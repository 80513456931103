import useApiResult from './useApiResult';
import { submitOrder } from './apiRequests';
import errorHandler from './fieldsErrorsHandler';
import { errorCodes } from '../utils/errorCodes';
import { generalErrorMessage } from '../utils/errorMessage';


const useCreateOrder = async (data, setError, dispatch) => {

  const request = submitOrder(data);
  const generalError = {
    "Errors": [
      {
        "Code": errorCodes.generalError,
        "Message": generalErrorMessage()
      }
    ]
  }
  const response = await useApiResult(request);

  // If no response "Server is down"
  if (!response) {
    errorHandler(generalError, setError, dispatch);
    return generalError
  }
  // If Response Has Error, return it as is
  if (response[1]) {
    errorHandler(response[1], setError, dispatch, data.orderTypeId);
    return response
  }
  // If Respones with Data => process locations data

  if (Object.keys(response[0])?.length) {
    const { Data } = response[0];

    return [Data, response[1]];
  }

  return response;
}

export default useCreateOrder;
