import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, connect } from 'react-redux';
import { getServiceLogo } from '../store/reducers';
import _ from "lodash";

const ServiceItem = ({ service, imagesSrc }) => {
    const { url } = useRouteMatch();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const locationId = new URLSearchParams(search).get('locationId');
    const [isShowDetailsClicked, setIsShowDetailsClicked] = useState(null);
    const [selectedService, setSelectedService] = useState(null);

    const logo = _.find(imagesSrc, (img) => { return img.serviceId === service.serviceId });
    useEffect(() => {
        if (!imagesSrc || (imagesSrc && !logo)) {
            dispatch(getServiceLogo(service.serviceId));
        }
        // eslint-disable-next-line
    }, [getServiceLogo])

    return (
        <Col lg={4} md={6} sm={12} xs={12} className="justify-content-center mb-4 card-col" key={service.orderTypeId} >

            <div className={`card ${(isShowDetailsClicked && selectedService === service.orderTypeId) ? 'expanded' : ''}`} >
                <div className={`service-icon`} ><img src={logo ? logo['data'] : ""} alt='' /></div>
                <div className={`card-body`}>
                    <div className='card-title'> {service.title} </div>
                </div>
                <div className='show-more' onClick={() => {
                    setIsShowDetailsClicked(!isShowDetailsClicked)
                    setSelectedService(service.orderTypeId)
                }}>
                    {!isShowDetailsClicked ? "Show Details" : "Hide Details"}
                </div>
                {isShowDetailsClicked && <div className='subheader'>
                    {service.subHeader}
                </div>}

                <div className='enroll-btn'>
                    <Link to={`${url}/${service.route}${locationId ? `?locationId=${locationId}` : ''}`}>
                        <Button variant="primary" >Enroll Now</Button>
                    </Link>
                </div>
            </div>
        </Col>
    )
}

const mapStateToProps = ({ imagesSrc }) => ({
    imagesSrc
});

export default connect(mapStateToProps)(ServiceItem);