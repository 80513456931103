import { getOri } from './apiRequests';
import axios from 'axios';

const useGetOri = async (ori, orderType) => {

  const request = getOri(ori, orderType);
  const response = await axios.get(request[0])
  return response;
}

export default useGetOri;
