export const homeTitle = () => `Home`;
export const howItWorksTitle = () => `How It Works`;
export const mobileServiceTitle = () => `Mobile Services`;
export const liveScanClassesTitle = () => `Live Scan Classes`;
export const corpeorateBillingTitle = () => `Corporate Billing`;
export const getFingerprintTitle = () => `Get Fingerprinted`;
export const joinOurNetworkTitle = () => `Join Our Network`;
export const liveScanSystemTitle = () => `Live Scan System`;
export const supportTitle = () => `Knowledge Base`;
export const aboutUsTitle = () => `About Certifix`;
export const faqTitle = () => `FAQ`;
export const contactUsTitle = () => `Contact Us`;
export const blogTitle = () => `Blog`;
export const fromsTitle = () => `Forms`;
export const loginTitle = () => `Login`;
export const servicesTitle = () => `Services - Certifix Live Scan`;