import useApiResult from './useApiResult';
import { getBookingSlots } from './apiRequests';
import { extractSlotsByDaysAndTimes } from '../utils/helper';
import errorHandler from './fieldsErrorsHandler';

const useSlotsById = async (locationId, endDateTime) => {
  const lastDayofMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split('T')[0];
  const lastDayofMonthFormatted = `${lastDayofMonth}T23:59:59Z`
  let endDate = endDateTime ? endDateTime : lastDayofMonthFormatted
  // const request = getLocation(locationId);
  const request = getBookingSlots(locationId, endDate);
  const response = await useApiResult(request);

  // If Response Has Error, return it as is
  if (!response || response[1]) {
    response[1] && errorHandler(response[1]);
    return response
  }
  // If Respones with Data => process locations data
  if (response[0]) {
    const { Data } = response[0];
    const hasBookingSlots = Data?.slots?.length;
    if (!hasBookingSlots) return {};
    
    const slots = extractSlotsByDaysAndTimes(Data.slots)
    
  
    return [slots, response[1]];
  }

  return response;
}

export default useSlotsById;
