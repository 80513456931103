import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

const SelectField = ({control, name, children, rules, errorMsgs, controlId, label, onChangeCallBack, disabled}) => {

  return (
    <Controller 
      render={({ 
        field,
        fieldState: { invalid, isTouched, isDirty, error } 
      })=> {
        const {onChange, ...fieldPropsWithoutOnChange} = field; 
        return (
        <Form.Group controlId={controlId}>
          {label ?
            <Form.Label style={{whiteSpace:"nowrap"}}>{label} {rules ? '*' : null}</Form.Label>
          : <span style={{height:'26px', display: 'inline-block'}} />
          }
          <Form.Control
            custom
            as="select"
            {...fieldPropsWithoutOnChange}
            isValid={isTouched && isDirty && !invalid}
            isInvalid={invalid}
            feedback={error}
            onChange={(e)=> {
              field.onChange(e.target.value);
              onChangeCallBack && onChangeCallBack();
            }}
            disabled={disabled}
          >
            {children}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {error ?
              errorMsgs && error.type !== 'required' ?
                errorMsgs[error.type] 
              :
              error.message ?
                error.message :
                'Required'
            : 
            null}
            {/* {error} */}
          </Form.Control.Feedback>
        </Form.Group>
      )
      }}
      name={name}
      control={control}
      rules={rules}
    />
    
  )
}

export default SelectField;
