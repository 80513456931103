import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { SET_APPLICANT_INFO, UPDATE_CURRENT_STEP, UPDATE_FORM_VALUES, setOrderTypeId } from '../store/actions/form';
import { checkOri, updateFdleRedirectionInfo } from '../store/reducers';
import { scrollToTop } from "../utils/scroll";
import BasicInfo from "./BasicInfo";
import ApplicantInfo from "./ApplicantInfo";
import AdditionalInfo from "./AdditionalInfo";
import Aliases from "./Aliases";
import Ori from "./Ori";
import Reason from "./Reason";
import React from 'react'
import { orderType } from "../utils/orderType";
import IsUSCitizen from "./IsUSCitizen";
import AddressInfo from "./AddressInfo";
import moment from "moment";
import FdleRedirectionModal from "./FdleRedirectionModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Loader from "./shared/loader/Loader";

const ProfileInfo = ({ initialValues, updateCurrentStep, updateStepValues, checkORIValid, globalErrors, currentStep, updateApplicantInfo, updateFdleRedirectionInfo, fdleRedirectionInfo, setOrderTypeId, isLoading }) => {
    const history = useHistory();

    const stepFieldNames = [
        "isUsCitizen",
        "firstName",
        "lastName",
        "middleName",
        "suffix",
        "emailAddress",
        "ori",
        "reason",
        "reasonDetails",
        "dateOfBirth",
        "placeOfBirth",
        "socialSecurityNumber",
        "gender",
        "race",
        "heightType",
        "height",
        "heightInch",
        "weightType",
        "weight",
        "hairColor",
        "eyeColor",
        "phoneNumber",
        "extension",
        "countryOfCitizenship",
        "oca",
        "aliasLastName",
        "aliasesFirstName",
        "aliasesMiddleName",
        "aliasesSuffix",

    ];
    const formMethods = useForm({ defaultValues: initialValues, mode: "all" });
    const isUSCitizenVal = formMethods.watch("isUsCitizen");
    const [showRedirectionModal, setShowRedirectionModal] = useState(false);
    const handleHideRedirectionModal = () => setShowRedirectionModal(false);
    const handleShowRedirectionModal = () => setShowRedirectionModal(true);
    const handleFdleRedirectionModal = () => {
        updateFdleRedirectionInfo({
            firstName: formMethods.getValues('firstName'), lastName: formMethods.getValues('lastName'),
            middleName: formMethods.getValues('middleName'), ori: formMethods.getValues('ori'),
            externalLocationId: formMethods.getValues('externalLocationId'), currentStep
        });
        setOrderTypeId(orderType.FDLE);
        setShowRedirectionModal(false);
        history.push("/services/fdle");
    }
    useEffect(() => {
        globalErrors &&
            Object.entries(globalErrors).forEach(
                ([key, value]) =>
                    stepFieldNames.includes(key) &&
                    formMethods.setError(key, { type: value.type, message: value.message })
            );

    }, []); //eslint-disable-line
    useEffect(() => {
        if (fdleRedirectionInfo) {
            updateFdleRedirectionInfo(undefined);
        } 
    }, [fdleRedirectionInfo]);  //eslint-disable-line

    const onBack = (_) => {
        const values = formMethods.getValues();
        updateStepValues(values);
        updateCurrentStep(--currentStep);
        scrollToTop("box-wrapper");
    };
    const onSubmit = async (data) => {
        updateApplicantInfo({ firstName: formMethods.getValues('firstName'), lastName: formMethods.getValues('lastName'), email: formMethods.getValues('emailAddress') })
        updateStepValues(data);
        let moveToNextStep = true;
        if (initialValues.orderTypeId === orderType.FDLE) {
            const isValidOri = await checkORIValid({ori: formMethods.getValues('ori'), orderTypeId: initialValues.orderTypeId});
            if (!isValidOri) {
                formMethods.setError("ori", {
                    type: "manual",
                    message: "Invalid ORI",
                });
                moveToNextStep = false;
            }
        }

        if (!checkDateOfBirthValid(formMethods.getValues('dateOfBirth'))) {
            moveToNextStep = false;
        }

        if (moveToNextStep) {
            updateCurrentStep(++currentStep);
        }

        scrollToTop("box-wrapper");
        
    };

    const onError = (errors) => {
        if (formMethods.formState.errors.dateOfBirth &&
            formMethods.formState.errors.dateOfBirth.type === "required"
        ) {
            formMethods.setError(
                "dateOfBirth",
                { type: "required", message: "Required" },
                { shouldFocus: true }
            );
            scrollToTop("box-wrapper");
        }
    };
    return (
        <>
        {(isLoading && initialValues.orderTypeId === orderType.PRINTTOCARD) && <Loader/>}
            <FormProvider {...formMethods}>
                <Row>
                    <Col>
                        <form id="profile-info-form" onSubmit={formMethods.handleSubmit(onSubmit, onError)}>
                            <Row>
                                <Col md="12">
                                    <h3 className="section-heading mt-4">Basic Info</h3>
                                </Col>
                            </Row>
                            {initialValues.orderTypeId === orderType.FBI ?
                                <>
                                    <IsUSCitizen />
                                    <BasicInfo />
                                </> : initialValues.orderTypeId === orderType.PRINTTOCARD ?
                                    <>
                                        <BasicInfo />
                                        <Row>
                                            <Ori showRedirectionModalHandler={handleShowRedirectionModal}></Ori>
                                            <Reason></Reason>
                                        </Row>
                                    </>
                                    : initialValues.orderTypeId === orderType.CA ?
                                        <>
                                            <BasicInfo />
                                        </>
                                        : initialValues.orderTypeId === orderType.FDLE &&
                                        <>
                                            <Row className="form-row">
                                                <Ori></Ori>
                                                <Reason></Reason>
                                                <Col md="6"></Col>
                                                <BasicInfo />
                                            </Row>
                                        </>}
                            <Row>
                                <Col md="12">
                                    <h3 className="section-heading mt-4">Applicant Info</h3>
                                </Col>
                            </Row>
                            <ApplicantInfo />

                            {initialValues.orderTypeId !== orderType.CA && <>
                                <Row>
                                    <Col md="12">
                                        <h3 className="section-heading mt-4">Additional Info</h3>
                                    </Col>
                                </Row>
                                <AdditionalInfo />
                            </>
                            }
                            {
                                initialValues.orderTypeId === orderType.FDLE &&
                                <Row className="form-row mt-3">
                                    <Col md="12">
                                        <h4>Aliases</h4>
                                    </Col>
                                    <Aliases />
                                </Row>
                            }
                            {
                                initialValues.orderTypeId === orderType.CA &&
                                <>
                                    <Row>
                                        <Col md="12">
                                            <h3 className="section-heading mt-4">Home Address</h3>
                                        </Col>
                                    </Row>
                                    <AddressInfo />
                                </>
                            }
                            <Row className="mt-4 mb-3">
                                <Col md="12">
                                    <input
                                        type="button"
                                        className="btn btn-outline-primary"
                                        value="Back"
                                        onClick={onBack}
                                    />
                                    <input type="submit" className="btn btn-primary" value="Next" disabled={isUSCitizenVal === "No" ? true : false}

                                    />
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </FormProvider>
            <FdleRedirectionModal show={showRedirectionModal} onClose={handleHideRedirectionModal} onSubmit={handleFdleRedirectionModal} />
        </>
    )
}
function mapStateToProps({ values, errors, currentStep, applicantInfo, fdleRedirectionInfo, isLoading }) {
    return {
        initialValues: values,
        applicantInfo,
        globalErrors: errors || {},
        currentStep,
        fdleRedirectionInfo,
        isLoading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentStep: (value) => dispatch({ type: UPDATE_CURRENT_STEP, value }),
        updateStepValues: (values) => dispatch({ type: UPDATE_FORM_VALUES, values }),
        checkORIValid: (getOri) => dispatch(checkOri(getOri)),
        updateApplicantInfo: (info) => dispatch({ type: SET_APPLICANT_INFO, info }),
        updateFdleRedirectionInfo: (info) => dispatch(updateFdleRedirectionInfo(info)),
        setOrderTypeId: (orderTypeId) => dispatch(setOrderTypeId(orderTypeId))

    }
}

function checkDateOfBirthValid(selectedDateOfBirth) {
    return moment(selectedDateOfBirth, "MM/DD/YYYY").isValid() && selectedDateOfBirth < moment();
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
