import useApiResult from './useApiResult';
import { getFees } from './apiRequests';


const useGetOriFees = async (getOriFees) => {
  const request = getFees(getOriFees);
  const response = await useApiResult(request);
  // If Response Has Error, return it as is
  if (!response || response[1]) {
    return response
  }
  // If Respones with Data => process locations data
  if (response[0]) {
    const { Data } = response[0];
    
    return [Data[0], response[1]];
  }

  return response;
}

export default useGetOriFees;
