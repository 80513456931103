import { Button, Modal } from 'react-bootstrap';

const ModalApp = ({ show, size, hideCloseBtn, children, hideCancelBtn, hideSaveBtn, onSave, onClose, isDisabledBtn, saveBtnTitle, cancelBtnTitle, title, backdrop, hideModalHeader, cancelBtnStyle, saveBtnStyle }) => {
    const handleClose = () => onClose();
    const handleSave = () => onSave();

    return (
        <>
            <Modal animation={false} show={show} onHide={handleClose} size={size} centered={true} backdrop={backdrop} >

                {!hideModalHeader &&
                    <Modal.Header closeButton={!hideCloseBtn} closeVariant="white" className="px-4">
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>}
                <Modal.Body className="px-4">
                    {children}
                </Modal.Body>
                <Modal.Footer className="px-4 mb-3">
                    {!hideCancelBtn &&
                        <Button className={cancelBtnStyle} onClick={handleClose}>
                            {cancelBtnTitle}
                        </Button>
                    }
                    {!hideSaveBtn && <Button className={saveBtnStyle} onClick={handleSave} disabled={isDisabledBtn ? isDisabledBtn : false}>
                        {saveBtnTitle}
                    </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

ModalApp.defaultProps = {
    hideSaveBtn: false ,
    hideCancelBtn: false,
    hideCloseBtn: false,
    hideModalHeader: false,
    size: 'lg', 
    isDisabledBtn: false,
    backdrop: true,


}
export default ModalApp
