import { Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Col from "react-bootstrap/Col";
const CheckboxField = ({
  control,
  name,
  type,
  rules,
  controlId,
  label,
  onChangeCallBack,
  checked,
  children, 
  disabled
  
}) => {

  return (
     <Controller 
      render={({ 
        field,
      })=> {
        return (
        <Form.Group controlId={controlId} as={Col} >
       
          <InputGroup>
           
         
          <Form.Check 
            type={type} 
            name={name}
            value={checked}
            disabled= {disabled}
            checked ={checked}
            onChange={(e)=>{
              onChangeCallBack(e);
            }}
          />
          <Form.Check.Label  >
            {label}
            {children ? children :""}
          </Form.Check.Label>
          </InputGroup>
        </Form.Group>
      )}}
      name={name}
      control={control}
      rules={rules}
    />

  

  );
};

export default CheckboxField;
