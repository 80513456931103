import React, { useEffect } from 'react'
import Ori from './Ori'
import TextField from './form-fields/TextField'
import SelectField from './form-fields/SelectField'
import { patternRgx } from '../utils/formValidator';
import { useFormContext } from 'react-hook-form'
import { authApplicantTypeErrorMessage, maxLength } from '../utils/errorMessage';
import { Row, Col } from 'react-bootstrap'
import { connect, useDispatch } from "react-redux";
import { getCaOriAgencies, setIsOriCompleteValue, setFbCaValue } from '../store/reducers';

const ApplicationSubmission = ({ caOriAgencies, setFbCaValue, isOriComplete, initialValues }) => {
  const dispatch = useDispatch();
  const form = useFormContext();
  const applicantTypes = caOriAgencies?.authApplicantTypes?.map(i => i.authType)
  const authValue = form.watch('authorizedApplicantType');
  const authTypes = applicantTypes && applicantTypes?.map(authType => (<option key={authType} value={authType}>
    {authType}
  </option>))
  const fbCa = authValue && caOriAgencies.authApplicantTypes.filter(auth => auth.authType === authValue)[0]?.fbCa
  fbCa && setFbCaValue(fbCa)

  useEffect(() => {
    !isOriComplete ? (form.reset({ ...initialValues, authorizedApplicantType: "" })) :
      (dispatch(getCaOriAgencies(form.getValues('ori'))) && dispatch(setIsOriCompleteValue(true)))
  }, [isOriComplete]) //eslint-disable-line
  return (
    <>
      <Row>
        <Ori />
        <Col md="6">
          <SelectField
            control={form.control}
            name="authorizedApplicantType"
            rules={{
              required: {
                value: "Required",
                message: authApplicantTypeErrorMessage()
              }
            }}
            controlId="authTypeSubmissionStep"
            label="Authorized Applicant Type"
            disabled={((applicantTypes && applicantTypes[0] && isOriComplete)) ? false : true}
          >
            <option value={""} disabled>

            </option>
            {authTypes}
          </SelectField>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            control={form.control}
            name="typeOfLicense"
            type="text"
            rules={{
              required: {
                value: "Required",
              },
              maxLength: {
                value: 20,
                message: maxLength(20),
              },
              pattern: patternRgx.alphaNumericSpaceSlash
            }}
            controlId="typeOfLicenseSubmissionStep"
            label="Type of License/Certification/Permit OR Working Title"
          />
        </Col>
      </Row>

    </>
  )
}
function mapStateToProps({ caOriAgencies, isOriComplete, values }) {
  return {
    caOriAgencies,
    isOriComplete,
    initialValues: values,

  }
}
const mapDispatchToProps = dispatch => {
  return {
    setFbCaValue: (fbCa) => dispatch(setFbCaValue(fbCa)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSubmission)
