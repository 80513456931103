import {
    alphaNumericErrorMessage,
    alphaNumericSpecialCharactersErrorMessage,
    charSpaceDashErrorMessage,
    invalidEmailErrorMessage,
    invalidNumberErrorMessage,
    invalidPhoneErrorMessage,
    numericErrorMessage,
    invalidExtension,
    alphaNumericAllSpecialCharactersErrorMessage,
    alphaNumericSpaceSlashErrorMessage,
    alphaNumericalDashParenthesesSpaceErrorMessage,
    alphaNumericSpaceDashErrorMessage,
    onlyFiveDigitsErrorMessage,
    onlyFiveDigitsOrFollowedByFourDigitsErrorMessage,
    numericalSpecialCharactersErrorMessage,
    alphaNumericSDashesHashesErrorMessage,
    caDriverLicenseErrorMessage,
    onlySixDigitsAndDisallowZerosErrorMessage,
    onlyNineDigitsErrorMessage,
    charSpaceDashAmpersandErrorMessage
  } from "./errorMessage";
  export const patternRgx = {
    alphaCharactersDashesSpaces: {
      value: /^[a-zA-Z- ]+$/,
      message: charSpaceDashErrorMessage(),
    },
    alphaNumeric: {
      value: /^[a-zA-Z0-9]*$/,
      message: alphaNumericErrorMessage(),
    },
    alphaNumericSpecialCharacters: {
      value: /^[a-zA-Z0-9-'";: ]*$/,
      message: alphaNumericSpecialCharactersErrorMessage(),
    },
    numeric: {
      value: /^[0-9]+$/,
      message: numericErrorMessage(),
    },
    onlyNineDigits: {
      value: /^([0-9]){9}$/,
      message: onlyNineDigitsErrorMessage(),
    },
    onlyFourDigits: {
      value: /^([0-9]){4}$/,
      message: invalidNumberErrorMessage(),
    },
    email: {
        value: /^[a-zA-Z0-9'_%+^#&$*!-][a-zA-Z0-9.'_%+^#&$*!-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: invalidEmailErrorMessage()
    },
    phone: {
      value: /^([0-9]){10}$/,
      message: invalidPhoneErrorMessage(),
    },
    alphaCharactersDashsSpacesAmpersands: {
      value: /^[a-zA-Z-& ]+$/,
      message: charSpaceDashErrorMessage(),
    },
    extension: {
      value: /^([0-9]{1,10})$/,
      message: invalidExtension()
    },
   alphaNumericAllSpecialCharacters: {
    value: /^[ A-Za-z0-9_@./#&+-~$%*()!'"]*$/,
    message: alphaNumericAllSpecialCharactersErrorMessage()
   },
   alphaNumericSpaceSlash: {
    value: /^[a-zA-Z0-9/ ]*$/,
    message: alphaNumericSpaceSlashErrorMessage(),
  },
  onlyFiveDigits : {
    value : /^([0-9]){5}$/,
    message: onlyFiveDigitsErrorMessage()
  },
  alphaNumericalDashParenthesesSpace: {
    value : /^[a-zA-Z0-9-,'() ]*$/,
    message : alphaNumericalDashParenthesesSpaceErrorMessage()
  },
  alphaNumericalSpaceDash: {
    value:  /^[a-zA-Z0-9- ]*$/,
    message: alphaNumericSpaceDashErrorMessage()
  },
  onlyFiveDigitsOrFollowedByFourDigits: {
    value:/^([0-9]){5}-([0-9]){4}$|^([0-9]){5}$/,
    message: onlyFiveDigitsOrFollowedByFourDigitsErrorMessage()

  },
  numericalSpecialCharacters: {
    value: /^([0-9-,()#+ ]){5,24}$/,
    message : numericalSpecialCharactersErrorMessage()
  },
  alphaNumericalDashesHashes: {
    value: /^[a-zA-Z0-9-,#]*$/,
    message: alphaNumericSDashesHashesErrorMessage()
  }, 
  caDriverLicense: {
    value: /^([A-Za-z]){1}([0-9]){7}$/ ,
    message: caDriverLicenseErrorMessage() 
  },
  onlySixDigitsAndDisallowZeros : {
    value : /^(?!000000)([0-9]){6}$/,
    message: onlySixDigitsAndDisallowZerosErrorMessage()
  },
  
  alphaCharactersDashesSpacesAmpersands: {
    value: /^[a-zA-Z-& ]+$/,
    message: charSpaceDashAmpersandErrorMessage(),
  },

  };
  
