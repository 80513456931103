import useApiResult from './useApiResult';
import { getFees } from './apiRequests';
import errorHandler from './fieldsErrorsHandler';


const useCheckOri = async (getOri, dispatch) => {

  const request = getFees(getOri);
  const response = await useApiResult(request);

  // If Response Has Error, return it as is
  if (!response || response[1] || response[0].Data.length === 0) {
    response[1] && errorHandler(response[1], null, dispatch, getOri.orderTypeId);
    return response
  }
  // If Respones with Data => process locations data

  if (response[0]) {
    const { Data } = response[0];

    return [Data[0], response[1]];
  }

  return response;
}

export default useCheckOri;
