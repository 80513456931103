import React from 'react'
import { Row, Col } from 'react-bootstrap';
import RadioCheckboxField from './form-fields/RadioCheckboxField';
import { useFormContext } from 'react-hook-form';
import { isUSCitizenLabel } from '../utils/confirmationMessage';
import { isUSCitienErrorMessage, selectValidOptionMessage } from '../utils/errorMessage';

const IsUSCitizen = () => {
  const { control, clearErrors, watch, formState: { errors } } = useFormContext();
  const isUSCitizenVal = watch("isUsCitizen");

  return (
    <>
      <Row>
        <Col md="12">
          <RadioCheckboxField
            control={control}
            name="isUsCitizen"
            type="radio"
            options={["Yes", "No"]}
            rules={{ required: true }}
            controlId="isUSCitizenProfileInfo"
            label={isUSCitizenLabel()}
            onChangeCallBack={() => {
              clearErrors();
            }}
            defaultValue={isUSCitizenVal}
          />
          {errors?.isUsCitizen && (
            <p style={{ color: "red" }}>{selectValidOptionMessage()}</p>
          )}
        </Col>

      </Row>
      <Row>

        {isUSCitizenVal === "No" ? (
          <Col md="12" className="usCitizenErrorMessage bgError">
            <p>
              {isUSCitienErrorMessage()}
            </p>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  )
}

export default IsUSCitizen
