export const scrollToTop = (elClass) => {
  const scrollToElOffset = elClass 
  ? document.getElementsByClassName(elClass)[0].offsetTop
  : 0
  window.scroll({ top: scrollToElOffset, behavior: 'smooth' })
};

export const scrolltoRef = ({ ref, isSmooth = true }) =>
  window.scroll({
    top: ref?.current?.offsetTop,
    ...(isSmooth ? { behavior: 'smooth' } : {}),
  });

export const scroll = ({ yOffset }) => window.scroll({ top: yOffset });
