import React from 'react'
import {  Col } from "react-bootstrap";
import TextField from "./form-fields/TextField";
import { useFormContext } from "react-hook-form";
import { patternRgx } from '../utils/formValidator';
import { maxLength } from '../utils/errorMessage';

const Aliases = () => {
  const { control } = useFormContext();

  return (
    <>
      <Col md="6">
        <TextField
          control={control}
          name="aliasesLastName"
          type="text"
          rules={{
            maxLength: {
              value: 30,
              message: maxLength(30),
            },
            pattern: patternRgx.alphaCharactersDashesSpaces
          }}
          controlId="aliasesLastNameStepTwo"
          label="Last Name"
        />
      </Col>
      <Col md="6">
        <TextField
          control={control}
          name="aliasesFirstName"
          type="text"
          rules={{
            maxLength: {
              value: 30,
              message: maxLength(30),
            },
            pattern: patternRgx.alphaCharactersDashesSpaces
          }}
          controlId="aliasesFirstNameStepTwo"
          label="First Name"
        />
      </Col>
      <Col md="6">
        <TextField
          control={control}
          name="aliasesMiddleName"
          type="text"
          rules={{
            maxLength: {
              value: 30,
              message: maxLength(30),
            },
            pattern: patternRgx.alphaCharactersDashesSpaces
          }}
          controlId="aliasesMiddleNameStepTwo"
          label="Middle Name"
        />
      </Col>
      <Col md="6">
        <TextField
          control={control}
          name="aliasesSuffix"
          type="text"
          controlId="suffixStepTwo"
          label="Suffix"
          rules={{
            maxLength: {
              value: 30,
              message: maxLength(30),
            },
          }}
        />
      </Col>

    </>
  )
}


export default Aliases
