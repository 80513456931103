import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const theme = createMuiTheme({
  palette: {
    type: "light",
  },
});

const MuiDatePicker = ({
  control,
  name,
  rules,
  controlId,
  label,
  maxDate,
  format,
  disabled,
}) => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { ref, ...rest },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => {
          return (
            <div
              className={
                (isTouched && invalid) || (error && error.type === "required")
                  ? "custom-required-field"
                  : ""
              }
            >
              <KeyboardDatePicker
                margin="normal"
                id={controlId}
                label={label}
                disabled={disabled}
                format={format ?? "MM/dd/yyyy"}
                maxDate={maxDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                {...rest}
              />
              {(isTouched && invalid) || error ? (
                <div className="invalid-feedback">
                  {error?.message
                    ? error.message
                    : error.type === "required"
                    ? "Required"
                    : null}
                </div>
              ) : null}
            </div>
          );
        }}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export default MuiDatePicker;
