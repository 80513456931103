import React from 'react'
import { Col, Row } from "react-bootstrap";
import TextField from "./form-fields/TextField";
import { useFormContext } from "react-hook-form";
import { orderType } from '../utils/orderType';
import { patternRgx } from '../utils/formValidator';
import {  maxLength } from '../utils/errorMessage';


const BasicInfo = () => {

  const { control, getValues, watch } = useFormContext();
  const orderTypeId = getValues('orderTypeId');
  const isUSCitizenVal = watch("isUsCitizen");

  return (
    <Row>
      <Col md="6">
        <TextField
          control={control}
          name="firstName"
          type="text"
          rules={{
            required: "Required",
            maxLength: {
              value: 30,
              message: maxLength(30),
            },
            pattern: patternRgx.alphaCharactersDashesSpaces
          }}
          controlId="firstNameStepOne"
          label="First Name"
          disabled={isUSCitizenVal === "No" ? true : false}

        />
      </Col>
      <Col md="6">
        <TextField
          control={control}
          name="lastName"
          type="text"
          rules={{
            required: "Required",
            maxLength: {
              value: 30,
              message: maxLength(30),
            },
            pattern: patternRgx.alphaCharactersDashesSpaces
          }}
          controlId="lastNameStepTwo"
          label="Last Name"
          disabled={isUSCitizenVal === "No" ? true : false}

        />
      </Col>
      <Col md="6">
        <TextField
          control={control}
          name="middleName"
          type="text"
          controlId="middleNameStepTwo"
          label="Middle Name"
          rules={{
            maxLength: {
              value: 30,
              message: "Max 30 characters.",
            },
            pattern: patternRgx.alphaCharactersDashesSpaces
          }}
          disabled={isUSCitizenVal === "No" ? true : false}

        />
      </Col>
      {
        orderTypeId === orderType.FDLE && <Col md="6">
          <TextField
            control={control}
            name="suffix"
            type="text"
            controlId="suffixStepTwo"
            label="Suffix"
            rules={{
              maxLength: {
                value: 30,
                message: maxLength(30),
              },
            }}
            disabled={isUSCitizenVal === "No" ? true : false}

          />
        </Col>

      }

    </Row>
  )
}

export default BasicInfo
