import { getApiStaticData } from './apiRequests';
import useApiResult from './useApiResult';

const useStaticData = async () => {
  
  const request = getApiStaticData();
  const response = await useApiResult(request);

  // If Response Has Error, return it as is
  if (!response || response[1]) {
    return response
  }

  if (response[0]) {
    const { Data } = response[0];
    return [Data, response[1]]
  }

  return response;
}

export default useStaticData;