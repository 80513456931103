export const errorCodes = {
    generalError: 1,
    validationError: 3,
    stripeError: 4,
    stripeValidation: 5,
    sterlingError: 6,
    sterlingNotFound: 7,
    sterlingValidation: 9,
    oriNotFound: 10,
    locationNotAcceptable: 11,
    bookAppointmentForbidden: 12,
    appointmentNotAvailable: 13,
    orderAlreadyBooked: 14,
    bookCanceledOrder: 15,
    invalidCbsAccount: 50,
    UnderRetrialOrder: 61,
    cscanError: 68,
    oriAgencyMismatch: 69,
    oriBillingNumberMismatch: 70,
    billingNumberNotFound: 72,
    underRetrialOrder: 61,
}

export const httpStatusCode = {
    OK: 200,
    badRequest: 400,
    forbidden: 403,
    notFound: 404,
    notAcceptable: 406,
    internalServerError: 500,
    badGateway: 502,
    serviceUnavailable: 503,
}


