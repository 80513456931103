
import React from 'react'
import FdleApplicantInfo from './FdleApplicantInfo'
import { orderType } from '../utils/orderType'
import PassThroughApplicantInfo from './PassThroughApplicantInfo'
import { useFormContext } from 'react-hook-form'
import { Row } from 'react-bootstrap'

const ApplicantInfo = () => {
    const { getValues } = useFormContext();
    const orderTypeId = getValues('orderTypeId')
    return (
        <Row>
            {orderTypeId === orderType.FDLE ?
                <FdleApplicantInfo/>
                : <PassThroughApplicantInfo/>
                }
        </Row>
    )
}


export default ApplicantInfo
