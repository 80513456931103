import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { UPDATE_CURRENT_STEP, UPDATE_FORM_VALUES } from "../store/actions/form";
import { getFees, createOrder, validateAccountNumber, setIsSubmittingValue } from "../store/reducers";
import { connect } from "react-redux";
import TextField from "./form-fields/TextField";
import SelectField from "./form-fields/SelectField";
import CheckboxField from "./form-fields/CheckboxField";
import { getFutureExpiryDate } from '../utils/helper';
import moment from 'moment';
import { scrollToTop } from '../utils/scroll';
import { useHistory } from 'react-router-dom';
import { orderType, orderTypeSuccessUrl } from "../utils/orderType";
import { PaymentType } from "../utils/paymentType";
import { fingerprintCopiesMessage } from "../utils/confirmationMessage";
import { additionalCopiesRangeErrorMessage } from '../utils/errorMessage';
import { AccountAgreementType, AccountAgreementTypeLabel } from "../utils/accountAgreementType";

const { currentYear, years, months, monthsPartial } = getFutureExpiryDate();

const Payment = ({ initialValues, updateCurrentStep, updateStepValues, calculateFees, oriFees, createOrder, isLoading, orderTypeId, currentStep, onAccountData, checkValidAccount, setIsSubmitting, isSubmitting}) => {
  const history = useHistory();

  const { watch, handleSubmit, getValues, setValue, clearErrors, setError, control, formState: { errors, isValid }, unregister, register } = useForm({ defaultValues: initialValues, mode: 'all' });

  const [isAccountVerified, setIsAccountVeried] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [additionalCopies, setAdditionalCopies] = useState(0)

  let agreementType = onAccountData.agreementType;

  const prepareDataRequest = (data) => {
    const {
      address1,
      address2,
      city,
      state,
      zip,
      country,
      cardNumber,
      dateOfBirth,
      reason,
      heightType,
      height,
      heightInch,
      weightType,
      weight,
      streetName,
      streetDirection,
      streetNumber, 
      apartmentNumber,
      mailCode,
      agencyStreetNumber,
      agencyStreetName,
      agencyStreetDirection,
      agencyCity,
      agencyState, 
      agencyZipCode,
      agencyContactName,
      agencyTelephoneNumber,
      levelOfServiceDoj,
      levelOfServiceFbi,
      authorizedApplicantType,
      typeOfLicense,
      authorizedAgency,
      billingNumber,
      ...rest } = data;
    const address = { address1, address2, streetNumber, streetName, streetDirection, apartmentNumber: apartmentNumber, city, state, zip, country };
    const agencyInfo = {
      mailCode, typeOfLicense, authorizedAgency,  levelOfServiceFbi, levelOfServiceDoj,  authorizedApplicantType,
      streetNumber: agencyStreetNumber, streetDirection: agencyStreetDirection, streetName: agencyStreetName, city: agencyCity,
      state: agencyState, zip: agencyZipCode, contactName: agencyContactName, telephoneNumber: agencyTelephoneNumber, billingNumber,
    }
    const cCard = cardNumber ? cardNumber.replace(/\s/g, '') : '';
    const dob = moment(dateOfBirth).format("YYYY-MM-DD");
    const reasonNum = +reason;
    
    let heightInFeet;
    let heightInInch;
    if( orderTypeId === orderType.FBI){
      rest.isUsCitizen = rest.isUsCitizen === "Yes" ? true : false;
    }
    if (heightType === "Standard") {
      heightInFeet = height;
      heightInInch = heightInch.length ? heightInch : "0";
    } else {
      let totalInches = parseInt(height) / 2.54;
      heightInFeet = Math.floor(totalInches / 12);
      heightInInch = Math.round(totalInches - 12 * heightInFeet);
    }

    let weightValue = weightType === "Standard" ? weight : (parseInt(weight) * 2.205).toFixed(0).replace(".", "");
    weightValue = weightValue && weightValue < 100 ? "0" + weightValue : weightValue;

    return {
      address, cardNumber: cCard, dateOfBirth: dob, reason: reasonNum, height: `${heightInFeet}`, weight: weightValue, heightInch: `${heightInInch}`,agencyInfo, ...rest
    };
  };

  const onSuccessCallback = () => {
    history.push(orderTypeSuccessUrl.get(orderTypeId));
  };

  const onSubmit = data => {
    setIsSubmitting(true)
    data.accountAgreement = isChecked;
    data.additionalCopies = Number(data.additionalCopies)
    updateStepValues(data);
    const dataModified = prepareDataRequest(data);
    createOrder(dataModified, setError, onSuccessCallback);
  };

  const onBack = _ => {
    const values = getValues();
    updateStepValues(values);
    updateCurrentStep(--currentStep);
    scrollToTop("box-wrapper");
  };

  const expiryYearVal = watch("expirationYear");

  const yearsOptionsList = years.map((year) => 
    <option key={year} value={year}>
      {year}
    </option>
  );

  const monthsOptionsList = months.map((month) => 
    <option key={month} value={month}>
      {month}
    </option>
  );

  const monthsPartialOptionsList = monthsPartial.map((month, i) => 
    <option key={month} value={month}>
      {month}
    </option>
  );

  const { GovFee, ServiceFee, TotalFee, FeePerCopy } = oriFees;
  const [totalFee, setTotalFee] = useState(TotalFee);
  const additionalCopiesPrice = useMemo(() => {
    const fees = (additionalCopies <= 30 && additionalCopies >= 0) ? Number(ServiceFee) + additionalCopies * FeePerCopy : 0;
    setTotalFee(fees);
    return additionalCopies * FeePerCopy
  }, [additionalCopies, FeePerCopy, ServiceFee])

  useEffect(() => {
    if (currentYear === +expiryYearVal) {
      setValue("expirationMonth", `${monthsPartial[0]}`);
    }
  }, [setValue, expiryYearVal]);

  useEffect(() => {
    orderTypeId !== orderType.CA && calculateFees({ori: getValues('ori'), orderTypeId, additionalCopies});
  }, [calculateFees]) //eslint-disable-line

  useEffect(() => {
    if (!isLoading) {
      setIsSubmitting(false)
    }
  }, [isLoading]) //eslint-disable-line

  const paymentValidationRules = {
    cardHolderName: {
      required: 'Required',
      maxLength: {
        value: 30,
        message: 'Max 30 characters.'
      },
      pattern: {
        value: /^[-a-zA-Z ]+$/,
        message: 'Letters, Spaces, and dashes are only allowed.'
      }
    },

    cardNumber: {
      required: 'Required',
      pattern: {
        value: /^[0-9 ]{17,19}$/,
        message: 'A valid credit card is 14 ~ 16 digits.'
      }
    },
    cvc: {
      required: 'Required',
      pattern: {
        value: /^[0-9]{3,4}$/,
        message: 'A valid security code is 3 ~ 4 digits.'
      }
    },
    expirationMonth: { required: 'Required' },
    expirationYear: { required: 'Required' },
    accountNumber: { required: 'Required' },
    additionalCopies: {

      pattern: {
        value: /^([0-9]|([0-2][0-9]|30){1,2})$/,
        message: additionalCopiesRangeErrorMessage()
      }
    }

  }
  const selectedPaymentType = watch('paymentType')
  useEffect(() => {
    setIsAccountVeried(false)
    if (selectedPaymentType === PaymentType.onApplicant) {
      // setValue
      register('cardHolderName', paymentValidationRules.cardHolderName);
      register('cardNumber', paymentValidationRules.cardNumber);
      register('cvc', paymentValidationRules.cvc);
      register('expirationMonth', paymentValidationRules.expirationMonth);
      register('expirationYear', paymentValidationRules.expirationYear);

      unregister('accountNumberCheck');
      unregister('accountNumber');
      unregister('accountAgreement');
      onAccountData.agreementType = AccountAgreementType.none;
    } else if (selectedPaymentType === PaymentType.onAccount) {
      register('accountNumber', paymentValidationRules.accountNumber);
      register('accountNumberCheck', paymentValidationRules.accountNumber);
      register('accountAgreement');
      unregister('cardHolderName');
      unregister('cardNumber');
      unregister('cvc');
      unregister('expirationMonth');
      unregister('expirationYear');
    }
  }, [selectedPaymentType]) //eslint-disable-line

  const watchAccountNumber = watch('accountNumberCheck')
  const verifyAccount = async () => {
    if (watchAccountNumber !== "") {
      const account = await checkValidAccount(watchAccountNumber);
      if (account === true) {
        setIsAccountVeried(true)
        setValue("accountNumber", watchAccountNumber)
        clearErrors("accountNumberCheck");
      } else {
        setIsAccountVeried(false)
        setError("accountNumberCheck", { message: "Invalid Account Number" })
        setValue("accountNumber", '')
      }
    }

  }
  return (
    <Row>
      <Col>
        <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Payment Details</h3>
            </Col>
          </Row>

          <Row>
            <Col md="12" style={{ marginBottom: "2rem" }}>
              <Row className="row fees-items text-left">
                <Col xs="10">
                  <h5>Service Fee</h5>
                </Col>
                <Col xs="2">
                  {selectedPaymentType === PaymentType.onApplicant ? (
                    <h5>${Number(ServiceFee).toFixed(2)}</h5>
                  ) : (
                    <h5>$0 </h5>
                  )}              
                </Col>
                {orderTypeId === orderType.PRINTTOCARD && (
                  <Col className="copies-message">
                    <span>{fingerprintCopiesMessage()}</span>
                  </Col>
                )}
              </Row>
              {(orderTypeId === orderType.FDLE || orderTypeId === orderType.FBI) && (
                <Row className="row fees-items text-left">
                  <Col xs="10">
                    <h5>Government Fee</h5>
                  </Col>
                  <Col xs="2">
                  {selectedPaymentType === PaymentType.onApplicant ? (
                    <h5>${Number(GovFee).toFixed(2)}</h5>
                  ) : (
                    <h5>$0 </h5>
                  )}                    </Col>
                </Row>
              )}
              {(orderTypeId === orderType.PRINTTOCARD && selectedPaymentType === PaymentType.onApplicant) && (
                <>
                  <Row className="row text-left fees-items additional-copies " >

                    <Col xs="3" md='4'>
                      <h5>Additional Copies </h5>

                    </Col>
                    <Col xs="7" md='6'>
                      <TextField className=""
                        control={control}
                        name="additionalCopies"
                        type="text"
                        controlId="additionalCopiesStepFour"
                        isValueUsed
                        value={additionalCopies}
                        rules={paymentValidationRules.additionalCopies}
                        maxLength={2}
                        onChangeCallBack={(e) => {
                          setAdditionalCopies(e.target.value)
                        }
                        }
                      />
                    </Col>
                    <Col xs="2" md='2'>
                      <h5 className="fee-per-copy">${(additionalCopiesPrice && (additionalCopies <= 30 && additionalCopies >= 0)) ? Number(additionalCopiesPrice).toFixed(2) : 0}</h5>
                    </Col>
                  </Row>
                </>)}
              <Row className="row fees-heading text-left">
                <Col xs="10">
                  <h5>Total Fees</h5>
                </Col>
                <Col xs="2">
                  {selectedPaymentType === PaymentType.onApplicant ? (
                    <h5>${(selectedPaymentType === PaymentType.onApplicant && orderTypeId === orderType.PRINTTOCARD && totalFee) ? Number(totalFee).toFixed(2) : Number(TotalFee).toFixed(2)}</h5>
                  ) : (
                    <h5>$0 </h5>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

            <div className="payment-method">
              <div className="mt-3">
                <button
                  className={
                    (selectedPaymentType === PaymentType.onApplicant
                      ? "active-method"
                      : "") + " credit-card"
                  }
                  type="button"
                  onClick={() =>
                    setValue("paymentType", PaymentType.onApplicant)
                  }
                ></button>
              </div>
              <div className="mt-3">
                <button
                  className={
                    (selectedPaymentType === PaymentType.onAccount
                      ? "active-method"
                      : "") + " charge-to-account"
                  }
                  type="button"
                  onClick={() => setValue("paymentType", PaymentType.onAccount)}
                ></button>
              </div>
            </div>

          {selectedPaymentType === PaymentType.onApplicant && (
            <div
              className={ "method-details text-left mt-5 pl-3 pr-3 pay-by-credit" }
            >
          <Row className="form-row">
            <Col md="6">
              <TextField
                control={control}
                name="cardHolderName"
                type="text"
                rules={paymentValidationRules.cardHolderName}
                controlId="cardHolderNameStepFour"
                label="Card Holder Name"
              />
            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="cardNumber"
                type="tel"
                inputMode="numeric"
                autoComplete="cc-number"
                formatCreditCard
                rules={paymentValidationRules.cardNumber}
                controlId="cardNumberNameStepFour"
                label="Card Number"
                placeHolder="xxxx xxxx xxxx xxxx"
              />
            </Col>
            <Col md="6">
              <TextField
                control={control}
                name="cvc"
                type="tel"
                inputMode="numeric"
                autoComplete="cc-csc"
                formatCreditCard
                rules={paymentValidationRules.cvc}
                controlId="cvcStepFour"
                label="CVV"
              />
            </Col>
            <Col md="6">
              <Row className="form-row">
                <Col md="12">
                  <label>Expiry Date *</label>
                </Col>
                <Col md="6">
                  {+expiryYearVal === currentYear ? (
                    <SelectField
                      control={control}
                      name="expirationMonth"
                      rules={paymentValidationRules.expirationMonth}
                      controlId="expirationMonthStepFour"
                    >
                      <option value="" defaultValue >
                        Month
                      </option>
                      {monthsPartialOptionsList}
                    </SelectField>
                  ) : (
                    <SelectField
                      control={control}
                      name="expirationMonth"
                      rules={paymentValidationRules.expirationMonth}
                      controlId="expirationMonthStepFour"
                    >
                      <option value="" defaultValue >
                        Month
                      </option>
                      {monthsOptionsList}
                    </SelectField>
                  )}
                </Col>
                <Col md="6">
                  <SelectField
                    control={control}
                    name="expirationYear"
                    rules={paymentValidationRules.expirationYear}
                    controlId="expirationYearStepFour">
                    <option value="" defaultValue >
                      Year
                    </option>
                    {yearsOptionsList}
                  </SelectField>
                </Col>
              </Row>
            </Col>
          </Row>
          </div>
          )}
          {selectedPaymentType === PaymentType.onAccount && (
            <div className="method-details text-left mt-5 pl-3 pr-3 pay-by-account ">
              {errors?.accountNumber?.message &&
                (isAccountVerified ? (
                  <></>
                ) : (
                  <p style={{ color: "red" }}>Select a valid Account</p>
                ))}
             <Row className="form-row">
                <Col>
                  <label className="m-2">Account Number</label>
                </Col>
                <Col md={6}>
                  <TextField
                    control={control}
                    name="accountNumberCheck"
                    disabled={isAccountVerified}
                    rules={paymentValidationRules.accountNumber}
                    controlId="accountNumberStepFour"
                  />
                </Col>
                <Col>
                  {isAccountVerified && !errors?.accountNumberCheck ? (
                    <p style={{ color: "green" }}>Account verified</p>
                  ) : (
                    <Button disabled={!watchAccountNumber} type="button" onClick={verifyAccount}>
                      Verify
                      {isLoading && (
                        <Spinner
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  )}
                </Col>
              </Row>
              {(agreementType !== AccountAgreementType.none 
              && Object.values(AccountAgreementType).includes(agreementType) && isAccountVerified) ?
                <Row className="checkboxRow">
                  <Col md={12}>
                    <CheckboxField
                      control={control}
                      rules={{ required: false }}
                      name="accountAgreement"
                      controlId="accountAgreementStep"
                      type="checkbox"
                      value={isChecked}
                      label={AccountAgreementTypeLabel.get(agreementType)}
                      onChangeCallBack={(e) => {
                        setIsChecked(e.target.checked);
                      }}
                    />
                  </Col>
                </Row>
                :
                ""}
            </div>
          )}


          <Row className="mt-4 mb-3">
            <Col md="12">
              <input
                type="button"
                className="btn btn-outline-primary"
                value="Back"
                onClick={onBack}
              />
              <Button
                className="btn btn-primary"
                disabled={isLoading || (agreementType && !isChecked) || (!isAccountVerified && selectedPaymentType === PaymentType.onAccount) || (selectedPaymentType === PaymentType.onApplicant && !isValid)}
                style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}

                type="submit"
              >
                <>
                  {isLoading && isSubmitting && (
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Submit
                </>
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

function mapStateToProps({ onAccountData, values, apiData, oriFees, isLoading, orderTypeId, currentStep, isSubmitting }) {
  return {
    initialValues: values,
    apiData,
    oriFees,
    isLoading,
    orderTypeId,
    onAccountData,
    currentStep,
    isSubmitting
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentStep: (value) =>  dispatch({ type: UPDATE_CURRENT_STEP, value }),
    updateStepValues: (values) => dispatch({ type: UPDATE_FORM_VALUES, values }),
    calculateFees: (getOriFee) => dispatch(getFees(getOriFee)),
    createOrder: (data, setError, callback) => dispatch(createOrder(data, setError, callback)),
    checkValidAccount: (accountNumber) => dispatch(validateAccountNumber(accountNumber)),
    setIsSubmitting: (value) => dispatch(setIsSubmittingValue(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
