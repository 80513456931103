import { Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";

const RadioCheckboxField = ({control, name, type, options, rules, errorMsgs, 
  controlId, label, onChangeCallBack, defaultValue, disabled}) => {
  
  return (
    <>
    <Controller 
      render={({ 
        field,
        fieldState: { invalid, isTouched, isDirty, error } 
      })=> {
        return (
        <Form.Group controlId={controlId}>
          {label ?
            <Form.Label>{label} {rules ? '*' : null}</Form.Label>
          : <span style={{height:'26px', display: 'inline-block'}} />
          }
          <InputGroup>
            {options.map(option => (
              <div key={`inline-${option}`}>
           
                <Form.Check
                  {...field}
                  custom
                  type={type}
                  inline 
                  label={option}
                  name={name}
                  id={`${option}-${name}`}
                  onChange={(e)=> {
                    field.onChange(e.target.value);
                    onChangeCallBack();
                  }}
                  isValid={isTouched && isDirty && !invalid && rules}
                  isInvalid={invalid}
                  value={option}
                  disabled={disabled}
                  defaultChecked={defaultValue === option}
                  // feedback={error}
                />
              </div>
            ))}
            
            <Form.Control.Feedback type="invalid">
              {error ?
                errorMsgs && error.type !== 'required' ?
                  errorMsgs[error.type] 
                :
                error.message ?
                  error.message :
                  'Required'
              : 
              null}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      )}}
      name={name}
      control={control}
      rules={{ required: true }}    />
</>
    
  )
}

export default RadioCheckboxField;
