import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { UPDATE_CURRENT_STEP, UPDATE_FORM_VALUES } from '../store/actions/form';
import TextField from "./form-fields/TextField";
import { scrollToTop } from '../utils/scroll';
import AddressInfo from "./AddressInfo";
import { maxLength } from "../utils/errorMessage";


const Address = ({ initialValues, updateCurrentStep, updateStepValues, globalErrors, currentStep }) => {
  const stepFieldNames = ['address1', 'address2', 'city', 'state', 'zip']
  const formMethods = useForm({ defaultValues: initialValues, mode: 'all' });

  useEffect(() => {
    globalErrors && Object.entries(globalErrors).forEach(([key, value]) => {
      stepFieldNames.includes(key) && formMethods.setError(key, { type: value.type, message: value.message })
    })
  }, []) //eslint-disable-line

  const onSubmit = (data) => {
    updateStepValues(data);
    updateCurrentStep(++currentStep)
    scrollToTop("box-wrapper");
  };

  const onBack = _ => {
    const values = formMethods.getValues();
    updateStepValues(values);
    updateCurrentStep(--currentStep);
    scrollToTop('box-wrapper');
  }

  return (
    <>
    <FormProvider {...formMethods}>
      <Col>
        <form id="address-form" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Address Info</h3>
            </Col>
          </Row>
           <>
              <Row>
                <Col md="12">
                  <TextField
                    control={formMethods.control}
                    name="address1"
                    type="text"
                    rules={{
                      required: 'Required',
                      maxLength: {
                        value: 100,
                        message: maxLength(100)
                      }
                    }}
                    controlId="address1StepThree"
                    label="Address 1"
                  />
                </Col>
                <Col md="12">
                  <TextField
                    control={formMethods.control}
                    name="address2"
                    type="text"
                    rules={{
                      maxLength: {
                        value: 100,
                        message: maxLength(100)
                      }
                    }}
                    controlId="address2StepThree"
                    label="Address 2"
                  />
                </Col>
                </Row>
              </>
              <AddressInfo/>
            <Row className="mt-4 mb-3">
              <Col md="12">
                <input type="button" className="btn btn-outline-primary" value="Back" onClick={onBack} />
                <input type="submit" className="btn btn-primary" value="Next" />
              </Col>
            </Row>
        </form>
      </Col>
      </FormProvider>
    </>
  );
};
function mapStateToProps({ values, apiData, errors, currentStep }) {
  return {
    initialValues: values,
    apiData,
    globalErrors: errors,
    currentStep
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentStep: (value) => dispatch({ type: UPDATE_CURRENT_STEP, value }),
    updateStepValues: (values) => dispatch({ type: UPDATE_FORM_VALUES, values }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Address);
