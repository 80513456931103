import React from 'react'
import { useFormContext } from 'react-hook-form';
import TextAsyncSearchField from "./form-fields/TextAsyncSearchField";
import { connect, useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import TextField from './form-fields/TextField';
import { generalErrorMessage, invalidOriErrorMessage, maxLength, minLength } from "../utils/errorMessage";
import { patternRgx } from "../utils/formValidator";
import { orderType } from '../utils/orderType';
import { autoCompleteOri, autoCompleteOriCa } from '../store/actions/form';
import { checkOri, getCaOriAgencies, setIsOriCompleteValue } from '../store/reducers';
import _ from "lodash";
import { showNotification } from '../services/fieldsErrorsHandler';

const Ori = ({ initialValues, apiData, isLoading, caOriAutoComplete, getOri, showRedirectionModalHandler }) => {
  const { control, setValue, getValues,clearErrors, reset } = useFormContext();
  const dispatch = useDispatch();
  const orderTypeId = getValues('orderTypeId');

  const handleOriChanged = (value) => {
    const oriValue = typeof value === "string" ? value : value.length ? value[0] : "";
    oriValue.length < 5 && dispatch(setIsOriCompleteValue(false))
    setValue("ori", oriValue);
    initialValues.ori = oriValue; 
}

  const handleSelect = (option) => {
    clearErrors(["ori"])
    dispatch(getCaOriAgencies(option))
    dispatch(setIsOriCompleteValue(true))
    reset({...initialValues, authorizedApplicantType : "" })
  }
  const handleSearch = async (value) => {
    if (orderTypeId === orderType.FDLE) {
      dispatch(autoCompleteOri(value))
    }
    else if (orderTypeId === orderType.CA) {
      dispatch(autoCompleteOriCa(value, getValues('orderTypeId')));
    } else {
      dispatch(autoCompleteOri(value))
    }
  }

  const isFdleOriValid = (ori) => {
    const regex = patternRgx.alphaNumeric.value;
    return (regex.test(ori) && ori.length === 9)
  }
  const handleOriRedirection = _.debounce(async (ori) => {
    try {
      if (isFdleOriValid(ori)) {
        const isOriValid = await getOri({ ori, orderTypeId: orderType.FDLE });
        isOriValid && showRedirectionModalHandler();
      }
    } catch (error) {
      showNotification('warning', 'Warning', generalErrorMessage());
    }
  }, 1000);
  
  return (
    <>
      {orderTypeId === orderType.FDLE &&
        <Col md="6">
          <TextAsyncSearchField
            control={control}
            name="ori"
            label="ORI"
            defaultInputValue={initialValues.ori}
            asyncData={apiData.oriAutoComplete}
            isLoading={isLoading}
            handleChange={handleOriChanged}
            handleSearch={handleSearch}
            rules={{
              required: "Required",
              minLength: {
                value: 5,
                message: minLength(5),
              },
              maxLength: {
                value: 9,
                message: maxLength(9),
              },
              pattern: patternRgx.alphaNumeric
            }}
          />
        </Col>
      }
      {orderTypeId === orderType.PRINTTOCARD &&
        <Col md="6">
          <TextField
            control={control}
            name="ori"
            type="text"
            label="ORI"
            rules={{
              minLength: {
                value: 1,
                message: minLength(1),
              },
              maxLength: {
                value: 20,
                message: maxLength(20),
              },
              pattern: patternRgx.alphaNumeric,
            }}
            onChangeCallBack={(e) => handleOriRedirection(e.target.value)}
          />
          
        </Col>
      }
      {
        orderTypeId === orderType.CA &&
        <Col md="6">
          <TextAsyncSearchField
            control={control}
            name="ori"
            label="ORI"
            defaultInputValue={getValues('ori')}
            asyncData={caOriAutoComplete}
            isLoading={isLoading}
            handleChange={handleOriChanged}
            handleSearch={handleSearch}
            handleSelect={handleSelect}
            rules={{
              required: "Required",
              minLength: {
                value: 5,
                message: minLength(5),
              },
              maxLength: {
                value: 9,
                message: maxLength(9),
              },
              pattern: patternRgx.alphaNumeric,
              validate: (value) => {
                 const valid = caOriAutoComplete.includes(value.toUpperCase());
                 valid && dispatch(setIsOriCompleteValue(true))
                return valid || invalidOriErrorMessage();
                }

            }}
          />
        </Col>
      }

    </>
  )
}
function mapStateToProps({ values, apiData, errors, isLoading, currentStep, applicantInfo, caOriAutoComplete }) {
  return {
    initialValues: values,
    apiData,
    applicantInfo,
    globalErrors: errors || {},
    isLoading,
    currentStep,
    caOriAutoComplete,

  };
}
const mapDispatchToProps = (dispatch) => {
  return {
      getOri: (getOri) => dispatch(checkOri(getOri)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Ori)
