import Sidebar from "./Sidebar";
import {corporateBillingLink, faqLink, formsLink, getFingerprintLink, howItWorksLink, liveScanSystemLink, loginLink, mobileServiceLink, blogLink, certifixlivescan } from "../utils/links";
import { blogTitle, corpeorateBillingTitle, faqTitle, fromsTitle, getFingerprintTitle, howItWorksTitle, liveScanSystemTitle, loginTitle, mobileServiceTitle } from "../utils/titles";
import cerifixLogo from "../assets/images/certifix-logo.png"; 
import '../assets/scss/Header.scss';

const Header = () => {
  
  return (
    <>
        <header id="masthead" className="site-header" >
            <div className="top-bar">
            <div className="container">
                <div className="row">
                <div className="col-md-12 text-right">
                    <nav id="top-navigation" className="top-navigation">
                    <div className="menu-top-menu-container">
                        <ul id="menu-top" className="menu">
                        <li id="menu-item-688" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-688"><a href={blogLink()} aria-current="page">{blogTitle()}</a></li>
                        <li id="menu-item-683" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-683"><a href={faqLink()}>{faqTitle()}</a></li>
                        <li id="menu-item-684" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-684"><a href={formsLink()}>{fromsTitle()}</a></li>
                        <li id="menu-item-685" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-685"><a href={loginLink()}>{loginTitle()}</a></li>
                        </ul>
                    </div>
                    </nav>
                </div>
                </div>
            </div>
            </div>
            <div className="container">
            <div className="row">
                <div className="col-md-3 col-5">
                <div className="site-branding">
                    <a href={certifixlivescan()} className="custom-logo-link" rel="home">
                    <img src={cerifixLogo} className="custom-logo" alt="Certifix Live Scan" />
                    </a>
                </div>
                </div>
                <div className="col-md-9 col-7">
                <nav id="site-navigation" className="main-navigation">
                    <div className="menu-main-menu-container">
                    <ul id="primary-menu" className="menu">
                        <li id="menu-item-689" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-689"><a href={howItWorksLink()}>{howItWorksTitle()}</a></li>
                        <li id="menu-item-690" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-690"><a href={mobileServiceLink()}>{mobileServiceTitle()}</a></li>
                        <li id="menu-item-691" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-691"><a href={corporateBillingLink()}>{corpeorateBillingTitle()}</a></li>
                        <li id="menu-item-692" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-692"><a href={liveScanSystemLink()}>{liveScanSystemTitle()}</a></li>
                        <li id="menu-item-693" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-693"><a href={getFingerprintLink()}>{getFingerprintTitle()}</a></li>
                    </ul>
                    </div>					
                </nav>
                <Sidebar />
                </div>
            </div>
            </div>
        </header>
    </>
    
  )
}

export default Header;