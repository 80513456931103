import useLocationById from '../../services/useGetLocation';
import useLocations from '../../services/useLocations';
import useStaticData from '../../services/useStaticData';
import useGetOriFees from '../../services/useGetOriFees';
import useCheckOri from '../../services/useCheckORI';
import useCreateOrder from '../../services/useCreateOrder';
import useValidateAccountNumber from '../../services/useValidateAccountNumber';
import useDownloadOrderPdf from '../../services/useDownloadOrderPdf';
import useGetOrderTypes from '../../services/useGetOrderTypes';
import useGetServiceLogo from '../../services/useGetServiceLogo';

import {
  REQ_START,
  REQ_END
} from '../actions/async';
import {
  UPDATE_LOCATIONS,
  SET_API_DATA,
  UPDATE_FORM_VALUES,
  UPDATE_FORM_ERRORS,
  UPDATE_CURRENT_STEP,
  setApiData,
  setLocationsData,
  SAVE_SEARCH_QUERY,
  setOriFees,
  SET_ORI_FEES,
  setOriValidity,
  SET_ORI_VALID,
  setOrderDetails,
  SET_ORDER_DETAILS,
  SET_IS_UNDER_RETRIAL,
  SET_FORM_ERRORS,
  setBookingSlots,
  SET_BOOKING_SLOTS,
  SET_BOOKING_CONFIRMATION_NUMBER,
  setBookingInfo,
  SET_LOCATION_ID,
  SET_LOCATION_INFO,
  FETCH_ORI_LIST_SUCCESS,
  setOrderTypeId,
  SET_ORDER_TYPE,
  SET_FORM_INITIAL_VALUES,
  setFormInitialValues,
  SET_ORDER_TYPE_DATA,
  SET_ACCOUNT_INFO,
  setAccountInfo,
  setDownloadOrderPdf,
  setApplicantInfo,
  SET_APPLICANT_INFO,
  SET_CA_ORI,
  SET_FB_CA,
  SET_CA_ORI_AGENCIES,
  setCaOriAgencies,
  setFbCa,
  SET_IS_ORI_COMPLETE,
  setIsOriComplete,
  setIsShowMoreClicked,
  SET_IS_SHOW_MORE_CLICKED,
  SET_IS_SUBMITTING,
  setIsSubmitting,
  setOrderTypes,
  SET_ORDER_TYPES,
  setServiceLogo,
  SET_SERVICE_LOGO,
  SET_FDLE_REDIRECTION_INFO,
  setFdleRedirectionInfo,

} from '../actions/form';
import useSlotsById from '../../services/useGetBookingSlots';
import useBookAppointment from '../../services/useBookAppointment';
import { orderType } from '../../utils/orderType';
import { PaymentType } from '../../utils/paymentType';
import { locationErrorMessage } from '../../utils/errorMessage';
import useGetCaOriAgencies from '../../services/useGetCaOriAgencies';
import useMapOriAgencies from '../../services/useMapOriAgencies';
import { errorCodes } from '../../utils/errorCodes';

const initialState = {
  values: {},
  errors: {},
  currentStep: 1,
  orderTypeId: 0,
  isUnderRetrial: false,
  apiData: {
    countries: [],
    gender: [],
    hairColor: [],
    eyeColor: [],
    race: [],
    oriAutoComplete: [],
    streetDirection: [],
    usStates: [],
  },
  onAccountData: {
    agreementType: 0,
  },
  oriFees: {
    GovFee: 0,
    ServiceFee: 0,
    TotalFee: 0,
  },
  oriValid: null,
  searchQuery: "",
  locations: {
    data: [],
    error: null,
  },
  orderDetails: {
    ReferenceId: '',
    SterlingRegistrationCode: '',
    OrderLocation: {},
    locationId: '',
    locationInfo: []
  },
  applicantInfo: {
    firstName: "",
    lastName: "",
    email: ""
  },
  bookingSlots: {},
  confirmedBookingDetails: {},
  isLoading: false,
  caOriAutoComplete: [],
  selectedFbCa: null,
  caOriAgencies: {
    authApplicantTypes: [],
    authorizedAgency: ""

  },
  isOriComplete: false,
  isShowMoreClicked: false,
  isSubmitting: false,
  imagesSrc: [],
  fdleRedirectionInfo: undefined
};

export default function form(state = initialState, action) {
  switch (action.type) {
    case REQ_START:
      return {
        ...state,
        isLoading: true,
      };

    case REQ_END:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_LOCATIONS:
      const [dataArray, error] = action.data;
      return {
        ...state,
        locations: {
          data: [...dataArray],
          error: !dataArray.length
            ? locationErrorMessage()
            : error,
        },
      };

    case SET_API_DATA:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          ...action.data,
        },
      };
    case SET_ORDER_TYPES:
      return {
        ...state,
        orderTypes: {
          ...action.data,
        },
      };
    case SET_SERVICE_LOGO:
      return {
        ...state,
        imagesSrc: [
          ...state.imagesSrc, action.data
        ],
      };
    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.value,
      };

    case SAVE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.query,
      };

    case UPDATE_FORM_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.values,
        },
      };

    case UPDATE_FORM_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.errors,
        },
      };
    case SET_ORI_FEES:
      return {
        ...state,
        oriFees: action.fees,
      };
    case SET_ORI_VALID:
      return {
        ...state,
        oriValid: action.isValid
      }
    case SET_IS_UNDER_RETRIAL:
      return {
        ...state,
        isUnderRetrial: action.value
      }
    case SET_ORDER_DETAILS:
      const { ReferenceId, SterlingRegistrationCode, OrderLocation ,TotalAmount } = action.orderDetails;
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ReferenceId,
          SterlingRegistrationCode,
          OrderLocation,
          TotalAmount
        }
      }
    case SET_BOOKING_CONFIRMATION_NUMBER:
      return {
        ...state,
        confirmedBookingDetails: action.bookingInfo,
      };
    case SET_BOOKING_SLOTS:
      return {
        ...state,
        bookingSlots: action.slots,
      };
    case SET_FORM_ERRORS:
      return {
        ...state,
        errors: {
          ...action.errors,
        },
      };
    case SET_LOCATION_ID:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          locationId: action.id,
        },
      };
    case SET_LOCATION_INFO:
      const selectedInfo = [{ ...action.info }];
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          locationInfo: selectedInfo
        }
      }
    case SET_ACCOUNT_INFO:
      const agreementType = action.info.agreementType
      return {
        ...state,
        onAccountData: { agreementType }
      }
    case FETCH_ORI_LIST_SUCCESS:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          oriAutoComplete: action.data,
        },
      };
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderTypeId: action.orderType,
      };
    case SET_FORM_INITIAL_VALUES:
      return {
        ...state,
        values: action.values,
      };
    case SET_ORDER_TYPE_DATA:
      return {
        ...state,
        oriFees: {
          GovFee: 0,
          ServiceFee: action.orderType.Price,
          TotalFee: action.orderType.Price
        }
      }
    case SET_APPLICANT_INFO:
      return {
        ...state,
        applicantInfo: action.info
      }
    case SET_CA_ORI:
      return {
        ...state,
        caOriAutoComplete: action.oris
      }
    case SET_FB_CA:
      return {
        ...state,
        selectedFbCa: action.fb
      }
    case SET_CA_ORI_AGENCIES:
      const { authApplicantTypes, authorizedAgency } = action.agencies
      return {
        ...state,
        caOriAgencies: {
          authApplicantTypes,
          authorizedAgency
        }
      }
      case SET_IS_ORI_COMPLETE:
        return {
          ...state,
          isOriComplete: action.value
        }
      case SET_IS_SHOW_MORE_CLICKED:
          return {
            ...state,
            isShowMoreClicked: action.value
          }
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.value
      }
      case SET_FDLE_REDIRECTION_INFO:
        return {
          ...state,
          fdleRedirectionInfo: action.value
        }
    default:
      return state;
  }
}

export const getApiStaticData = () => async (dispatch) => {
  const response = await useStaticData();
  response && dispatch(setApiData(response[0]));
};

export const getOrderTypes = () => async (dispatch) => {
  dispatch({ type: REQ_START })
  const response = await useGetOrderTypes();
  response && dispatch(setOrderTypes(response[0]));
  dispatch({ type: REQ_END });
};

export const getServiceLogo = (id) => async (dispatch) => {
  dispatch({ type: REQ_START })
  const response = await useGetServiceLogo(id);
  const logo = {serviceId: id , data: response[0]}
  response && dispatch(setServiceLogo(logo));
  dispatch({ type: REQ_END });
  return response;
};

export const searchLocations = (searchQuery) => async (dispatch) => {
  dispatch({ type: REQ_START });
  const response = await useLocations(searchQuery);
  response && dispatch(setLocationsData(response));
  dispatch({ type: REQ_END });
};

export const getLocation = (locationId) => async (dispatch) => {
  dispatch({ type: REQ_START });
  const response = await useLocationById(locationId);
  response && dispatch(setLocationsData([...response]));
  dispatch({ type: REQ_END });
};

export const getBookingSlots =
  (locationId, endDateTime) => async (dispatch) => {
    dispatch({ type: REQ_START });
    const response = await useSlotsById(locationId, endDateTime);
    response[0] && dispatch(setBookingSlots(response[0]));
    dispatch({ type: REQ_END });
  };

export const getFees = (getOriFees) => async (dispatch) => {
  dispatch({ type: REQ_START })
  const response = await useGetOriFees(getOriFees);
  response[0]?.TotalFee && dispatch(setOriFees(response[0]));
  dispatch({ type: REQ_END });
  return response;
};



export const checkOri = (getOri) => async (dispatch) => {
  dispatch({ type: REQ_START });
  const response = await useCheckOri(getOri, dispatch);
  response && dispatch(setOriValidity(response));
  dispatch({ type: REQ_END });
  return response && (response[1] || response[0]?.Data?.length === 0)
    ? false
    : true;
};

export const createOrder = (data, setError, callback) => async (dispatch) => {
  dispatch({ type: REQ_START });

  try {
    const response = await useCreateOrder(data, setError, dispatch);
    if (response && Object.keys(response[0])?.length) {
      dispatch(setOrderDetails(response[0]));
      callback();
    }
    if(response[1]?.Errors[0]?.Code === errorCodes.underRetrialOrder) {
      callback();
    }  
    if(response[1]){
      dispatch(setIsSubmitting(false))
      dispatch({ type: REQ_END });
    }
  } catch (error) {
    dispatch({ type: REQ_END });
  }
};

export const bookTimeSlot =
  (referenceId, startTime, feedBack) => async (dispatch) => {
    dispatch({ type: REQ_START });
    const response = await useBookAppointment(referenceId, startTime);
    dispatch({ type: REQ_END });

    if (response[0]?.length) {
      dispatch(setBookingInfo(response));
      feedBack({ type: "success", bookingNum: response });
    }

    if (response[1] && response[1]["Errors"]) {
      feedBack({ type: "error", message: response[1]["Errors"][0]?.Message });
    }
  };

export const setOrderType = (orderTypeId) => async (dispatch) => {
  dispatch(setOrderTypeId(orderTypeId));
};

export const initForm = (orderTypeId, firstName, lastName, middleName, ori, externalLocationId) => async (dispatch) => {
  let formInitialValues = {
    orderTypeId: orderTypeId,
    externalLocationId: externalLocationId ? externalLocationId : "",
    lastName: lastName ? lastName : "",
    firstName: firstName ? firstName :"",
    middleName:middleName ? middleName : "",
    emailAddress: "",
    dateOfBirth: null,
    socialSecurityNumber: "",
    gender: "",
    heightType: "Standard",
    height: "",
    heightInch: "",
    weightType: "Standard",
    weight: "",
    eyeColor: "",
    hairColor: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    cardHolderName: "",
    cardNumber: "",
    cvc: "",
    expirationMonth: "",
    expirationYear: "",
    countryOfCitizenship: "",
    ori: ori ? ori : "",
    accountNumber: "",
    accountNumberCheck: "",
    reasonDetails: "",
    paymentType: PaymentType.onApplicant,
    isRequiredSSN: (orderTypeId === orderType.FDLE || orderTypeId === orderType.CA) ? true : false,
  }

  const fdleInitial = {
    suffix: "",
    extension: "",
    reason: "",
    oca: "",
    aliasesLastName: "",
    aliasesFirstName: "",
    aliasesMiddleName: "",
    aliasesSuffix: "",
    phoneNumber: "",
    race: "",
    placeOfBirth: "",


  };

  const printTocardInitial = {
    accountAgreement: 0,
    phoneNumber: "",
    race: "",
    placeOfBirth: "",


  };
  const fbiInitial = {
    reason: "",
    isUsCitizen: null,
    lastFourDigitsSSN: "",
    isFbiAgreement: null,
    accountAgreement: 0,
    phoneNumber: "",
    race: "",
    placeOfBirth: "",


  };
  const caInitial = {
    authorizedApplicantType: '',
    typeOfLicense: '',
    authorizedAgency: '',
    mailCode: '',
    agencyStreetNumber: '',
    agencyStreetDirection: '',
    agencyStreetName: '',
    agencyCity: '',
    agencyState: '',
    agencyZipCode: '',
    agencyContactName: '',
    agencyTelephoneNumber: '',
    driverLicense: '',
    streetNumber: '',
    streetName: '',
    streetDirection: '',
    apartmentNumber: '',
    levelOfServiceFbi: false,
    levelOfServiceDoj: false,
    oca: '',
    billingNumber: '',
  }
  switch (orderTypeId) {
    case orderType.FDLE:
      formInitialValues = { ...formInitialValues, ...fdleInitial };
      break;
    case orderType.PRINTTOCARD:
      formInitialValues = { ...formInitialValues, ...printTocardInitial };
      break;
    case orderType.FBI:
      formInitialValues = { ...formInitialValues, ...fbiInitial };
      break;
    case orderType.CA:
      formInitialValues = { ...formInitialValues, ...caInitial };
      break;
    default:
      break;
  }
  dispatch(setFormInitialValues(formInitialValues));
};

export const validateAccountNumber = (accountNumber) => async (dispatch) => {
  dispatch({ type: REQ_START });
  const response = await useValidateAccountNumber(accountNumber);
  if (response[0]) {
    dispatch(setAccountInfo(response[0]))
  } else {
    dispatch(setAccountInfo(initialState.onAccountData))
  }

  dispatch({ type: REQ_END });
  return (response && (response[1] || response[0]?.Data?.length === 0)) ? false : true;
}
export const updateApplicantInfo = (firstName, lastName) => async (dispatch) => {
  dispatch(setApplicantInfo({ firstName, lastName }))
}
export const downloadOrderPdf = (orderCode, email, fileName, downloadingPdf) => async (dispatch) => {
  dispatch({ type: REQ_START })
  const response = await useDownloadOrderPdf(orderCode, email, fileName, downloadingPdf);
  response[0] && dispatch(setDownloadOrderPdf(response[0]));
  dispatch({ type: REQ_END });
  return response;
}
export const setFbCaValue = (fbCaVal) => async (dispatch) => {
  await dispatch(setFbCa(fbCaVal))
}
export const setIsOriCompleteValue = (oriValue) => async (dispatch) => {
  await dispatch(setIsOriComplete(oriValue))
}
export const setIsShowMoreClickedValue = (value) => async (dispatch) => {
  await dispatch (setIsShowMoreClicked(value))
}

export const getCaOriAgencies = (ori) => async (dispatch) => {
  dispatch({ type: REQ_START })
  const response = await useGetCaOriAgencies(ori)
  const authorizedAgencyInfo = useMapOriAgencies(response);
  authorizedAgencyInfo && await (dispatch(setCaOriAgencies(authorizedAgencyInfo)) && dispatch(setOriValidity(true)))
  dispatch({ type: REQ_END });
  return response && (response[1] || response[0]?.data?.length === 0)
    ? false
    : true;
}
export const isOriValid = (ori, orderTypeId) => async (dispatch) => {
  dispatch({ type: REQ_START });
  const response = await useGetCaOriAgencies(ori, orderTypeId);
  dispatch({ type: REQ_END });
  return response && (response[1] || response[0]?.data?.length === 0)
    ? false
    : true;
};
export const setIsSubmittingValue = (value) => async (dispatch) => {
  await dispatch(setIsSubmitting(value))
}

export const updateFdleRedirectionInfo = (info) => async (dispatch) => {
  dispatch(setFdleRedirectionInfo(info))
}