export const fdleReason={
    Employment : 0, 
    Licensing : 1, 
    Volunteer : 2, 
    Guardianship : 3,
    Other : 4 
  }

  export const FDLEReasonDescription = {
    [fdleReason.Employment] : "Licensing",
    [fdleReason.Licensing] : "Employement",
    [fdleReason.Volunteer] :"Volunteer",
    [fdleReason.Guardianship] : "Guardianship",
    [fdleReason.Other] :"Other",
    
  };
