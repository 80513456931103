import React from 'react';
import { Row } from 'react-bootstrap';
import ServiceItem from './ServiceItem';
import { connect } from 'react-redux';


const Services = ({ orderTypes }) => {
  let services = [];
  if(orderTypes){
    services = Object.keys(orderTypes).map(key => {
  
      const service = {
        orderTypeId: orderTypes[key].Id,
        serviceId: orderTypes[key].Setting.Id,
        title: orderTypes[key].Setting.Value.title,
        subHeader: orderTypes[key].Setting.Value.description,
        route: orderTypes[key].Uri
      }
      return service;
    })
  }

  
  return (
    <div className='services-container'>
      <div className='service-selection'>
        <Row className="mb-4">
          {
            services.map(service => {
              return (
                <ServiceItem key={service.orderTypeId} service={service} />
              )
            })
          }
        </Row>
      </div>
    </div>

  )
}

function mapStateToProps({ orderTypes }) {
  return {
    orderTypes
  }
}
export default connect(mapStateToProps)(Services)
