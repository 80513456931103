
import TagManager from 'react-gtm-module'
import { generateRandomUUID } from './helper';
import { orderTypeGtmItem } from './orderType';

const gtmId = process.env.REACT_APP_GTM_ID;

function initializeGtm() {
    const tagManagerArgs = {
        gtmId: gtmId
    }
    TagManager.initialize(tagManagerArgs)
}

function gtmPushData(data) {
    TagManager.dataLayer({ dataLayer: data });
}

const firePurchaseEvent = (ecommerceData) => {
    gtmPushData({
        event: 'purchase',
        ecommerce: ecommerceData
    })

}
const prepareEcommerceObject = (orderData, orderCode, totalFees, storeLocation) => {
    return {
        user_id: generateRandomUUID(),
        transaction_id: orderCode,
        affiliation: '',
        value: totalFees,
        tax: 0.00,
        shipping: 0.00,
        currency: 'USD',
        coupon: '',
        items: [{
            item_id: orderTypeGtmItem.get(orderData.orderTypeId),
            item_brand: storeLocation.Name,
            item_name: 'Booking',
            item_category: storeLocation.City,
            item_category2: storeLocation.State,
            item_category3:"",
            affiliation: '',
            coupon: '',
            discount: '0.00',
            price: totalFees,
            quantity: 1,
        }],

        customer: [{
            email: orderData.emailAddress,
            phone_number: orderData.phoneNumber,
            first_name: orderData.firstName,
            last_name: orderData.lastName,
            home_address: {
                street: !orderData.address1 ? `${orderData.streetNumber} ${orderData.streetDirection} ${orderData.streetName}` : orderData.address1,
                city: orderData.city,
                region: orderData.state,
                postal_code: orderData.zip,
                country: 'US'
            }
        }]
    }
}




export { initializeGtm, gtmPushData, prepareEcommerceObject, firePurchaseEvent };