import React, { useEffect } from "react";
import { Route, Switch, Redirect } from 'react-router';
import { orderType, orderTypeBrowserTitle } from "./utils/orderType";
import Services from './components/Services';
import RegistrationCompleted from "./components/RegistrationCompleted";
import WizardForm from "./components/WizardForm";
import { setOrderType, initForm, getApiStaticData, getOrderTypes } from "./store/reducers";
import { connect, useDispatch } from "react-redux";
import { UPDATE_CURRENT_STEP, saveSearchQuery , SET_IS_UNDER_RETRIAL, clearFormErrors } from "./store/actions/form";
import { wizardSteps } from "./utils/wizardSteps";
import { setBrowserTitle } from "./utils/helper";

const Routes = ({ orderTypeId, saveSearchQuery }) => {
    const dispatch = useDispatch();
    dispatch(getApiStaticData());

    useEffect(()=>{
        dispatch(getOrderTypes());
    },[dispatch])

    const handleDispatch = (id) => {
        dispatch(clearFormErrors());
        dispatch({ type: UPDATE_CURRENT_STEP, value: wizardSteps.StepOne })
        saveSearchQuery();     
        dispatch({ type: SET_IS_UNDER_RETRIAL, value: false });
        setBrowserTitle(orderTypeBrowserTitle.get(id));
        dispatch(initForm(id));
        
        if(id){
            dispatch(setOrderType(id));
        }
    }
    
    return (
        <Switch>
          
            <Route
                exact
                path='/services/fdle'
                render={() => {
                    handleDispatch(orderType.FDLE)
                    return <WizardForm />
                }}

            />
            <Route
                exact
                path='/services/ink-card-fingerprinting'
                render={() => {
                    handleDispatch(orderType.PRINTTOCARD)
                    return <WizardForm />
                }}

            />
            <Route
                exact
                path='/services/fbi'
                render={() => {
                    handleDispatch(orderType.FBI)
                    return <WizardForm />
                }}

            />
                   <Route
                    exact
                    path='/services/ca'
                    render={(props) => {
                        handleDispatch(orderType.CA)
                        return <WizardForm />
                    }}
                />
            <Route path="/services/fdle/success" >
                {orderTypeId === orderType.FDLE ? <RegistrationCompleted /> : <Redirect to='/services' />}
            </Route>
            <Route path="/services/ink-card-fingerprinting/success" >
                {orderTypeId === orderType.PRINTTOCARD ? <RegistrationCompleted/> : <Redirect to='/services' />}
            </Route>
            <Route path="/services/fbi/success" >
                {orderTypeId === orderType.FBI ? <RegistrationCompleted/> : <Redirect to='/services' />}
            </Route>
            <Route path="/services/ca/success" >
                {orderTypeId === orderType.CA ? <RegistrationCompleted /> : <Redirect to='/services' />}
            </Route>


            <Route path='/services' 
                render={() => {
                    handleDispatch();
                    return <Services />
                }}>
            </Route>
            <Route path="/" >
                <Redirect to='/services' />
            </Route>


        </Switch>
    )
}
const mapStateToProps = ({ orderTypeId }) => ({
    orderTypeId
});

const mapDispatchToProps = (dispatch) => {
    return {
      saveSearchQuery: (query) => dispatch(saveSearchQuery(query)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Routes);