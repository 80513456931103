import { notification } from 'antd';
import { SET_FORM_ERRORS, SET_IS_UNDER_RETRIAL, UPDATE_CURRENT_STEP } from '../store/actions/form';
import { generalErrorMessage, noAppointmentErrorMessage, orderAlreadyBookedMessage, bookCanceledOrderMessage, appointmentNotAvailableMessage, bookAppointmentForbiddenMessage, invalidDateMessage, invalidCbsAccountMessage } from '../utils/errorMessage';
import { errorCodes } from '../utils/errorCodes';
import { REQ_END } from '../../src/store/actions/async';
import { stepsFields } from '../utils/orderType';

export const showNotification = (type, message, description) => {
  notification[type]({
    message,
    description: description || generalErrorMessage(),
    placement: 'topRight',
    duration: 10
  });
};

const handleAddressErrors = _ => {
  const addressFields = ['address1', 'address2', 'city', 'state', 'zip', 'country'];
  return addressFields.map(field => ({ Field: field, Message: 'Invalid Field' }))
}

const getFieldStepIndex = (fieldName, orderTypeId) => {
  const orderStepsFields = stepsFields.get(orderTypeId);
  return orderStepsFields.filter(step => step.includes(fieldName)).map(step => orderStepsFields.indexOf(step))[0]
}

const markFieldsInvalid = (validationErrors, setError, dispatch, orderTypeId) => {
  if (validationErrors[0]?.Field === 'endDateTime') {
    showNotification('warning', 'Warning', invalidDateMessage());
    return;
  }

  let stepToJumbTo = [];
  const addressError = validationErrors.find(error => error.Field === 'address');
  const validationErrorsExtended = addressError
    ? validationErrors.filter(error => error.Field !== 'address').concat(handleAddressErrors())
    : validationErrors;

  const rhfErrors = validationErrorsExtended.map(error => ({ type: 'required', name: error.Field.startsWith("address.") ? error.Field.substr(8) : error.Field, message: error.Message.startsWith("address.") ? error.Message.substr(8) : error.Message }));
  const errorsObj = {}

  for (let i = 0; i < rhfErrors.length; i++) {
    errorsObj[rhfErrors[i].name] = rhfErrors[i]
    let fieldStep = getFieldStepIndex(rhfErrors[i].name, orderTypeId)
    if (stepToJumbTo.length) {
      if (fieldStep < stepToJumbTo[0]) {
        stepToJumbTo[0] = fieldStep;
      }
    } else {
      stepToJumbTo[0] = fieldStep;
    }
  }
  if (stepToJumbTo[0]) {
    dispatch({ type: SET_FORM_ERRORS, errors: errorsObj })
    dispatch({ type: UPDATE_CURRENT_STEP, value: stepToJumbTo[0] + 1 })
    rhfErrors.forEach((rhfError) => {
      const { name, type, message } = rhfError;
      setError(name, { type, message })
    }
    )
  }
  dispatch({type: REQ_END})
};


const errorHandler = ({ Errors, Validations }, setError, dispatch, orderTypeId) => {
  const { Code, Message } = Errors[0] || {};

  switch (Code) {
    case errorCodes.stripeError:
    case errorCodes.sterlingError:
    case errorCodes.sterlingNotFound:
      showNotification('error', 'Error', Message);
      break;

    case errorCodes.generalError:
    case errorCodes.cscanError:
      showNotification('warning', 'Warning', generalErrorMessage());
      break;

    case errorCodes.locationNotAcceptable:
      showNotification('info', 'Info', noAppointmentErrorMessage());
      break;

    case errorCodes.orderAlreadyBooked:
      showNotification('warning', 'Warning', orderAlreadyBookedMessage());
      break;

    case errorCodes.bookCanceledOrder:
      showNotification('warning', 'Warning', bookCanceledOrderMessage());
      break;

    case errorCodes.UnderRetrialOrder:
      dispatch({ type: SET_IS_UNDER_RETRIAL, value: true });
      dispatch({ type: REQ_END });
      break;

    case errorCodes.bookAppointmentForbidden:
      showNotification('error', 'Error', bookAppointmentForbiddenMessage());
      break;

    case errorCodes.appointmentNotAvailable:
      showNotification('error', 'Error', appointmentNotAvailableMessage());
      break;

    case errorCodes.validationError:
    case errorCodes.stripeValidation:
    case errorCodes.sterlingValidation:
      markFieldsInvalid(Validations, setError, dispatch, orderTypeId);
      dispatch({ type: REQ_END });
      break;

    case errorCodes.invalidCbsAccount:
      setError("accountNumberCheck", { message: invalidCbsAccountMessage() })
      dispatch({ type: REQ_END });
      break;

    default:
      break;
  }
};


export default errorHandler;