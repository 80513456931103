import React from 'react'
import ApplicationSubmission from './ApplicationSubmission'
import AgencyInfo from './AgencyInfo'
import { connect } from 'react-redux'
import { UPDATE_CURRENT_STEP, UPDATE_FORM_VALUES } from '../store/actions/form'
import { getCaOriAgencies, setIsShowMoreClickedValue } from '../store/reducers';
import { FormProvider, useForm } from 'react-hook-form'
import { Row, Col } from 'react-bootstrap'
import { orderType } from '../utils/orderType'
import { scrollToTop } from "../utils/scroll";
import { invalidOriErrorMessage } from '../utils/errorMessage'


const SubmissionAndAgencyInfo = ({ initialValues, updateCurrentStep, updateStepValues, checkORIValid, currentStep, caOriAgencies, setIsShowMoreButtonClicked, isShowMoreClicked, isOriCompleted }) => {
  const formMethods = useForm({ defaultValues: initialValues, mode: "all" });
  const onBack = (_) => {
    const values = formMethods.getValues();
    updateStepValues(values);
    updateCurrentStep(--currentStep);
    scrollToTop("box-wrapper");
  };
  const onSubmit = async (data) => {
    data.authorizedAgency = caOriAgencies.authorizedAgency
    updateStepValues(data);
    if (initialValues.orderTypeId === orderType.CA) {
      const isValidOri = await checkORIValid(formMethods.getValues('ori'));
      if (isValidOri !== true) {
        formMethods.setError("ori", {
          type: "manual",
          message: invalidOriErrorMessage(),
        });
      } else {
        updateCurrentStep(++currentStep);
      }
    } else {
      updateCurrentStep(++currentStep);
    }
    scrollToTop("box-wrapper");
  };
  const handleShowMore = () => {
    setIsShowMoreButtonClicked(true)
  }
  return (
    <>
      <FormProvider {...formMethods}>
        <form id="submission-and-agency-info-form" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Application Submission</h3>
            </Col>
          </Row>
          <ApplicationSubmission />
          <div className={isShowMoreClicked ? 'hide-more-btn' : 'show-more-btn'} onClick={handleShowMore}>+ Show more</div>

         { isShowMoreClicked && 
         <>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Contributing Agency Information</h3>
            </Col>
          </Row>
          <AgencyInfo />
          </>}

          <Row className="mt-4 mb-3">
            <Col md="12">
              <input type="button" className="btn btn-outline-primary" value="Back" onClick={onBack} />
              <input type="submit" className="btn btn-primary" value="Next" />
            </Col>
          </Row>
        </form>

      </FormProvider>

    </>
  )
}

function mapStateToProps({ values, errors, currentStep, applicantInfo, caOriAgencies, isShowMoreClicked }) {
  return {
    initialValues: values,
    applicantInfo,
    globalErrors: errors || {},
    currentStep,
    caOriAgencies, 
    isShowMoreClicked,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentStep: (value) => dispatch({ type: UPDATE_CURRENT_STEP, value }),
    updateStepValues: (values) => dispatch({ type: UPDATE_FORM_VALUES, values }),
    checkORIValid: (ori) => dispatch(getCaOriAgencies(ori)),
    setIsShowMoreButtonClicked : (value) => dispatch(setIsShowMoreClickedValue(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionAndAgencyInfo);
