import useApiResult from './useApiResult';
import { bookAppointment } from './apiRequests';

const useBookAppointment = async (orderReferenceId, startTime) => {
  const request = bookAppointment(orderReferenceId, startTime);
  const response = await useApiResult(request);

  // If Response Has Error, return it as is
  if (!response || response[1]) {
    return response
  }
  // If Respones with Data => process locations data

  if (response[0]) {
    const { Data } = response[0];
    
    return [Data['confirmationNumber'], response[1]];
  }

  return response;
}

export default useBookAppointment;
