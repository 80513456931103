import axios from "axios";
import useGetOri from "../../services/useGetOri";
import { httpStatusCode } from "../../utils/errorCodes";
import { notification } from "antd";
import { generalErrorMessage } from "../../utils/errorMessage";

export const API_BASE = process.env.REACT_APP_API_URL;

export const SET_API_DATA = 'SET_API_DATA';
export const UPDATE_FORM_VALUES = 'UPDATE_FORM_VALUES';
export const UPDATE_FORM_ERRORS = 'UPDATE_FORM_ERRORS';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY';
export const GET_LOCATION_BY_ID = 'GET_LOCATION_BY_ID';
export const CALCULATE_ORI_FEES = 'CALCULATE_ORI_FEES';
export const SET_ORI_FEES = 'SET_ORI_FEES';
export const SET_ORI_VALID = 'SET_ORI_VALID';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const SET_IS_UNDER_RETRIAL='SET_IS_UNDER_RETRIAL';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';
export const SET_BOOKING_SLOTS = 'SET_BOOKING_SLOTS';
export const SET_BOOKING_CONFIRMATION_NUMBER = 'SET_BOOKING_CONFIRMATION_NUMBER';
export const SET_LOCATION_ID = 'SET_LOCATION_ID';
export const SET_LOCATION_INFO = 'SET_LOCATION_INFO';
export const FETCH_ORI_LIST_REQUEST = 'FETCH_ORI_LIST_REQUEST';
export const FETCH_ORI_LIST_SUCCESS = 'FETCH_ORI_LIST_SUCCESS';
export const FETCH_ORI_LIST_FAILURE = 'FETCH_ORI_LIST_FAILURE';
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const SET_ORDER_TYPE_DATA = 'SET_ORDER_TYPE_DATA';
export const SET_FORM_INITIAL_VALUES = 'SET_FORM_INITIAL_VALUES';
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO';
export const SET_DOWNLOAD_ORDER_PDF = 'SET_DOWNLOAD_ORDER_PDF';
export const SET_APPLICANT_INFO = 'SET_APPLICANT_INFO';
export const SET_CA_ORI = 'SET_CA_ORI';
export const SET_FB_CA = 'SET_FB_CA';
export const SET_CA_ORI_AGENCIES = 'SET_CA_ORI_AGENCIES';
export const SET_IS_ORI_COMPLETE = 'SET_IS_ORI_COMPLETE';
export const SET_IS_SHOW_MORE_CLICKED = 'SET_IS_SHOW_MORE_CLICKED';
export const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING';
export const SET_ORDER_TYPES = 'SET_ORDER_TYPES';
export const SET_SERVICE_LOGO = 'SET_SERVICE_LOGO';
export const SET_FDLE_REDIRECTION_INFO = 'SET_FDLE_REDIRECTION_INFO';

export function updateFormValues (values) {
  return {
    type: UPDATE_FORM_VALUES,
    values
  }
}

export function updateFormErrors (errors) {
  return {
    type: UPDATE_FORM_ERRORS,
    errors
  }
}

export function updateCurrentStep (value) {
  return {
    type: UPDATE_CURRENT_STEP,
    value
  }
}

export function setApiData (data) {
  return {
    type: SET_API_DATA,
    data
  }
}

export function setOrderTypes (data) {
  return {
    type: SET_ORDER_TYPES,
    data
  }
}

export function setServiceLogo (data) {
  return {
    type: SET_SERVICE_LOGO,
    data
  }
}

export function saveSearchQuery (query) {
  return {
    type: SAVE_SEARCH_QUERY,
    query
  }
}

export function setLocationsData (data) {
  return {
    type: UPDATE_LOCATIONS,
    data
  }
}

export function getLocationInfoById (id) {
  return {
    type: GET_LOCATION_BY_ID,
    id
  }
}

export function setLocationId (id) {
  return {
    type: SET_LOCATION_ID,
    id
  }
}

export function setLocationInfo (info) {
  return {
    type: SET_LOCATION_INFO,
    info
  }
}
export function setAccountInfo(info){
  return {
    type:SET_ACCOUNT_INFO,
    info
  }
}

export function setBookingSlots (slots) {
  return {
    type: SET_BOOKING_SLOTS,
    slots
  }
}

export function calculateFees (ori) {
  return {
    type: CALCULATE_ORI_FEES,
    ori
  }
}

export function setOriFees (fees) {
  return {
    type: SET_ORI_FEES,
    fees
  }
}



export function setOriValidity (isValid) {
  return {
    type: SET_ORI_VALID,
    isValid
  }
}

export function setOrderDetails (orderDetails) {
  return {
    type: SET_ORDER_DETAILS,
    orderDetails
  }
}

export function setBookingInfo (bookingInfo) {
  return {
    type: SET_BOOKING_CONFIRMATION_NUMBER,
    bookingInfo
  }
}

export function setFormErrors (errors) {
  return {
    type: SET_FORM_ERRORS,
    errors
  }
}


export const fetchOriListRequest = _ => {
  return {
    type: FETCH_ORI_LIST_REQUEST
  }
}

export const fetchOriListSuccess = data => {
  return {
    type: FETCH_ORI_LIST_SUCCESS,
    data
  }
}

export const fetchOriListFailure = error => {
  return {
    type: FETCH_ORI_LIST_FAILURE,
    error
  }
}

export const autoCompleteOri = ori => {
  return async dispatch => {
    dispatch(fetchOriListRequest());

    try {
      return axios.get(`${API_BASE}/ori-fees?type=1&searchByOri=${ori}`)
        .then(response => {
          if(response.status === httpStatusCode.OK) {
            
            const Data =response?.data?.Data.map(i => i?.OriNumber);
            dispatch(fetchOriListSuccess(Data))
          } else {
            throw Error('NETWOOOOORK ERROR')
          }
        })
    } catch (error) {
      dispatch(fetchOriListFailure(error.message))
    }
  }
}

export function setOrderTypeId(orderType) {
  return {
    type: SET_ORDER_TYPE,
    orderType
  }
}

export function setFormInitialValues(values) {
  return {
    type: SET_FORM_INITIAL_VALUES,
    values
  }
}
export function setApplicantInfo(info){
  return {
    type: SET_APPLICANT_INFO,
    info
  }
}
export function setDownloadOrderPdf(values) {
  return {
    type: SET_DOWNLOAD_ORDER_PDF,
    values
  }
}

export function setCaOri(oris) {
  
  return {
    type: SET_CA_ORI,
    oris
  }
}

export function setFbCa(fb) {
  return {
    type: SET_FB_CA,
    fb
  }
}

export function setCaOriAgencies(agencies) {
  return {
    type: SET_CA_ORI_AGENCIES,
    agencies
  }
}


export function setIsOriComplete(value){
  return {
    type: SET_IS_ORI_COMPLETE,
    value
  }
}
export function setIsShowMoreClicked(value){
  return {
    type: SET_IS_SHOW_MORE_CLICKED,
    value
  }
}

export const autoCompleteOriCa = (ori, orderType) => {
  return async dispatch => {
      return useGetOri(ori, orderType)
        .then(response => {
          if(response.status === httpStatusCode.OK) {
            const Data =response?.data?.Data?.map(i => i?.OriNumber);
            dispatch(setCaOri(Data))
          } else {
            notification.error({
              message: 'Error',
              description: generalErrorMessage(),
              placement: 'topRight',
              duration: 10
            })
          }
        }).catch(() => {
          notification.error({
            message: 'Error',
            description: generalErrorMessage(),
            placement: 'topRight',
            duration: 10
          })
        })
        }
}

export const clearFormErrors = () => {
  return {
    type: SET_FORM_ERRORS,
    errors: {},
  }
}

export function setIsSubmitting(value) {
  return {
    type: SET_IS_SUBMITTING,
    value
  }
}

export function setFdleRedirectionInfo(value){
  return {
    type: SET_FDLE_REDIRECTION_INFO,
    value
  }
}