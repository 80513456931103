import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { UPDATE_CURRENT_STEP } from "../store/actions/form";
import { scrollToTop } from "../utils/scroll";
import { actStatementMesssage } from "../utils/confirmationMessage";
function ActStatement({  updateCurrentStep , currentStep}) {

  const onSubmit = (data) => {
    updateCurrentStep(++currentStep);
    scrollToTop("box-wrapper");
  };
  const onBack = (_) => {
    updateCurrentStep(--currentStep);
    scrollToTop("box-wrapper");
  };

  return (
    <Row>
      <Col>
        <form id="act-statement-form" onSubmit={onSubmit}>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">Privacy Act Statement </h3>
            </Col>
          </Row>

          <Row>
            <p>
             {actStatementMesssage()}
            </p>
          </Row>
          <Row className="mt-4 mb-3">
            <Col md="12">
              <input
                type="button"
                className="btn btn-outline-primary"
                value="Back"
                onClick={onBack}
              />
              <input type="submit" className="btn btn-primary" value="Next" />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
}

function mapStateToProps({ values, apiData , currentStep}) {
  return {
    initialValues: values,
    apiData,
    currentStep
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentStep: (value) =>
      dispatch({ type: UPDATE_CURRENT_STEP, value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActStatement);
