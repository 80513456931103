import { formatDateTime, getCurrentYear } from "./helper";
import {  fbiGovLink, fpaLink } from "./links";

export const instructionsMessage = () => "You will be sent an email with a QR code and further instructions, simply present the QR code to your selected location and they will capture your fingerprints and transmit them electronically.";
export const locationTitle = () => "Selected Location:";
export const hoursOfOperationTitle = () => "Hours of Operation:";
export const appointmentConfirmedMessage = (bookingDay, bookingTime) => `Your appointment confirmed at ${bookingDay}, ${bookingTime}`;
export const confirmationNumberMessage = (bookingNumber) => `Confirmation Number: ${bookingNumber}`;
export const scheduleAppointmentMessage = () => "Now it's time to schedule an appointment at selected location.";
export const paperEducationPolicyMessage = () => `Biometric Information Security Policy`
export const paperEducationLabel_1 =()=>`I confirm that I have reviewed and signed the `
export const paperEducationLabel_2 =()=>`through Paper.`
export const additionalCopiesFeeLabel = (fee = 0) => `$ ${fee} for each copy` 
export const underRetrialOrderMessage = () => `We are in the process of creating your order, we will send an order confirmation to your email shortly.`;
export const confirmationOrderPdfName = (firstName, lastName) => `${firstName}-${lastName}_${formatDateTime(new Date())}`;
export const downloadOrderPdfSuccessMessage = () => `Your order confirmation pdf is downloaded successfully`
export const fingerprintCopiesMessage = () => ` Includes one fingerprint card that will be mailed to you via USPS `
export const dontHaveSSN = () => ` I don't have a Social Security Number `
export const actStatementMesssage = () => `The FBI's acquisition, retention, and sharing of information submitted on this form is 
                                            generally authorized under 28 USC 534 and 28 CFR 16.30-16.34. 
                                            The purpose for requesting this information from you is to provide the FBI 
                                            with a minimum of identifying data to permit an accurate and timely search of criminal history identification records.
                                            Providing this information (including your Social Security Account Number) is voluntary; however,
                                            failure to provide the information may affect the completion of your request. 
                                            The information reported on this form may be disclosed pursuant to your consent, 
                                            and may also be disclosed by the FBI without your consent pursuant to the Privacy Act of 1974 and all applicable routine uses.`;
export const disclaimerMessages = (paragraph) => {
    switch (paragraph) {
        case 'paragraphOne':
            return `I declare under penalty of perjury under the laws of the United States of America 
                    that the enclosed fingerprint impression is that of the person completing this form,
                    and that all of the information submitted on the`

        case 'boldText':
            return "FBI Criminal Report Request"

        case 'paragraphTwo':
            return "form is true and correct."

        case 'paragraphThree':
            return ` Applicant acknowledges that information sent through electronic
                    mail, United States Mail, and courier service is not secure.
                    Applicant further acknowledges that Certifix may not alter any of the
                    information contained in the FBI report, even if there are obvious
                    errors. Applicant waives, disclaims, and holds Certifix harmless from
                    and against any inadvertent disclosure of Applicant's FBI report
                    and from any inaccuracies contained within said FBI report,
                    provided that nothing herein affects any intentional, wrongful
                    disclosure of Applicant's FBI report or the contents thereof.`

        case 'paragraphFour':
            return `If you have a criminal history record, you have the opportunity to
                    complete or challenge the accuracy of the information in the
                    record. You should be afforded a reasonable amount of time to
                    correct or complete the record. The procedures for obtaining a
                    change, correction, or updating of your FBI criminal history
                    record are set forth in Title 28, Code of Federal Regulations
                    (CFR), Section 16.30 through 16.34. Information on how to review
                    and challenge your FBI criminal history record can be found at`

        case 'fbiLink':
            return fbiGovLink()

        case 'paragraphFive':
            return `under "Services" and then "Identity History Summary Checks" or by calling (304)
                    625-5590. For more information, about the noncriminal justice applicant’s privacy rights, please`

        case 'fpaLink':
            return fpaLink()
        
        case 'fpaLinkText':
            return `click here`

        default:              
            break;
    }

}

export const disclaimerAcknowledgement = () => `By checking this box you are electronically signing this document and\n
                                                indicating your agreement with the terms and conditions of the background investigation.`
export const isUSCitizenLabel = () => `Are You U.S Citizen Or Legal Permanent Resident?`
export const serviceSelectionParagraphs = (paragraph) => {
    switch (paragraph) {
        case 'paragraphOne':
            return `We will scan your fingerprints and`
        case 'paragraphTwo':
            return `electronically transmit them to`
        case 'paragraphThree':
            return `FDLE or AHCA.`
        default:
            return ``
    }
} 
export const mailCodePlaceHolder = () => `e.g 12345`;
export const certifixPhone = () =>'(714) 790-1929';
export const supportEmail = () => 'support@certifixlivescan.com';
export const homeBreadCrumbsMessage = () => 'Go to Certifix Live Scan.';
export const servicesBreadCrumbsMessage = () => 'Go to Certifix Live Services.';
export const rightsReservedMessage = () => `${getCurrentYear()} Certifix Inc. All Rights Reserved.`;
export const fdleRedirectionModalText = (paragraph) => {
    switch (paragraph) {
        case "paragraphOne":
            return "It appears you are using a "
        case "boldText":
            return "Florida-based ORI "
        case "paragraphTwo":
            return "number for a Live Scan submission while enrolling for a Fingerprint Card Service."
        default:
            return ""
    }
}
