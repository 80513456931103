export const BASE_URL = process.env.REACT_APP_API_URL;

const createUrl = (base, path, query) => `${base}${path}${query ? query : ''}`;

export const getLocations = (searchQuery) => [
  createUrl(BASE_URL, '/locations/actions/external-locations', `?searchTerm=${searchQuery}`),
  {
    method: 'GET'
  }
];

export const getLocation = (locationId) => [
  createUrl(BASE_URL, '/locations/actions/external-locations/', `${locationId}`),
  {
    method: 'GET'
  }
];

export const getBookingSlots = (locationId, endDateTime) => [
  createUrl(BASE_URL, '/locations/actions/external-locations/', `${locationId}/available-slots?endDateTime=${endDateTime}&maxResults=2000`),
  {
    method: 'GET'
  }
];

export const getApiStaticData = () => [
  createUrl(BASE_URL, '/lookup/get'),
  {
    method: 'GET'
  }
];

export const getFees = (getOriFees) => [
  createUrl(BASE_URL, `/ori-fees?type=${getOriFees.orderTypeId}&oriNumber=${getOriFees.ori}&additionalCopies=${getOriFees.additionalCopies}&authorizedApplicantType=${encodeURIComponent(getOriFees.authorizedApplicantType)}&levelOfServiceDoj=${getOriFees.levelOfServiceDoj}&levelOfServiceFbi=${getOriFees.levelOfServiceFbi}&authorizedAgency=${encodeURIComponent(getOriFees.authorizedAgency)}&billingNumber=${getOriFees.applicantBillingNumber}`),
  {
    method: 'GET'
  }
];


export const submitOrder = (data) => [
  createUrl(BASE_URL, '/order'),
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    
    method: 'POST',

    body: JSON.stringify(data)
  }
]

export const bookAppointment = (orderReferenceId, startTime) => [
  createUrl(BASE_URL, '/appointments'),
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ orderReferenceId, startTime })
  }
]

export const validateAccountNumber = (accountNumber) => [
  createUrl(BASE_URL, `/cbs-accounts/actions/validate`),
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ accountNumber })
  }
];

export const downloadOrderConfirmationPdf = (orderCode, email) => [
  createUrl(BASE_URL, `/order/actions/download-order-confirmation-pdf`),
  {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({orderCode, email})
  }
]

export const getOri = (ori, orderType) => [
  createUrl(BASE_URL,`/ori-fees?type=${orderType}&searchByOri=${ori}`),
  {
    method: 'GET'
  }
]
export const getCaOriAgencies = (ori) => [
  createUrl(BASE_URL,`/ori-fees/${ori}/agencies`),
  {
    method: 'GET'
  }
]

export const getOrderTypes = () => [
  createUrl(BASE_URL,`/order-types?withSettings=1`),
  {
    method: 'GET'
  }
]

export const getServiceLogo = (id) => [
  createUrl(BASE_URL,`/settings/${id}/actions/download-logo`),
  {
    method: 'GET'
  }
]
