import { Spinner } from 'react-bootstrap';

const Loader = () => {
    return (
        <>
            <div className="loader-wrapper">
                <Spinner animation="border" role="status" variant="dark">
                </Spinner>
            </div>
        </>
    )
}

export default Loader;