export const maxLength = (noOfCharacters) => `Max ${noOfCharacters} characters.`
export const minLength = (noOfCharacters) => `Min ${noOfCharacters} Characters`
export const minMaxRange = (min, max, unit) => `${min} ~ ${max} ${unit}`
export const charSpaceDashErrorMessage = () => `Letters, spaces and hyphens only allowed.`
export const alphaNumericErrorMessage = () => `Alphanumeric characters only allowed.`
export const alphaNumericSpecialCharactersErrorMessage = () => `Alphanumeric and - ' " ; : only allowed`
export const numericErrorMessage = () => `Numbers only allowed`
export const invalidNumberErrorMessage = () => `Invalid Number`
export const invalidEmailErrorMessage = () => `Invalid email address`
export const invalidPhoneErrorMessage = () => `Invalid phone number`
export const isUSCitienErrorMessage = () => 'We can only process requests for a US lawful permanent resident.'
export const locationErrorMessage = () => 'No geographic locations were found with that search term.'
export const charSpaceDashAmpersandErrorMessage = () => `Letters and - & only allowed`
export const additionalCopiesRangeErrorMessage = () => `Only 1 to 30 copies are allowed`
export const downloadOrderPdfErrorMessage = () => `PDF can't be downloaded at the moment`
export const printOrderPdfErrorMessage = () => `PDF can't be printed at the moment`
export const invalidExtension = () => `Invalid extension.`;
export const alphaNumericSpaceDashErrorMessage = () => `Only alphanumeric, spaces and hyphens only allowed.`;
export const alphaNumericAllSpecialCharactersErrorMessage = () => `Alphanumeric and special characters only allowed`
export const alphaNumericSpaceSlashErrorMessage = () => `Only spaces and slash are allowed`
export const numericSlashErrorMessage = () => `Allowed special character (/)`;
export const alphaNumericalDashParenthesesSpaceErrorMessage = () => `Alphanumeric, spaces and ,- ' () only allowed.`
export const onlyFiveDigitsOrFollowedByFourDigitsErrorMessage = () => `A valid zip code is 5 digits, you can add a hyphen and a 4-digit extension`;
export const onlyFiveDigitsErrorMessage = () => `Only 5 digits allowed.`;
export const numericalSpecialCharactersErrorMessage = () => `Numbers, spaces and special characters ( -,()#+ ) only allowed.`
export const alphaNumericSDashesHashesErrorMessage = () => `Alphanumeric and - , # only allowed`
export const caDriverLicenseErrorMessage = () => `Exact 8 char starts with a letter following with 7 digits.`
export const onlySixDigitsAndDisallowZerosErrorMessage = () => `6 characters numeric field, all zeros not allowed.`;
export const onlyNineDigitsErrorMessage = () => `Invalid Number 9 character numeric field`;
export const generalErrorMessage = () => `Something went wrong, Please Contact Certifix support team.`
export const levelOfServiceErrorMEssage = () => `Please select one level of services at least`
export const noAppointmentErrorMessage = () => `Your selected location does not offer appointment scheduling, simply walk in with your QR code during their business hours.`;
export const authApplicantTypeErrorMessage = () => `Required, depends on ORI value.`
export const invalidOriErrorMessage = () => `Invalid ORI.`
export const invalidErrorMessage = () => `Invalid.`;
export const requiredErrorMessage = () => `Required.`;
export const invalidZipCodeErrorMessage = () => `Invalid zip code`;
export const invalidOcaErrorMessage = () => `Invalid OCA or facility number`
export const orderAlreadyBookedMessage = () => `An appointment already booked for this order.`;
export const bookCanceledOrderMessage = () => `The Order is Canceled, Can't book an appointment for a canceled order.`;
export const bookAppointmentForbiddenMessage = () => `The caller is not allowed to book appointments.`;
export const appointmentNotAvailableMessage = () => `The requested appointment was not available.`;
export const invalidDateMessage = () => `Invalid Date, Please contact our support.`;
export const invalidCbsAccountMessage = () => `Invalid Account Number`;
export const selectValidOptionMessage = () => `Please select a valid option`;
export const selectLocationToProceedToNextStepMessage = () => `Select a location to proceed to next step.`;
