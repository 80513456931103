import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bookTimeSlot, getBookingSlots, downloadOrderPdf } from "../store/reducers";
import BookingCalendar from './bookingCalendar';
import { notification, Result, Table } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { instructionsMessage,locationTitle,hoursOfOperationTitle,appointmentConfirmedMessage,confirmationNumberMessage ,scheduleAppointmentMessage, confirmationOrderPdfName, downloadOrderPdfSuccessMessage} from "../utils/confirmationMessage";
import { downloadOrderPdfErrorMessage, printOrderPdfErrorMessage } from "../utils/errorMessage";
import UnderRetrialOrder from "./UnderRetrialOrder";
import { firePurchaseEvent, prepareEcommerceObject } from "../utils/gtm";



const RegistrationCompleted = ({ ReferenceId, SterlingRegistrationCode, OrderLocation, bookingSlots, getBookingAvailability, bookTimeSlot, locationId, locationInfo, applicantInfo, downloadOrderPdf, isUnderRetrial, orderData ,TotalAmount}) => {
  const lastDayOfMonth = useMemo(() => {
    const date = new Date();
    let year = date.getFullYear();
    const month = date.getMonth() + 1;
    return { year, month }
  }, [])
  let endMonth = `${lastDayOfMonth.month === 11 ? '1' : lastDayOfMonth.month === 12 ? '2' : lastDayOfMonth.month + 2}`;
  endMonth = +endMonth < 10 ? `0${endMonth}` : endMonth;
  const endDay = '01';
  const endYear = `${(lastDayOfMonth.month === 11 || lastDayOfMonth.month === 12) ? lastDayOfMonth.year + 1 : lastDayOfMonth.year}`;
  const endDate = `${endYear}-${endMonth}-${endDay}T07:59:59Z`;
  const [ checkCompleted, setCompleted ] = useState("false");
  const [ isBookingVisible, setBookingVisible] = useState(false);
  const [ displayBookingNumber, setDisplayBookingNumber] = useState(false);
  const [ bookingNumber, setBookingNumber] = useState(false);
  const [ bookingTime, setBookingTime] = useState();
  const [ bookingDay, setBookingDay] = useState();

  setTimeout(()=> setCompleted("true"), 1500);

  useEffect(()=> {
   !isUnderRetrial && getBookingAvailability(locationId, endDate)
  }, [endDate, getBookingAvailability, locationId, isUnderRetrial])


  useEffect(() => {
    if (!isUnderRetrial && SterlingRegistrationCode) {
      firePurchaseEvent(prepareEcommerceObject(orderData, SterlingRegistrationCode, TotalAmount , OrderLocation))
    }
    // eslint-disable-next-line 
  }, [SterlingRegistrationCode])


  const feedBack = ({type, message, bookingNum}) => {
      type === 'error'  && notification.error({
      message: 'Error',
      description: message || 'Something went wrong, Try again later.',
      placement: 'topRight',
      duration: null
    })

    if ( type === 'success' ) {
      
      setBookingNumber(bookingNum)
      setDisplayBookingNumber(true)
      setBookingVisible(false)
    }
  }
  
  const onBookingConfirm = async (referenceId = ReferenceId, startTime , timzezoneOffset) => {
    const bookingTimeFormatted = (moment(startTime)).utcOffset(timzezoneOffset).format('h:mm a, zZ');

    setBookingTime(bookingTimeFormatted);
    await bookTimeSlot(referenceId, startTime, feedBack);
  }
  const downloadPdfHandler = async() => {
    const fileName = confirmationOrderPdfName(applicantInfo.firstName, applicantInfo.lastName);
   try{ 
    const response = await downloadOrderPdf(SterlingRegistrationCode, applicantInfo.email, fileName, true);
    if (!response || response[1]) {
      notification.error(
        {
          message: "Error",
          description: downloadOrderPdfErrorMessage(),
          placement: 'topRight',
        }
      )
    } else {
        notification.success({
          message: "Success",
          description: downloadOrderPdfSuccessMessage(),
          placement: 'topRight'
        })
    }

  } catch(error){
    notification.error(
      {
        message: "Error",
        description: downloadOrderPdfErrorMessage(),
        placement: 'topRight',
      }
    )
  }
   
  }
  const printPdfHandler =  async() => {
    const fileName = confirmationOrderPdfName(applicantInfo.firstName, applicantInfo.lastName);
    try{
    const response = await downloadOrderPdf(SterlingRegistrationCode, applicantInfo.email, fileName, false);
    if (!response || response[1]) {
      notification.error({
          message: "Error",
          description: printOrderPdfErrorMessage(),
          placement: 'topRight',
        }
      )
    } 

    } catch(error){
      notification.error({
        message: "Error",
        description: printOrderPdfErrorMessage(),
        placement: 'topRight',
      }
    )
    }
  

  }
  

  const columns = [{
    title: 'Distance',
    dataIndex: 'distance',
    responsive: ['md']
  }, {
    title: 'Name',
    dataIndex: 'name',
  }, {
    title: 'Address',
    dataIndex: 'address',
  }, {
    title: 'City',
    dataIndex: 'city',
  }, {
    title: 'State',
    dataIndex: 'state',
  }, {
    title: 'Zip',
    dataIndex: 'zip',
  }, {
    title: 'Phone',
    dataIndex: 'phone',
  }, {
    title: 'Hours',
    dataIndex: 'hours',
  }];
  return (
    <>
  { isUnderRetrial && <UnderRetrialOrder></UnderRetrialOrder> }
    {!isUnderRetrial && <Row>
      <Col xs={{ span: 12}} md={{ span: 10, offset: 1 }} className="registrion-success text-center p-md-5">
          <Row>
            <Col>
              <div className={`circle-loader ${checkCompleted === "true" ? "load-complete" : ""}`}>
                <div className={`checkmark draw ${checkCompleted === "true" ? "checkMark-show" : ""}`}></div>
              </div>
              <div className="order-confirmation-pdf" onClick={downloadPdfHandler}>
                <div className="pdf-icon icon"  ></div>
                <span id="pdf" >PDF</span>
              </div>
              <div className="order-confirmation-pdf" onClick={printPdfHandler}>
                <div className="print-icon icon"  ></div>
                <span id="printer" >Print </span>
              </div>
            </Col>
          </Row>
        <h1 className="mt-3 mb-3">
          <span className="prime-color" style={{fontSize: '2rem'}}>Order Registration Code </span><br />
          
        </h1>
        
        <h3 className="mb-5" style={{fontSize: "28pt"}}>{SterlingRegistrationCode}</h3>
        <div style={{ textAlign: "initial" }}> 
          <p>{instructionsMessage()}</p>
          {
            OrderLocation && <div>
              <br />
              <h5 style={{ fontWeight: "bold" }}>{locationTitle()}</h5>
              <p>{OrderLocation.Name}</p>
              <p>{OrderLocation.Address1}</p>
              <p>{OrderLocation.City} {OrderLocation.State} {OrderLocation.ZipCode}</p>
              <p>{OrderLocation.PhoneNumber}</p>
            </div>
          }
          { OrderLocation?.BusinessHours && <>
            <br />
            <p style={{ fontWeight: "bold" }}>{hoursOfOperationTitle()}</p>
            {
              OrderLocation.BusinessHours.map((element, index) => <p key={index}>{element}</p>)
             }
          </>}
          
         </div>


        { displayBookingNumber && bookingTime && bookingNumber
          ? 
            <Result
              style={{paddingTop: '0'}}
              icon={<FieldTimeOutlined style={{color: '#08aae8', marginBottom: '-10px', fontSize: '48px'}} />}
              status="success"
              title="Booking Confirmed!"
              subTitle={(
                <>
                <p style={{fontSize: "1.1rem"}}>
                    {appointmentConfirmedMessage(bookingDay, bookingTime)}
                    {<br />}{confirmationNumberMessage(bookingNumber)}
                </p>
                <Table
                  className="mt-4 mb-1"
                  columns={columns}
                  dataSource={locationInfo}
                  pagination={false}
                />
                </>
              )}
            />
          :
            isBookingVisible ?
              <>
                <p>{scheduleAppointmentMessage()}</p>
                <div className="booking-wrapper">
                  <h2>Select a Date & Time</h2>
                  <Row>
                    <BookingCalendar 
                      slots={bookingSlots}
                      onBookingConfirm={(selectedTime , timzezoneOffset)=> onBookingConfirm(ReferenceId, selectedTime,timzezoneOffset)}
                      onDaySelectHandler={setBookingDay}
                    />
                  </Row>
                  
                </div>
              </>
            :
              bookingSlots && Object.keys(bookingSlots).length
              ? <div className="text-center mt-3 mb-3">
                  <Button 
                    style={{padding: '0 2rem', display: 'inline-block', float: 'none'}}
                    onClick={setBookingVisible}
                  >
                    Book Appointment
                  </Button>
                </div>  
              : null
              
        }
        
      </Col>
    </Row>
    }
    </>
  )
}

const mapStateToProps = ({ orderDetails: { ReferenceId, SterlingRegistrationCode, OrderLocation ,TotalAmount}, bookingSlots, orderDetails: { locationId, locationInfo }, applicantInfo, isUnderRetrial,
  values }) => {
  return {
    bookingSlots,
    locationId,
    locationInfo,
    applicantInfo,
    isUnderRetrial,
    OrderLocation,
    ReferenceId,
    SterlingRegistrationCode,
    TotalAmount,
    orderData: values,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getBookingAvailability: (locationId, endDateTime) => dispatch(getBookingSlots(locationId, endDateTime)),
    bookTimeSlot: (referenceId, startTime, cb) => dispatch(bookTimeSlot(referenceId, startTime, cb)),
    downloadOrderPdf: (orderCode, email, fileName, downloadingPdf) => dispatch(downloadOrderPdf(orderCode, email, fileName, downloadingPdf))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationCompleted);