import {corporateBillingLink, faqLink, getFingerprintLink, howItWorksLink, liveScanSystemLink, mobileServiceLink, certifixlivescan, joinOurNetworkLink, liveScanClassesLink, supportLink, aboutUsLink, contactUsLink } from "./links";
import {aboutUsTitle, contactUsTitle, corpeorateBillingTitle, faqTitle, getFingerprintTitle, homeTitle, howItWorksTitle, joinOurNetworkTitle, liveScanClassesTitle, liveScanSystemTitle, mobileServiceTitle, supportTitle } from './titles';

export const menuItems = [
  {link: certifixlivescan(), title: homeTitle()},
  {link: howItWorksLink(), title: howItWorksTitle()},
  {link: mobileServiceLink(), title: mobileServiceTitle()},
  {link: liveScanClassesLink(), title: liveScanClassesTitle()},
  {link: corporateBillingLink(), title: corpeorateBillingTitle()},
  {link: getFingerprintLink(), title: getFingerprintTitle()},
  {link: joinOurNetworkLink(), title: joinOurNetworkTitle()},
  {link: liveScanSystemLink(), title: liveScanSystemTitle()},
  {link: supportLink(), title: supportTitle()},
  {link: aboutUsLink(), title: aboutUsTitle()},
  {link: faqLink(), title: faqTitle()},
  {link: contactUsLink(), title: contactUsTitle()},
];