import React, { useState, useEffect }from 'react'
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { dontHaveSSN } from "../utils/confirmationMessage";
import { useFormContext } from "react-hook-form";
import TextField from "./form-fields/TextField";
import { patternRgx } from '../utils/formValidator';

const RequiredSSN = ({ globalErrors }) => {
  const { control, watch, setValue, clearErrors } = useFormContext();
  const isRequiredSSN = watch('isRequiredSSN');
    const ssn = watch('socialSecurityNumber');
    const [forceUncheckRequiredSSn, setForceUncheckRequiredSSn] = useState(globalErrors['socialSecurityNumber'] ? true : false)
  
    useEffect(() => {
  
      if (!isRequiredSSN && !forceUncheckRequiredSSn) {
        if (ssn) {
          setValue('socialSecurityNumber', '')
        }
        clearErrors('socialSecurityNumber')
        }
        // eslint-disable-next-line
    }, [isRequiredSSN]) 
  
    useEffect(() => {
      if (forceUncheckRequiredSSn) {
        setValue('isRequiredSSN', true)
        setForceUncheckRequiredSSn(false)
      }
      // eslint-disable-next-line
    }, [forceUncheckRequiredSSn]) 

  return (
    <>
    <Col md="6">
                <TextField
                    control={control}
                    name="socialSecurityNumber"
                    type="text"
                    rules={{
                        required: isRequiredSSN && 'Required',
                        pattern: patternRgx.onlyNineDigits,
                        validate: value => {
                            if ((globalErrors['socialSecurityNumber'] && ssn === '') || !value) { return true }
                            const regexFound = value.match(patternRgx.onlyNineDigits.value);
                            return !!value && !!regexFound;
                          }
                    }}
                    controlId="socialSecurityNumberStepTwo"
                    label="Social Security Number"
                    disabled={!isRequiredSSN}
                />
                    <div className="related-element">
                <input
                  checked={!isRequiredSSN}
                  onChange={e => setValue('isRequiredSSN', !e.target.checked)}
                  className="mr-2 mb-3"
                  name="isRequiredSSN"
                  type="checkbox" />
                <small> <b>{dontHaveSSN()}</b> </small>
              </div>
            </Col>
    </>
  );
};

function mapStateToProps({ errors }) {
    return {
      globalErrors: errors || {},
  
    };
  }
  
  
  export default connect(mapStateToProps)(RequiredSSN)