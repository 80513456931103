import React from 'react'
import { useFormContext } from 'react-hook-form';
import SelectField from './form-fields/SelectField';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from './form-fields/TextField';
import { orderType } from '../utils/orderType';
import { invalidZipCodeErrorMessage, maxLength } from '../utils/errorMessage';
import { patternRgx } from '../utils/formValidator';
import Option from './form-fields/Option';

const AddressInfo = ({ apiData }) => {
    const form = useFormContext();
    const orderTypeId = form.getValues('orderTypeId')

    return (
        <>
            {orderTypeId === orderType.CA &&
                <>
                    <Row>
                        <Col md={4}>
                            <TextField
                                control={form.control}
                                name="streetNumber"
                                type="text"
                                rules={{
                                    required: "required",
                                    maxLength: {
                                        value: 10,
                                        message: maxLength(10),
                                    },
                                    pattern: patternRgx.numeric
                                }}
                                controlId="StreetNumberSubmissionStep"
                                label="Street Number"
                            />
                        </Col>
                        <Col md={3}>
                            <SelectField
                                control={form.control}
                                name="streetDirection"
                                controlId="streetDirectionSubmissionStep"
                                label="Street Direction"

                            >
                                <option value="" > </option>
                                <Option data={apiData.streetDirection} />
                            </SelectField>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <TextField
                                control={form.control}
                                name="streetName"
                                type="text"
                                rules={{
                                    required: "required",
                                    maxLength: {
                                        value: 20,
                                        message: maxLength(20),
                                    },
                                    pattern: patternRgx.alphaNumericalDashParenthesesSpace
                                }}
                                controlId="StreetNameSubmissionStep"
                                label="Street Name" />
                        </Col>
                        <Col md={3}>
                            <TextField
                                control={form.control}
                                name="apartmentNumber"
                                type="text"
                                rules={{
                                    maxLength: {
                                        value: 5,
                                        message: maxLength(5),
                                    },
                                    pattern: patternRgx.alphaNumericalDashesHashes
                                }}
                                controlId="ApartmentNumberSubmissionStep"
                                label="Apartment Number"
                            />
                        </Col>
                    </Row>
                </>
            }

            <Row>
                <Col md={6}>
                    <TextField
                        control={form.control}
                        name="city"
                        type="text"
                        rules={{
                            required: 'Required',
                            maxLength: {
                                value: 20,
                                message: maxLength(20)
                            },
                            pattern: patternRgx.alphaNumericalSpaceDash
                        }}
                        controlId="cityStepThree"
                        label="City"
                    />
                </Col>
                <Col md="6">
                    <SelectField
                        control={form.control}
                        name="state"
                        rules={{ required: 'Required' }}
                        controlId="stateStepThree"
                        label="State"
                    >
                        <option value="" disabled> -- Select State --</option>
                        <Option data={apiData.usStates} />
                    </SelectField>
                </Col>

                <Col md="6">
                    <TextField
                        control={form.control}
                        name="zip"
                        type="text"
                        rules={{
                            required: 'Required',
                            pattern: {
                                value: patternRgx.onlyFiveDigits.value,
                                message: `${invalidZipCodeErrorMessage()}, ${patternRgx.onlyFiveDigits.message}`
                            }
                        }}
                        controlId="zipStepThree"
                        label="Zip Code"
                    />
                </Col>
            </Row>
        </>

    )
}

function mapStateToProps({ apiData, initialValues }) {
    return {
        apiData,
        initialValues,
    }

}

export default connect(mapStateToProps)(AddressInfo)
