import { Container } from 'react-bootstrap';
import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import BreadCrumbs from './components/BreadCrumbs';
import Routes from './Route';
import { initializeGtm } from './utils/gtm';

function App() {
  initializeGtm();
  return (
    <div className="App">
      <Header></Header>
      <BreadCrumbs></BreadCrumbs>
      <Container as="main">
         <Routes/>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default (App);