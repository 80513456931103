import useApiResult from './useApiResult';
import { getCaOriAgencies } from './apiRequests';


const useGetCaOriAgencies = async (ori) => {
  const request = getCaOriAgencies(ori);
  const response = await useApiResult(request);
  // If Response Has Error, return it as is
  if (!response || response[1] || response[0].Data.length === 0) {
    return response
  }
  // // If Respones with Data 

  if (response[0]) {
    const { Data, Status } = response[0];
    return { Data, Status };
  }

  return response;
}

export default useGetCaOriAgencies;
