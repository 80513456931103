import {  Col, Row } from "react-bootstrap";
import { underRetrialOrderMessage } from "../utils/confirmationMessage";

const UnderRetrialOrder = () => {
    return (
        <>
            <Row>
                <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }} className="registrion-success text-center p-md-5">

                    <div className={`circle-loader load-complete`}>
                        <div className={`checkmark draw checkMark-show`}></div>
                    </div>
                    <h2 className="mt-3 mb-3">
                        <span className="prime-color" style={{ fontSize: '2rem' }}>{underRetrialOrderMessage()}</span><br />

                    </h2>
                </Col>
            </Row>
        </>
    )
}

export default UnderRetrialOrder;