import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Col } from 'react-bootstrap';
import { FDLEReasonDescription, fdleReason } from '../utils/reason';
import SelectField from './form-fields/SelectField';
import TextField from './form-fields/TextField';
import { maxLength, minLength } from "../utils/errorMessage";
import { patternRgx } from "../utils/formValidator";
import { orderType } from '../utils/orderType';

const Reason = () => {

  const { control, getValues, trigger, clearErrors, setValue } = useFormContext();
  const orderTypeId = getValues('orderTypeId');

  const fdleReasons = Object.keys(FDLEReasonDescription).map((key) => (
    <option key={key} value={key}>
      {FDLEReasonDescription[key]}
    </option>));


  return (
    <>
      {(orderTypeId === orderType.FDLE) &&
        <Col md="6">
          <SelectField
            control={control}
            name="reason"
            rules={{ required: "Required" }}
            controlId="reasonStepTwo"
            label="Reason"
            onChangeCallBack={() => {
              orderTypeId === orderType.FDLE ? trigger('reasonDetails') :
              clearErrors('reasonDetails')
              setValue("reasonDetails", "")

            }}
          >
            <option value="" disabled>
              {" "}
              -- Reason Details --
            </option>
            {fdleReasons}
          </SelectField>
        </Col>}

      <Col md="6">
        {orderTypeId === orderType.PRINTTOCARD ?
          <TextField
            control={control}
            name="reasonDetails"
            type="text"
            rules={{
              minLength: {
                value: 1,
                message: minLength(1),
              },
              maxLength: {
                value: 20,
                message: maxLength(20),
              },
              pattern: patternRgx.alphaNumericSpecialCharacters,
            }}
            controlId="reasonDetailsStepTwo"
            label="Reason Fingerprinted"

          />
            : orderTypeId === orderType.FDLE &&
            <TextField
              control={control}
              name="reasonDetails"
              type="text"
              rules={{
                maxLength: {
                  value: 255,
                  message: maxLength(255)
                },
                validate: {
                  required: (value) => {
                    const isReasonOther = +getValues("reason") === fdleReason.Other;
                    const hasValue = value.length;
                    return !(isReasonOther && !hasValue);
                  },
                },
              }}
              controlId="otherDetailsProfileInfoStep"
              label="Reason Details"
            />

        }
      </Col>



    </>
  )
}

export default Reason
