import ActStatement from '../components/ActStatement';
import Address from '../components/Address';
import Payment from '../components/Payment';
import Disclaimer from '../components/Disclaimer';
import Location from '../components/Location';
import ProfileInfo from '../components/ProfileInfo';
import SubmissionAndAgencyInfo from '../components/SubmissionAndAgencyInfo';
import TransactionInformation from '../components/TransactionInformation';

export const orderType = {
  FDLE: 1,
  PRINTTOCARD: 2,
  FBI : 3,
  CA: 4
};
export const headerSteps = new Map(
  [
   [orderType.FDLE , ["Locations", "Profile Info", "Address Details", "Payment"]] ,
   [orderType.PRINTTOCARD , ["Locations", "Profile Info", "Address Details", "Payment"]],
   [orderType.FBI , ["Locations","Privacy Act Statement","Profile Info","Address Details", "Disclaimer","Payment",]],
   [orderType.CA,  [ "Locations", "Submission & Contributing\nAgency Info", "Applicant Info", "Transaction Info & Employer", "Payment" ]],
  ]
)
export const stepOne = new Map([
  [orderType.FDLE, <Location />],
  [orderType.PRINTTOCARD, <Location />],
  [orderType.FBI, <Location />],
  [orderType.CA, <Location/>],
  
]);
export const stepTwo = new Map([
  [orderType.FDLE, <ProfileInfo />],
  [orderType.PRINTTOCARD, <ProfileInfo />],
  [orderType.FBI, <ActStatement />],
  [orderType.CA, <SubmissionAndAgencyInfo/>],
  
]);
export const stepThree = new Map([
  [orderType.FDLE, <Address />],
  [orderType.PRINTTOCARD, <Address />],
  [orderType.FBI, <ProfileInfo />],
  [orderType.CA, <ProfileInfo/>],
  
]);
export const stepFour = new Map([
  [orderType.FDLE, <Payment />],
  [orderType.PRINTTOCARD, <Payment />],
  [orderType.FBI, <Address />],
  [orderType.CA, <TransactionInformation/> ],
  
]);
export const stepFive = new Map([
  [orderType.FDLE, null],
  [orderType.PRINTTOCARD, null],
  [orderType.FBI, <Disclaimer />],
  [orderType.CA, <Payment/>],
  
]);
export const stepSix = new Map([
  [orderType.FDLE, null],
  [orderType.PRINTTOCARD, null],
  [orderType.FBI, <Payment />],
  [orderType.CA, null],
  
]);
export const orderTypeTitle = new Map([
  [orderType.FDLE, "Florida Live Scan Service"],
  [orderType.PRINTTOCARD, "Fingerprint Card Service"],
  [orderType.FBI, "FBI Criminal Report Request"],
  [orderType.CA, "California Fingerprint Enrollment"],

]);

export const orderTypeBreadcrumbsTitle = new Map([
  [orderType.FDLE, "Florida Fingerprint Service"],
  [orderType.PRINTTOCARD, "Finger print Service"],
  [orderType.FBI, "FBI Fingerprint Service"],
  [orderType.CA, "California Fingerprint Service"]
]);

export const orderTypeSuccessUrl = new Map([
  [orderType.FDLE, '/services/fdle/success'],
  [orderType.PRINTTOCARD, '/services/ink-card-fingerprinting/success'],
  [orderType.FBI, '/services/fbi/success'],
  [orderType.CA, '/services/ca/success'],
]);

export const orderTypeBrowserTitle = new Map([
  [orderType.FDLE, 'Florida Live Scan Submission - Certifix Live Scan'],
  [orderType.PRINTTOCARD, 'Fingerprint Card Service - Certifix Live Scan'],
  [orderType.FBI, 'FBI Background Check - Certifix Live Scan'],
  [orderType.CA, 'California Fingerprint Submission - Certifix Live Scan'],
]);

export const orderTypeGtmItem = new Map([
  [orderType.FDLE, 'FDLE Fingerprinting '],
  [orderType.PRINTTOCARD, 'Inkcard Fingerprinting'],
  [orderType.FBI, 'FBI Fingerprinting'],
  [orderType.CA, 'CA Fingerprinting'],
]);

export const stepsFields = new Map([
  [orderType.FDLE, [
    ['externalLocationId'],
    ['lastName', 'firstName', 'middleName', 'suffix',
      'emailAddress', 'dateOfBirth', 'placeOfBirth', 'socialSecurityNumber',
      'gender', 'race', 'heightType', 'height', 'heightInch',
      'weightType', 'weight', 'eyeColor', 'hairColor', 'phoneNumber',
      'extension', 'reason', 'reasonDetails', 'countryOfCitizenship',
      'ori', 'oca', 'aliasesLastName', 'aliasesFirstName', 'aliasesMiddleName', 'aliasesSuffix', 'reasonForFingerprint', 'isRequiredSSN'],
    ['address1', 'address2', 'country', 'city', 'state', 'zip'],
    ['cardHolderName', 'cardNumber', 'cvc', 'expirationMonth', 'expirationYear', 'accountNumber', 'paymentType', 'accountNumberCheck']
  ]

  ],
  [orderType.PRINTTOCARD, [
    ['externalLocationId'],
    ['lastName', 'firstName', 'middleName', 'reason', 'reasonDetails', 'dateOfBirth', 'gender', 'race', 'countryOfCitizenship',
      'heightType', 'height', 'heightInch', 'weightType', 'weight', 'eyeColor', 'hairColor', 'placeOfBirth', 'socialSecurityNumber',
      'emailAddress', 'phoneNumber'],
    ['address1', 'address2', 'country', 'city', 'state', 'zip'],
    ['cardHolderName', 'cardNumber', 'cvc', 'expirationMonth', 'expirationYear', 'accountNumber', 'paymentType', 'additionalCopies', 'accountNumberCheck']
  ]],
  [orderType.FBI, [
    ['externalLocationId'],
    [null],
    ['isUsCitizen', 'lastName', 'firstName', 'middleName',
      'emailAddress', 'reason', 'reasonDetails', 'dateOfBirth', 'countryOfCitizenship',
      'placeOfBirth', 'socialSecurityNumber',
      'gender', 'race', 'heightType', 'height', 'heightInch',
      'weightType', 'weight', 'eyeColor', 'hairColor', 'phoneNumber',
    ],
    ['address1', 'address2', 'country', 'city', 'state', 'zip'],
    ['isFbiAgreement'],
    ['cardHolderName', 'cardNumber', 'cvc', 'expirationMonth', 'expirationYear', 'accountNumber', 'paymentType', 'accountNumberCheck'],
  ]],
  [orderType.CA, [
    ['externalLocationId'],
    ['ori', 'authorizedApplicantType', 'typeOfLicense', 'authorizedAgency', 'mailCode', 'agencyStreetNumber', 'agencyStreetDirection',
      'agencyStreetName', 'agencyCity', 'agencyState', 'agencyZipCode', 'agencyContactName', 'agencyTelephoneNumber'],
    ['lastName', 'firstName', 'middleName', 'dateOfBirth', 'gender', 'heightType', 'height', 'heightInch', 'weightType', 'weight',
      'eyeColor', 'hairColor', 'emailAddress', 'socialSecurityNumber', 'driverLicense', 'city', 'state', 'zip', 'streetNumber', 'streetDirection',
      'streetName', 'apartmentNumber', 'isRequiredSSN'],
    ['oca', 'levelOfServiceDoj', 'levelOfServiceFbi', 'billingNumber'],
    ['cardHolderName', 'cardNumber', 'cvc', 'expirationMonth', 'expirationYear', 'accountNumber', 'paymentType', 'accountNumberCheck']
  ]],
])