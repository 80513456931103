import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { UPDATE_CURRENT_STEP,UPDATE_FORM_VALUES} from "../store/actions/form";
import { scrollToTop } from "../utils/scroll";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { disclaimerMessages, disclaimerAcknowledgement } from "../utils/confirmationMessage";
import { fbiGovLink, fpaLink } from "../utils/links";
function Disclaimer({
  initialValues,
  currentStep,
  updateCurrentStep,
  updateStepValues
 
}) {

  const {
    getValues,
    handleSubmit,
  } = useForm({ defaultValues: initialValues, mode: "all" });

  const [isChecked, setIsChecked] = useState(false);

  const onSubmit = async (data) => {
    data.isFbiAgreement = isChecked
    updateStepValues(data);
    updateCurrentStep(++currentStep);
    scrollToTop("box-wrapper");
  };
  const onBack = (_) => {
    const values = getValues();
    updateStepValues(values);
    updateCurrentStep(--currentStep);
    scrollToTop("box-wrapper");
  };


  return (
    <Row>
      <Col>
        <form id="disclaimer-form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <h3 className="section-heading mt-4">
              Criminal Background Check Disclaimer
              </h3>
            </Col>
          </Row>

          <Row>
          <div className="mb-4">
              {disclaimerMessages('paragraphOne')} <b> {disclaimerMessages('boldText')} </b>
              {disclaimerMessages('paragraphTwo')}
          </div>
          <div className="mb-4">
            {disclaimerMessages('paragraphThree')}
          </div>
          <div className="mb-4">
            {disclaimerMessages('paragraphFour')} <a className="disclaimerLink" href={fbiGovLink()} target="_blank" rel="noopener noreferrer"> {disclaimerMessages('fbiLink')} </a>
            {disclaimerMessages('paragraphFive')} <a className="disclaimerLink" href={fpaLink()} target="_blank" rel="noopener noreferrer">{disclaimerMessages('fpaLinkText')}</a>.
          </div>
          <div className="agreement-check-label">
            <label className="disclaimerCheckText">
              <input
                className="form-check-input position-static"
                type="checkbox"
                name="isFbiAgreement"
                for="disclaimerIdStep"
                value={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
              /> {disclaimerAcknowledgement()}
            </label>
          </div>
          </Row>
          <Row className="mt-4 mb-3">
            <Col md="12">
              <input
                type="button"
                className="btn btn-outline-primary"
                value="Back"
                onClick={onBack}
              />
              <input
                type="submit"
                className="btn btn-primary"
                disabled={!isChecked}
                value="Next"
              />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
}

function mapStateToProps({ values, currentStep }) {
  return {
    initialValues: values,
    currentStep
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentStep: (value) =>
      dispatch({ type: UPDATE_CURRENT_STEP, value }),
      updateStepValues: (values) =>
      dispatch({ type: UPDATE_FORM_VALUES, values }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer);
