import React from 'react'
import { Row, Col } from "react-bootstrap";
import MuiDatePicker from "./form-fields/MuiDatePicker";
import RadioCheckboxField from "./form-fields/RadioCheckboxField";
import TextField from "./form-fields/TextField";
import { useFormContext } from "react-hook-form";
import { orderType } from '../utils/orderType';
import SelectField from "./form-fields/SelectField";
import moment from "moment";
import { connect } from "react-redux";
import { minMaxRange } from "../utils/errorMessage";
import { patternRgx } from "../utils/formValidator";
import Option from './form-fields/Option';
import RequiredSSN from './RequiredSSN';

const PassThroughApplicantInfo = ({ apiData, initialValues, globalErrors }) => {
    const { control, watch, setValue, clearErrors } = useFormContext();
    const isUSCitizenVal = watch("isUsCitizen");

    const maxDateOfBirth = moment()
        .subtract(12, "year")
        .subtract(1, "days")
        .format("MM/DD/YYYY");

    const dateOfBirthVal = watch("dateOfBirth");
    const heightTypeVal = watch("heightType");
    const weightTypeVal = watch("weightType");

    return (
        <>

            <Col md="6">
                <MuiDatePicker
                    control={control}
                    name="dateOfBirth"
                    rules={{ required: true }}
                    controlId="dateOfBirthStepTwo"
                    label="Date of Birth *"
                    maxDate={maxDateOfBirth}
                    fieldValue={dateOfBirthVal}
                    disabled={isUSCitizenVal === "No" ? true : false}
                />
            </Col>
            {initialValues.orderTypeId !== orderType.CA && <Col md="6">
                <SelectField
                    control={control}
                    rules={{ required: true }}
                    name="countryOfCitizenship"
                    controlId="countryOfCitizenshipStepTwo"
                    label="Country of Citizenship"
                    disabled={isUSCitizenVal === "No" ? true : false}

                >
                    <option value="" disabled>
                        {" "}
                        -- Select Country --
                    </option>
                    <Option data={apiData.citizenshipCountries} />

                </SelectField>
            </Col>}
            <Col md="6">
                <SelectField
                    control={control}
                    rules={{ required: true }}
                    name="gender"
                    controlId="genderStepTwo"
                    label="Sex"
                    disabled={isUSCitizenVal === "No" ? true : false}
                >
                    <option value="" disabled>
                        {" "}
                        -- Select Gender --
                    </option>
                    <Option data={apiData.gender} />
                </SelectField>
            </Col>

            {initialValues.orderTypeId !== orderType.CA && <Col md="6">
                <SelectField
                    control={control}
                    name="race"
                    rules={{ required: "Required" }}
                    controlId="raceStepTwo"
                    label="Race"
                    disabled={isUSCitizenVal === "No" ? true : false}

                >
                    <option value="" disabled>
                        {" "}
                        -- Select Race --
                    </option>
                    <Option data={apiData.race} isRace={true} />

                </SelectField>
            </Col>}
            <Col md="6">
                <RadioCheckboxField
                    control={control}
                    rules={{ required: true }}
                    name="heightType"
                    controlId="heightTypeStepTwo"
                    label="Height"
                    type="radio"
                    options={["Standard", "Metric"]}
                    onChangeCallBack={() => {
                        clearErrors(["height", "heightInch"]);
                        setValue("height", "");
                        setValue("heightInch", "");
                    }}
                    defaultValue={initialValues.heightType}
                    disabled={isUSCitizenVal === "No" ? true : false}

                />
            </Col>
            <Col md="6">
                <RadioCheckboxField
                    control={control}
                    rules={{ required: true }}
                    name="weightType"
                    controlId="weightTypeStepTwo"
                    label="Weight"
                    type="radio"
                    options={["Standard", "Metric"]}
                    onChangeCallBack={() => {
                        clearErrors("weight");
                        setValue("weight", "");
                    }}
                    defaultValue={initialValues.weightType}
                    disabled={isUSCitizenVal === "No" ? true : false}
                />
            </Col>

            <Col md="6">
                <Row>
                    <Col md="6">
                        <TextField
                            control={control}
                            name="height"
                            type="text"
                            controlId="heightStepTwo"
                            inputGroupAppend={
                                heightTypeVal === "Standard" ? "Ft" : "Cm"
                            }
                            rules={{
                                required: "Required",
                                min: {
                                    value: heightTypeVal === "Standard" ? 4 : 122,
                                    message:
                                        heightTypeVal === "Standard"
                                            ? minMaxRange(4, 7, "Ft")
                                            : minMaxRange(122, 241, "Cm"),
                                },
                                max: {
                                    value: heightTypeVal === "Standard" ? 7 : 241,
                                    message:
                                        heightTypeVal === "Standard"
                                            ? minMaxRange(4, 7, "Ft")
                                            : minMaxRange(122, 241, "Cm"),
                                },
                                pattern: patternRgx.numeric,
                            }}
                            disabled={isUSCitizenVal === "No" ? true : false}

                        />
                    </Col>
                    {heightTypeVal === "Standard" ? (
                        <Col md="6">
                            <TextField
                                control={control}
                                name="heightInch"
                                type="text"
                                controlId="heightInchStepTwo"
                                inputGroupAppend="In"
                                formatTwoDigits
                                rules={{
                                    max: {
                                        value: 11,
                                        message: minMaxRange(0, 11, "In"),
                                    },
                                    pattern: patternRgx.numeric,
                                }}
                                disabled={isUSCitizenVal === "No" ? true : false}

                            />
                        </Col>
                    ) : null}
                </Row>
            </Col>
            <Col md="6">
                <Row>
                    <Col md="6">
                        <TextField
                            control={control}
                            name="weight"
                            type="text"
                            controlId="weightStepTwo"
                            inputGroupAppend={
                                weightTypeVal === "Standard" ? "Lb" : "Kg"
                            }
                            rules={{
                                required: "Required",
                                min: {
                                    value: weightTypeVal === "Standard" ? 50 : 23,
                                    message:
                                        weightTypeVal === "Standard"
                                            ? minMaxRange(50, 499, "Lb")
                                            : minMaxRange(23, 226, "Kg"),
                                },
                                max: {
                                    value: weightTypeVal === "Standard" ? 499 : 226,
                                    message:
                                        weightTypeVal === "Standard"
                                            ? minMaxRange(50, 499, "Lb")
                                            : minMaxRange(22, 226, "Kg"),
                                },
                                pattern: patternRgx.numeric,
                            }}
                            disabled={isUSCitizenVal === "No" ? true : false}

                        />
                    </Col>
                </Row>
            </Col>
            <Col md="6">
                <SelectField
                    control={control}
                    name="hairColor"
                    rules={{ required: "Required" }}
                    controlId="hairColorStepTwo"
                    label="Hair Color"
                    disabled={isUSCitizenVal === "No" ? true : false}

                >
                    <option value="" disabled>
                        {" "}
                        -- Select Hair Color --
                    </option>
                    <Option data={apiData.hairColor} />
                </SelectField>
            </Col>
            <Col md="6">
                <SelectField
                    control={control}
                    name="eyeColor"
                    rules={{ required: "Required" }}
                    controlId="eyeColorStepTwo"
                    label="Eye Color"
                    disabled={isUSCitizenVal === "No" ? true : false}

                >
                    <option value="" disabled>
                        {" "}
                        -- Select Eye Color --
                    </option>
                    <Option data={apiData.eyeColor} />
                </SelectField>
            </Col>

            {initialValues.orderTypeId !== orderType.CA && <Col md="6">
                <SelectField
                    control={control}
                    name="placeOfBirth"
                    rules={{ required: "Required" }}
                    controlId="placeOfBirthStepTwo"
                    label="Place of Birth"
                    disabled={isUSCitizenVal === "No" ? true : false}

                >
                    <option value="" disabled>
                        {" "}
                        -- Select Place of Birth --
                    </option>
                    <Option data={apiData.countries} />
                </SelectField>
            </Col>}
            {initialValues.orderTypeId === orderType.CA && <RequiredSSN/> }
            {initialValues.orderTypeId === orderType.PRINTTOCARD && <Col md="6">
                <TextField
                    control={control}
                    name="socialSecurityNumber"
                    type="text"
                    rules={{
                        pattern: patternRgx.onlyNineDigits
                    }}
                    disabled={isUSCitizenVal === "No" ? true : false}
                    controlId="socialSecurityNumberStepTwo"
                    label="Social Security Number"
                />
            </Col> }
            {initialValues.orderTypeId === orderType.FBI && <Col md="6">
                    <TextField
                        control={control}
                        name="socialSecurityNumber"
                        type="text"
                        rules={{
                            required: "Required",
                            pattern: patternRgx.onlyFourDigits,
                        }}
                        controlId="lastFourDigitsOfSSNProfileInfoStep"
                        label="Last Four Digits of Social Security Number"
                        disabled={isUSCitizenVal === "No" ? true : false}

                    />
                </Col>}
            {
                initialValues.orderTypeId === orderType.CA && <>
                    <Col md="6">
                        <TextField
                            control={control}
                            name="driverLicense"
                            type="text"
                            rules={{
                                pattern: patternRgx.caDriverLicense,
                            }}
                            controlId="CaDriverLicenseNumberApplicantInfoStep"
                            label="CA Driver's License Number"

                        />
                    </Col>
                    <Col md="6">
                        <TextField
                            control={control}
                            name="emailAddress"
                            type="email"
                            disabled={isUSCitizenVal === "No" ? true : false}
                            rules={{
                                required: "Required",
                                pattern: patternRgx.email,
                            }}
                            controlId="emailAddressStepTwo"
                            label="Email"
                        />
                    </Col>

                </>
            }



        </>
    );
    }

function mapStateToProps({ values, apiData, errors }) {
    return {
        initialValues: values,
        apiData,
        globalErrors: errors || {},
    };
}

export default connect(mapStateToProps)(PassThroughApplicantInfo)
