import { downloadOrderConfirmationPdf } from "./apiRequests";
import useApiResult from "./useApiResult";
import { saveAs } from 'file-saver';
import printJS from "print-js";

const useDownloadOrderPdf = async (orderCode, email, fileName, downloadingPdf) => {
    const request = downloadOrderConfirmationPdf(orderCode, email);
    const response = await useApiResult(request);
    if (response[0] && !response[1] && downloadingPdf ) {
        saveAs(response[0], fileName);
    }else if (response[0] && !response[1] && !downloadingPdf){
        printJS(URL.createObjectURL(response[0]));
    }else{
        return response
    }

    return response;

}
export default useDownloadOrderPdf;