import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, InputGroup, Button, Spinner } from "react-bootstrap";
import "antd/dist/antd.css";
import { Table } from "antd";
import {
  updateCurrentStep,
  updateFormValues,
  saveSearchQuery,
  setLocationId,
  setLocationInfo,

} from "../store/actions/form";
import { searchLocations, getLocation } from "../store/reducers";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { scrollToTop } from "../utils/scroll";
import { locationErrorMessage, selectLocationToProceedToNextStepMessage } from "../utils/errorMessage";
import TextField from "./form-fields/TextField";

const Location = ({
  initialValues,
  updateStepNumber,
  saveStepValues,
  saveSearchQuery,
  locations,
  searchQuery,
  searchLocations,
  isLoading,
  getLocationById,
  globalErrors,
  setLocationID,
  setLocationInformation,
  currentStep
}) => {
  const search = useLocation().search;
  const locationId = new URLSearchParams(search).get("locationId");
  const stepFieldNames = ["externalLocationId"];

  const { handleSubmit, setValue, setError, getValues, control } = useForm({
    defaultValues: initialValues,
    mode: "all",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    getValues("externalLocationId") !== ""
      ? [getValues("externalLocationId")]
      : []
  );
  const [noLocationSelected, setNoLocationSelected] = useState(false);
  useEffect(() => {
    globalErrors &&
      Object.entries(globalErrors).forEach(
        ([key, value]) =>
          stepFieldNames.includes(key) &&
          setError(key, { type: value.type, message: value.message })
      );
  }, []); //eslint-disable-line
  
  useEffect(() => {
    if (locationId && !searchQuery) {
      getLocationById(locationId);
    }
  }, []); //eslint-disable-line
  useEffect(() => {
    !getValues("externalLocationId") && selectRow("")
    !searchQuery && scrollToTop("top-bar");

  }, [searchQuery]); //eslint-disable-line
  useEffect(() => {
    if (locationId && (locations.data.length ===1) && !getValues("externalLocationId") && !searchQuery) {
      let selectedRKeys = [];
      selectedRKeys.push(locations.data[0].key);
      setSelectedRowKeys(selectedRKeys);
      setValue("externalLocationId", locations.data[0].key);
      setLocationInformation(locations.data[0]);
    } else {
      let selectedRKeys = [];
      getValues('externalLocationId') && selectedRKeys.push(getValues('externalLocationId'));
      setSelectedRowKeys(selectedRKeys);
    }
  }, [locationId, locations.data]); //eslint-disable-line

  const columns = [
    {
      title: "Distance",
      dataIndex: "distance",
      responsive: ["md"],
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Zip",
      dataIndex: "zip",
      responsive: ["md"],
    },
  ];

  const onSubmit = (data) => {
    saveStepValues(data);
    updateStepNumber(++currentStep);
    const locationID = getValues("externalLocationId");
    setLocationID(locationID);
    scrollToTop("box-wrapper");
  };

  const onError = (errors) => {
    setNoLocationSelected(true);
  };

  const handleSearchSubmit = (e) => {
    setNoLocationSelected(false)
    e.preventDefault();
    setValue("externalLocationId", "");
    const query = e.target[0].value;
    saveSearchQuery(query);
    searchLocations(query);
  };

  const selectRow = (record) => {
    let selectedRKeys = [...selectedRowKeys];
    const recordKeyIndex = selectedRKeys.indexOf(record.key);
    if (recordKeyIndex >= 0) {
      setSelectedRowKeys(selectedRKeys);
      return;
    }
    selectedRKeys = [];
    selectedRKeys.push(record.key);
    setSelectedRowKeys(selectedRKeys);
    setValue("externalLocationId", record.key);
    setLocationInformation(record);
    setNoLocationSelected(false)
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
      setValue("externalLocationId", selectedRowKeys[0]);
    },
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col md="4">
            <Form id="location-form" onSubmit={handleSearchSubmit}>
              <Form.Label>Search Locations</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Search locations..."
                  autoComplete="off"
                  defaultValue={searchQuery ? searchQuery : ""}
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary" type="submit">
                    {isLoading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <i className="fa fa-search" aria-hidden="true"></i>
                    )}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {!isLoading ? (
            <Row>
              {(searchQuery || locationId || selectedRowKeys.length > 0) && <Col>
                {locations.data.length ? (
                  <Table
                    className="mt-4 mb-1"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={locations.data}
                    onRow={(record) => ({
                      onClick: () => {
                        selectRow(record);
                      },
                    })}
                  />
                ) : (
                  <p>
                    {locations.error ? locationErrorMessage() : '' }
                  </p>
                )}
              </Col>}

            </Row>
          ) : null}

          {!isLoading && noLocationSelected ? (
            <p style={{ color: "red" }}>
              { selectLocationToProceedToNextStepMessage() }
            </p>
          ) : null}
          <Row className="mt-4 mb-3">
            <Col md="12">
              <input type="submit" className="btn btn-primary" value="Next" />
            </Col>
            <Col className="d-none">
                <TextField
                  control={control}
                  name="externalLocationId"
                  type="text"
                  rules={{
                    required: "Required",
                  }}
                  controlId="externalLocationIdStepOne"
                  label="Location Id"
                />
              </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

function mapStateToProps({
  values,
  locations,
  searchQuery,
  isLoading,
  errors,
  currentStep,


}) {
  return {
    initialValues: values,
    locations,
    searchQuery,
    isLoading,
    globalErrors: errors || {},
    currentStep,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateStepNumber: (value) => dispatch(updateCurrentStep(value)),
    saveStepValues: (values) => dispatch(updateFormValues(values)),
    searchLocations: (query) => dispatch(searchLocations(query)),
    saveSearchQuery: (query) => dispatch(saveSearchQuery(query)),
    getLocationById: (id) => dispatch(getLocation(id)),
    setLocationID: (id) => dispatch(setLocationId(id)),
    setLocationInformation: (info) => dispatch(setLocationInfo(info)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
