import { Form, InputGroup, Spinner } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { normalizeCardNumber } from "../../utils/helper";

const TextField = ({
  control,
  name,
  type,
  rules,
  errorMsgs,
  controlId,
  label,
  placeHolder,
  autoComplete,
  inputMode,
  inputGroupAppend,
  inputGroupPrepend,
  formatTwoDigits,
  formatCreditCard,
  disabled,
  onChangeCallBack,
  inlineLabel,
  value,
  isValueUsed,
  maxLength,
  isLoading
  }) => {
  
  return (
    <Controller
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
        <Form.Group controlId={controlId}>
          {label ?
            <Form.Label>{label} {rules?.required ? '*' : null}</Form.Label>
          : null
          }
     
          <InputGroup className={(inputGroupAppend || inputGroupPrepend) ? 'input-has-icon' : ''}>
            {inputGroupPrepend ?   
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">{inputGroupPrepend}</InputGroup.Text>
              </InputGroup.Prepend>
            : 
            null}  
            <Form.Control
              {...field}
              type={type}
              isValid={isTouched && isDirty && !invalid}
              isInvalid={invalid}
              feedback={error}
              placeholder={placeHolder}
              autoComplete={autoComplete}
              inputMode={inputMode}
              disabled={disabled}
              maxLength={maxLength}
              value={isValueUsed ? value : field.value}
              onChange={(e) => {
                onChangeCallBack &&  onChangeCallBack(e);
                const value = e.target.value;
                return formatCreditCard ? field.onChange(normalizeCardNumber(value)) : field.onChange(value)
              }}
              onBlur={(e) => {
                const value = e.target.value;
                return formatTwoDigits && value.length === 1 && value <= 9 ? field.onChange(`0${value}`) : field.onChange(value)
              }}
              
            />

          {isLoading ? (
                <InputGroup.Append>
                  <InputGroup.Text>
                    <Spinner animation="border" size="sm" />
                  </InputGroup.Text>
                </InputGroup.Append>
              ) : null}
                 {
            inlineLabel ? <Form.Label column sm={8} md={8}>
            {inlineLabel}
          </Form.Label> :null
          }
            {inputGroupAppend ?   
              <InputGroup.Append>
                <InputGroup.Text id="inputGroupAppend">{inputGroupAppend}</InputGroup.Text>
              </InputGroup.Append>
            : 
            null}
            <Form.Control.Feedback type="invalid">
              {error ?
                errorMsgs && error.type !== 'required' ?
                  errorMsgs[error.type] 
                :
                error.message ?
                  error.message :
                  'Required'
              : 
              null}  
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      )}}
      name={name}
      control={control ? control : null}
      rules={rules}
    />
  );
};

export default TextField;
