const useApiResult = async request => {
  let results = [];
  let error = null;
  let responseArray = await fetch(...request)
    .then(async (response) => {
      if (response.ok) {
        const allowedImages = ['image/png', 'image/jpg', 'image/jpeg', 'image/*']
        if (response.headers.get('Content-Type') === 'application/pdf'){
          results = await response.blob();
        } else if (allowedImages.includes(response.headers.get('Content-Type'))) {
          results = await response.blob().then(blob => URL.createObjectURL(blob))
        } else {
          results = await response.json();
        }
        error = null;
      } else {
        error = await response.json();
        console.log(error)
      }
      return [results, error];
    })
    .catch(err => {
      error = err.message;
    })
    .finally(()=> {
      return [results, error];
    })

  return responseArray;

};

export default useApiResult;