import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Controller } from 'react-hook-form';
import { Menu, MenuItem } from 'react-bootstrap-typeahead';
import { invalidErrorMessage, requiredErrorMessage } from '../../utils/errorMessage';

const TextAsyncSearchField = ({
  control, 
  name, 
  rules, 
  errorMsgs, 
  controlId, 
  label,
  placeHolder,
  handleChange, 
  asyncData,
  defaultInputValue, 
  isLoading,
  handleSearch,
  handleSelect,
}) => {
  const filterBy = () => true;
  return (
    <Controller 
      render={({ 
        field,
        fieldState: { invalid, isTouched, isDirty, error } ,
        })=> (
          <Form.Group controlId={controlId}>
            {label ?
              <Form.Label>{label} {rules?.required ? '*' : null}</Form.Label>
            : null
            }
            <AsyncTypeahead 
              {...field}
              isValid={isTouched && isDirty && !invalid}
              isInvalid={invalid}
              className={invalid ? 'is-invalid' : 'is-valid'}
              filterBy={filterBy}
              id={name}
              isLoading={isLoading}
              labelKey={name}
              minLength={3}
              onSearch={handleSearch}
              options={asyncData}
              placeholder={placeHolder}
              onChange={handleChange}
              onInputChange={handleChange}
              defaultInputValue={defaultInputValue}
              renderMenu={
                (options , props) => (
                  <Menu {...props}>
                    {
                        options.map((option, indx) => (
                          <MenuItem
                          onClick={ ()=>{ 
                            handleSelect && handleSelect(option)} }
                          option={option}
                          position={indx}
                          key={indx}>
                            
                          {option}
                          </MenuItem>
                        ))
                    }
                  </Menu>
                )
              }
            />
            <Form.Control.Feedback type="invalid">
           {
              error ?
                errorMsgs && error.type !== requiredErrorMessage() ?
                  errorMsgs[error.type] 
                :
                error.message ?
                  error.message :
                  invalidErrorMessage()
              : 
              null}  
            </Form.Control.Feedback>
          </Form.Group>    
        )}
      name={name}
      control={control ? control : null}
      rules={rules}
    />
    
  )
}

export default TextAsyncSearchField;