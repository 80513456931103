import React, { useEffect } from 'react'
import TextField from './form-fields/TextField'
import { Row, Col } from 'react-bootstrap'
import { patternRgx } from '../utils/formValidator'
import { useFormContext } from 'react-hook-form'
import { maxLength, minLength } from '../utils/errorMessage'
import SelectField from './form-fields/SelectField'
import { connect } from 'react-redux'
import Option from './form-fields/Option'
import { mailCodePlaceHolder } from '../utils/confirmationMessage'

const AgencyInfo = ({ caOriAgencies, isOriComplete, apiData, initialValues }) => {
  const form = useFormContext();

  useEffect(() => {
    if (!isOriComplete) {
      form.setValue('authorizedAgency', "");
      initialValues.authorizedAgency = "";
    }
  }, [isOriComplete]) //eslint-disable-line

  useEffect(() => {
   !isOriComplete ? initialValues.authorizedAgency = "" : form.setValue('authorizedAgency', caOriAgencies.authorizedAgency)
  }, [caOriAgencies]) //eslint-disable-line
  return (
    <>
      <Row>
        <Col>
          <TextField
            control={form.control}
            name="authorizedAgency"
            type="text"
            disabled={true}
            controlId="AuthorizedAgencySubmissionStep"
            value={caOriAgencies.authorizedAgency}
            label="Authorized Agency to Receive Criminal Record" />
        </Col>
        <Col>
          <TextField
            control={form.control}
            name="mailCode"
            type="text"
            rules={
              {
                pattern: patternRgx.onlyFiveDigits
              }
            }
            placeHolder={mailCodePlaceHolder()}
            controlId="MailCodeSubmissionStep"
            label="Mail Code"
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <h6 className="sub-title mt-4">Street Address or P.O.Box</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            control={form.control}
            name="agencyStreetNumber"
            type="text"
            rules={{
              maxLength: {
                value: 10,
                message: maxLength(10),
              },
              pattern: patternRgx.numeric
            }}
            controlId="StreetNumberSubmissionStep"
            label="Street Number"
          />
        </Col>
        <Col>
          <SelectField
            control={form.control}
            name="agencyStreetDirection"
            controlId="streetDirectionSubmissionStep"
            label="Street Direction"
          >
            <option value="" > </option>
            <Option data={apiData.streetDirection} />
          </SelectField>
        </Col>
        <Col>
          <TextField
            control={form.control}
            name="agencyStreetName"
            type="text"
            rules={{
              maxLength: {
                value: 20,
                message: maxLength(20),
              },
              pattern: patternRgx.alphaNumericalDashParenthesesSpace
            }}
            controlId="StreetNameSubmissionStep"
            label="Street Name" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            control={form.control}
            name="agencyCity"
            type="text"
            rules={{
              maxLength: {
                value: 20,
                message: maxLength(20),
              },
              pattern: patternRgx.alphaNumericalSpaceDash
            }}
            controlId="CitySubmissionStep"
            label="City" />
        </Col>
        <Col>
          <SelectField
            control={form.control}
            name="agencyState"
            controlId="stateSubmissionStep"
            label="State"
          >
            <option value=""> -- Select State --</option>
            <Option data={apiData.usStates} />
          </SelectField>
        </Col>
        <Col>
          <TextField
            control={form.control}
            name="agencyZipCode"
            type="text"
            rules={{
              pattern: patternRgx.onlyFiveDigitsOrFollowedByFourDigits
            }}
            controlId="ZipCodeSubmissionStep"
            label="Zip Code" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            control={form.control}
            name="agencyContactName"
            type="text"
            rules={{
              maxLength: {
                value: 30,
                message: maxLength(30),
              },
              pattern: patternRgx.alphaCharactersDashesSpaces
            }}
            controlId="ContactNameSubmissionStep"
            label="Contact Name" />
        </Col>
        <Col>
          <TextField
            control={form.control}
            name="agencyTelephoneNumber"
            type="text"
            rules={{
              maxLength: {
                value: 24,
                message: maxLength(24),
              },
              minLength: {
                value: 5,
                message: minLength(5)
              },
              pattern: patternRgx.numericalSpecialCharacters
            }}
            controlId="TelephoneNumberSubmissionStep"
            label="Telephone Number" />
        </Col>
      </Row>
    </>

  )
}
function mapStateToProps({ caOriAgencies, isOriComplete, apiData, values }) {
  return {
    caOriAgencies,
    isOriComplete,
    apiData,
    initialValues: values,

  }
}
export default connect(mapStateToProps)(AgencyInfo)
