
import { Calendar, Select, Radio, Row, Button } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { connect } from "react-redux";

const BookingCalendar = ({ slots, onBookingConfirm, onDaySelectHandler, isLoading }) => {
  
  const [selectedDay, setSelectedDay] = useState();
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState();
  const [timzoneOffset, setTimezoneOffset] = useState(0);
  const hasAvailableSlots = Object.keys(slots).length;

  const disabledDates = date => {
    const allowedSlots = Object.keys(slots).map(day => moment(day));
    return !allowedSlots.some((d) => date.isSame(d, "day"));
  }

  const onDaySelect = day => {
    setSelectedTime(null);
    const dayString = day.format('M/D/YYYY');
    setSelectedDay(dayString);
    onDaySelectHandler(dayString)
    Object.keys(slots).length && setAvailableTimes(slots[dayString]?.map(obj => ({ time: obj.time, originTime: obj.originalDate, offset: obj.offset })))
  }

  useEffect(() => {
    if (availableTimes && availableTimes.length > 0) {
      setTimezoneOffset(availableTimes[0].offset ?? 0)
    }
  }, [availableTimes])

  return (
    <>
      {
        hasAvailableSlots ? 
        <>
          <Col md="9">
            <Calendar
              defaultValue={null}
              fullscreen={false}
              disabledDate={disabledDates}
              onSelect={onDaySelect}
              headerRender={({ value, onChange }) => {
                const monthNames = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ];
                const start = new Date().getMonth();
                const end = start + 2;
                const monthOptions = [];
                const monthOptionsObj = {};
                for (let index = start; index < end; index++) {
                  monthOptionsObj[monthNames[index]] = {
                    index,
                    name: monthNames[index]
                  };
                }

                for (let index = start; index < end; index++) {
                  monthOptions.push(
                    <Select.Option
                      className="month-item"
                      value={monthOptionsObj[monthNames[index]].index}
                      key={`${index}`}
                    >
                      {monthOptionsObj[monthNames[index]].name}
                    </Select.Option>
                  );
                }
                const month = value
                  ? monthOptionsObj[monthNames[value.month()]]
                  : monthOptionsObj[monthNames[moment().month()]];
                return (
                  <div style={{ padding: 8 }}>
                    <Row gutter={8}>
                      <Col style={{textAlign: 'left'}}>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={month.name}
                          onChange={(selectedMonth) => {
                            onChange(moment().month(selectedMonth));
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
          </Col>
          <Col md="3">
          { 
            selectedDay && (
              <>
                <h5 className="mt-2 mb-0">Times</h5>
                <div className="selectableTimes pt-3">
                  <Radio.Group value={selectedTime} onChange={(e)=> setSelectedTime(e.target.value)}>
                    {
                      availableTimes && availableTimes.map(timeObj => (
                        <Radio.Button 
                          key={timeObj.time}
                          className='timeSlotBtn mt-1 mb-2'
                          value={timeObj.originTime}
                        >
                          <ClockCircleOutlined />
                          {timeObj.time}
                        </Radio.Button>
                      ))
                    }
                  </Radio.Group>
                  
                </div>
              </>
            )
          }
          </Col>
          <Col className='mb-5'>
            <Button 
              type='primary' 
              size='large'
              style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}
              disabled={!selectedTime || isLoading}
              block
              onClick={()=> {
                onBookingConfirm(selectedTime, timzoneOffset)
              }}
            >
              <>
                {isLoading && 
                  <Spinner 
                    animation="border"
                    variant="light" 
                    size="sm" 
                    role="status"
                    aria-hidden="true" 
                  />
                }
                Confirm
              </>
            </Button>              
          </Col>
        </>
        : null
      }
    </>
    
  )
}

function mapStateToProps ({isLoading}) {
  return {
    isLoading
  }
}

export default connect(mapStateToProps)(BookingCalendar);