import React from 'react'
import { Col, Row } from "react-bootstrap";
import TextField from "./form-fields/TextField";
import { patternRgx } from "../utils/formValidator";
import { useFormContext } from "react-hook-form";
import { orderType } from '../utils/orderType';
import SelectField from "./form-fields/SelectField";
import { connect } from "react-redux";
import { maxLength } from '../utils/errorMessage';
import Option from './form-fields/Option';

const AdditionalInfo = ({ apiData }) => {
  const { control, watch, getValues } = useFormContext();
  const isUSCitizenVal = watch("isUsCitizen");
  const orderTypeId = getValues('orderTypeId')


  return (
    <>
      <Row>
        <Col md={orderTypeId === orderType.FDLE ? { order: '3', span: '6' } : { order: '1', span: '6' }} >
          <TextField
            control={control}
            name="emailAddress"
            type="email"
            disabled={isUSCitizenVal === "No" ? true : false}
            rules={{
              required: "Required",
              pattern: patternRgx.email,
            }}
            controlId="emailAddressStepTwo"
            label="Email"
          />
        </Col>
        {orderTypeId === orderType.FDLE && <Col md={orderTypeId === orderType.FDLE ? { order: '2', span: '6' } : { span: '6' }}>
          <TextField
            control={control}
            name="extension"
            type="text"
            rules={{
              pattern: patternRgx.extension
            
            }}
            controlId="extensionStepTwo"
            label="Extension"
          />
        </Col>}
        <Col md={orderTypeId === orderType.FDLE ? { order: '1', span: '6' } : { order: '3', span: '6' }}>
          <TextField
            control={control}
            name="phoneNumber"
            type="text"
            disabled={isUSCitizenVal === "No" ? true : false}
            rules={{
              required: "Required",
              pattern: patternRgx.phone,
            }}
            controlId="phoneNumberStepOne"
            label="Phone Number"
          />
        </Col>
      </Row>
      {
        orderTypeId === orderType.FDLE &&
        <Row>
          <Col md="6">
            <SelectField
              control={control}
              name="countryOfCitizenship"
              controlId="countryOfCitizenshipStepTwo"
              label="Country of Citizenship"
            >
              <option value="">
                {" "}
                -- Select Country --
              </option>
              <Option data={apiData.citizenshipCountries} />
            </SelectField>
          </Col>
          <Col md="6">
            <TextField
              control={control}
              name="oca"
              type="text"
              rules={{
                required: false,
                maxLength: {
                  value: 20,
                  message: maxLength(20),
                },
                pattern: patternRgx.alphaNumeric
              }}
              controlId="ocaStepTwo"
              label="OCA"
            />
          </Col>
        </Row>
      }
    </>
  )
}
function mapStateToProps({ apiData }) {
  return {
    apiData,
  };
}
export default connect(mapStateToProps)(AdditionalInfo)
