import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import StepsHeader from "./StepsHeader";

import {
  orderTypeTitle,
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  stepFive,
  stepSix,
} from "../utils/orderType";
import { wizardSteps } from "../utils/wizardSteps";
import { initForm } from "../store/reducers";
import { UPDATE_CURRENT_STEP } from "../store/actions/form";
import { useEffect } from "react";
const stepsSwitch = (currentStep, orderTypeId) => {
  switch (currentStep) {
    case wizardSteps.StepOne:
      return stepOne.get(orderTypeId)
    case wizardSteps.StepTwo:
      return stepTwo.get(orderTypeId);
    case wizardSteps.StepThree:
      return stepThree.get(orderTypeId);
    case wizardSteps.StepFour:
      return stepFour.get(orderTypeId);
    case wizardSteps.StepFive:
      return stepFive.get(orderTypeId);
    case wizardSteps.StepSix:
      return stepSix.get(orderTypeId);
    default:
      return stepOne.get(orderTypeId)
  }
};

const WizardForm = ({ currentStep, orderTypeId, fdleRedirectionInfo, initForm, updateCurrentStep }) => {

  useEffect(() => {
    if (fdleRedirectionInfo) {
      initForm(orderTypeId, fdleRedirectionInfo.firstName, fdleRedirectionInfo.lastName, fdleRedirectionInfo.middleName, fdleRedirectionInfo.ori, fdleRedirectionInfo.externalLocationId);
      updateCurrentStep(fdleRedirectionInfo.currentStep)
    }
  }, [currentStep, fdleRedirectionInfo, updateCurrentStep, initForm, orderTypeId])

  //use
  return (
    <div>
      <Row className="justify-content-center">
        <h1 className="form-title prime-color mt-4">
          {orderTypeTitle.get(orderTypeId)}
        </h1>
      </Row>

      <Row className="box-wrapper mt-4 mb-4 pt-4 pb-4">
        <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }}>
          <StepsHeader
            currentStep={currentStep}
            orderTypeId={orderTypeId}
          />
          {stepsSwitch(currentStep, orderTypeId)}
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps({ currentStep, fdleRedirectionInfo, orderTypeId }) {
  return {
    currentStep,
    fdleRedirectionInfo,
    orderTypeId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (orderTypeId, firstName, lastName, middleName, ori, externalLocationId) => dispatch(initForm(orderTypeId, firstName, lastName, middleName, ori, externalLocationId)),
    updateCurrentStep: (value) => dispatch({ type: UPDATE_CURRENT_STEP, value }),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WizardForm);
