import moment from "moment-timezone";
import { servicesTitle } from "./titles";

export const normalizeCardNumber = value => {
  return !!value ? value.replace(/\s/g, '').match(/.{1,4}/g).join(' ').substr(0,19) || '' : '';
}

export const convertFrom24To12Format = (time24) => {
  const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
  const period = +sHours < 12 ? 'AM' : 'PM';
  let hours = +sHours % 12 || 12;
  hours = hours < 10 ? `0${hours}` : hours;
  return `${hours}:${minutes} ${period}`;
}

export const getFutureExpiryDate = _ => {
  let months = [1,2,3,4,5,6,7,8,9,10,11,12];
  let monthsPartial = [];
  
  const dateObj = new Date();
  const currentMonth = dateObj.getUTCMonth() + 1; //months from 1-12
  const currentYear = dateObj.getUTCFullYear();
  
  const maxYear = currentYear+10;
  const years = [];
  for (let i = currentYear; i <= maxYear; i++) {
    years.push(i);
  }
  
  monthsPartial = [...months].splice(months.indexOf(currentMonth))
  return {
    currentYear,
    years,
    months,
    monthsPartial
  }
}

export const extractSlotsByDaysAndTimes = slots => {

  const daysObj = {};
  if (!slots.length) return daysObj;
  for (let i = 0; i < slots.length; i++) {
    const offset = moment.parseZone(slots[i].localStartDateTime).utcOffset()

    const day = (moment(slots[i].startDateTime)).utcOffset(offset).format("M/D/YYYY")
    const time = (moment(slots[i].startDateTime)).utcOffset(offset).format("hh:mm a")

    const dayInfo = { time, originalDate: slots[i].startDateTime, localeDate: slots[i].localStartDateTime, offset: offset }

    if (daysObj[day] === undefined) {
      daysObj[day] = [dayInfo];
    } else {
      daysObj[day].push(dayInfo);
    }
  }

  return daysObj
}

export const addMonths = (date, months) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}


export const formatDateTime = (date, format = 'MM/DD/YYYY') => {
    return moment(date).format(format);
}

export const getCurrentYear = () => {
  return moment().format('YYYY');
}

export const setBrowserTitle = (title) => {
  document.title = title ?? servicesTitle();
}

export const generateRandomUUID = () => {
  return crypto.randomUUID();
}