import { orderTypeBreadcrumbsTitle } from "../utils/orderType";
import '../assets/scss/BreadCrumbs.scss';
import { connect } from "react-redux";
import { homeBreadCrumbsMessage } from "../utils/confirmationMessage";
import { certifixlivescan } from "../utils/links";
import { Link, useRouteMatch, useLocation } from "react-router-dom";

const BreadCrumbs = ({
    orderTypeId
}) => {
const { url } = useRouteMatch();
const {search } =  useLocation();
const locationId = new URLSearchParams(search).get('locationId');

  return (
    <>
        <div id="breadcrumbs">
            <div className="container">
                <ol typeof="BreadcrumbList" vocab="http://schema.org/BreadcrumbList">
                    <span property="itemListElement" typeof="ListItem">
                        <a property="item" typeof="WebPage" title={homeBreadCrumbsMessage()} href={certifixlivescan()} className="home">
                        <span property="name">Home</span>
                        </a>
                        <meta property="position" content="1" />
                    </span>
                    
                    <span property="itemListElement" typeof="ListItem">
                            { orderTypeId ? 
                                <Link to={`${url}services${locationId ? `?locationId=${locationId}`: ''}`}>
                                    Services
                                </Link>
                                 : 
                                <span property="name">
                                    Services
                                </span> 
                            }
                        <meta property="position" content="2" />
                    </span>
                    
                    {
                        !!orderTypeId && 
                        <span property="itemListElement" typeof="ListItem">
                            <span property="name">
                                {orderTypeBreadcrumbsTitle.get(orderTypeId)}
                            </span>
                            <meta property="position" content="3" />
                        </span>
                    }
                    
                    
                </ol>
            </div>
        </div>
    </>
    
  )
}

const mapStateToProps = ({ orderTypeId }) => ({
    orderTypeId,
});

export default connect(mapStateToProps)(BreadCrumbs);