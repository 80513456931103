import { headerSteps } from "../utils/orderType";

const StepsHeader = ({ currentStep,  orderTypeId }) => {
  const steps = headerSteps.get(orderTypeId) ;
  
  return (
    <>
      <p className="text-center mt-4 mb-4">
        Step {currentStep} of {steps.length}
      </p>
      <ol className="c-progress-steps">
        {steps.map((stepTitle, index) => {
          const title = stepTitle.split('\n')
          return (
            <li
              key={stepTitle}
              className={`c-progress-steps__step ${
                currentStep === index + 1 ? "current" : ""
              } ${currentStep > index + 1 ? "done" : ""}`}
            >
              {title.map(t => <span className="stepHeaderTitle" key={t}> {t} </span>)}
            </li>
          );
        })}
      </ol>
    </>
  );
};

export default StepsHeader;
