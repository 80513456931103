import useApiResult from './useApiResult';
import { validateAccountNumber } from './apiRequests';


const useValidateAccountNumber = async (accountNumber) => {

  const request = validateAccountNumber(accountNumber);
  const response = await useApiResult(request);
  // If Response Has Error, return it as is
  if (!response || response[1]) {
    return response
  }
  // If Respones with Data => process locations data
  if (response[0]) {
    const { Data } = response[0];

    return [Data, response[1]];
  }

  return response;
}

export default useValidateAccountNumber;
