import {useState} from 'react';
import {certifixlivescan} from '../utils/links';
import {menuItems} from '../utils/menuItems';
import '../assets/scss/Header.scss';

const Sidebar = () => {
    const [showSideBar, setShowSideBar] = useState(false);

    const showSideBarHandler = () => setShowSideBar(true);
    const closeSideBarHandler = () => setShowSideBar(false);

    return (
        <>
            <div id="main-sidebar-opener" className="ml-auto" onClick={showSideBarHandler}>
                <span><span></span></span>
            </div>
            <nav id='main-sidebar' className={`sidebar ${showSideBar === true ? 'open' : ''}`}>
                <div id="main-sidebar-closer" onClick={closeSideBarHandler}>
                    <span><span></span></span>
                </div>
                <div id="main-sidebar-title" className="sidebar-title">
                    <a id="sidebar-title-link" href={certifixlivescan()}>
                        <span>NAVIGATION</span>
                    </a>
                </div>
                <div className="sidebar-menu-container">
                    <ul className="sidebar-menu">
                        {menuItems.map((item, index) => {
                          return (
                            <li key={index} className="sidebar-menu-item" role="none">
                                <a href={item.link} className="sidebar-menu-item-link" role="menuitem">{item.title}</a>
                            </li>
                          )
                        })}
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Sidebar;