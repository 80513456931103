export const paperEducationPolicyLink =()=>`https://powerforms.docusign.net/7fafb538-e083-4cc7-b5dc-2cb3e51b6fbf?env=ca&acct=1557110c-93e0-404f-9818-024c2a9cace0&accountId=1557110c-93e0-404f-9818-024c2a9cace0`
export const blogLink=() => `https://blog.certifixlivescan.com`
export const faqLink=() => `https://www.certifixlivescan.com/frequently-asked-questions.html`
export const formsLink=() => `https://www.certifixlivescan.com/request-for-live-scan-forms.html`
export const loginLink=() => `https://billing.certifixlivescan.com/`
export const howItWorksLink=() => `https://www.certifixlivescan.com/how-it-works.html`
export const mobileServiceLink=() => `https://www.certifixlivescan.com/request-a-mobile-livescan-service.html`
export const corporateBillingLink=() => `https://www.certifixlivescan.com/open-a-live-scan-account.html`
export const liveScanSystemLink=() => `https://www.certifixlivescan.com/livescan-system.html`
export const getFingerprintLink=() => `https://www.certifixlivescan.com/certifix-livescan-locations.html`
export const liveScanClassesLink=() => `https://www.certifixlivescan.com/live-scan-fingerprinting-classes.html`
export const joinOurNetworkLink=() => `https://www.certifixlivescan.com/join-our-network.html`
export const supportLink=() => `https://support.certifixlivescan.com/`
export const aboutUsLink=() => `https://www.certifixlivescan.com/about-us.html`
export const reviewsLink=() => `https://www.certifixlivescan.com/customer-reviews.html`
export const contactUsLink=() => `https://www.certifixlivescan.com/contact-us.html`
export const privacyPolicyLink=() => `https://www.certifixlivescan.com/privacy-policy.html`
export const termsLink=() => `https://www.certifixlivescan.com/terms-of-use.html`
export const facebookLink=() => `https://www.facebook.com/CertifixLiveScan/`
export const twitterLink=() => `https://twitter.com/Certifix/`
export const linkedinLink=() => `https://www.linkedin.com/company/certifix-livescan/`
export const youtubeLink=() => `https://www.youtube.com/channel/UCilyNnqFdIl9yF7xm-We8aw/`
export const shopperCertifixReviewsLink=() => `https://www.shopperapproved.com/reviews/certifixlivescan.com/`
export const bbbRatingLink=() => `https://www.bbb.org/us/ca/anaheim/profile/live-scan-fingerprinting/certifix-live-scan-1126-172010560/#sealclick`
export const certifixlivescan=() => `https://www.certifixlivescan.com`
export const fbiGovLink = () => `https://www.fbi.gov`
export const fpaLink = () => `https://www.certifixlivescan.com/fbi-privacy-act.pdf`