import React, { useEffect, useState } from 'react'
import { patternRgx } from '../utils/formValidator'
import { useForm } from 'react-hook-form';
import TextField from './form-fields/TextField';
import CheckboxField from "./form-fields/CheckboxField";
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { scrollToTop } from '../utils/scroll';
import { UPDATE_CURRENT_STEP, UPDATE_FORM_VALUES } from '../store/actions/form';
import { getFees } from '../store/reducers';
import { orderType } from '../utils/orderType';
import { generalErrorMessage, invalidOcaErrorMessage, levelOfServiceErrorMEssage, maxLength } from '../utils/errorMessage';
import { notification } from 'antd';
import { fbCaCase } from '../utils/fbCa';
import { errorCodes } from '../utils/errorCodes';

const TransactionInformation = ({ initialValues, selectedFbCa, updateCurrentStep, updateStepValues, currentStep, calculateFees }) => {
  const { handleSubmit, getValues, control, setError } = useForm({ defaultValues: initialValues, mode: 'all' });
  const [isDojSelected, setIsDojSelected] = useState(false)
  const [isFbiSelected, setIsFbiSelected] = useState(false)
  const [isDisabledDoj, setIsDisabledDoj] = useState(false)
  const [isDisabledFbi, setIsDisabledFbi] = useState(false)

  const onSubmit = async (data) => {
    data.levelOfServiceDoj = isDojSelected;
    data.levelOfServiceFbi = isFbiSelected;
    updateStepValues(data);
    if (getValues("orderTypeId") === orderType.CA) {
      if ((isFbiSelected || isDojSelected)) {
        const isOriFeeValid = await calculateFees({
          ori: getValues('ori'), orderTypeId: getValues('orderTypeId'), authorizedApplicantType: getValues('authorizedApplicantType'),
          levelOfServiceDoj: isDojSelected, levelOfServiceFbi: isFbiSelected, authorizedAgency: getValues('authorizedAgency'),
          applicantBillingNumber: getValues('billingNumber')
        });
        if (isOriFeeValid[0].TotalFee) {
          updateCurrentStep(++currentStep);
        }
        else if (isOriFeeValid[1]?.Errors[0]?.Code === errorCodes.oriBillingNumberMismatch || isOriFeeValid[1]?.Errors[0]?.Code === errorCodes.billingNumberNotFound) {
          setError('billingNumber', {
            type: 'custom',
            message: isOriFeeValid[1]?.Errors[0]?.Message
          })
        }else if (isOriFeeValid[1]?.Errors[0]?.Code === errorCodes.oriAgencyMismatch){
          notification.error(
            {
              message: "Error",
              description: isOriFeeValid[1]?.Errors[0]?.Message,
              placement: 'topRight',
            }
          )
        }
        else {
          notification.error(
            {
              message: "Error",
              description: generalErrorMessage(),
              placement: 'topRight',
            }
          )
        }
      }
      else {
        notification.error(
          {
            message: "Error",
            description: levelOfServiceErrorMEssage(),
            placement: 'topRight',
          }
        )
      }
    } else {
      updateCurrentStep(++currentStep);
    }
    scrollToTop('box-wrapper');


  }

  const onBack = _ => {
    const values = getValues();
    updateStepValues(values);
    updateCurrentStep(--currentStep);
    scrollToTop('box-wrapper');
  }
  useEffect(() => {
    if (selectedFbCa) {
    }
    switch (Number(selectedFbCa)) {
      case fbCaCase.fbFalseCaTrue:
        setIsDojSelected(true)
        setIsFbiSelected(false)
        setIsDisabledDoj(true)
        setIsDisabledFbi(true)

        break;
      case fbCaCase.fbTrueCaFalse:
        setIsDojSelected(false)
        setIsFbiSelected(true)
        setIsDisabledDoj(true)
        setIsDisabledFbi(true)
        break;
      case fbCaCase.fbTrueCaTrue:
        setIsDojSelected(true)
        setIsFbiSelected(true)
        setIsDisabledDoj(true)
        setIsDisabledFbi(true)
        break;
      case fbCaCase.fbOptionalCaTrue:
        setIsDisabledDoj(true)
        setIsDojSelected(true)
        setIsFbiSelected(initialValues.levelOfServiceFbi)
        break;
      case fbCaCase.fbOptionalCaOptional:
        setIsFbiSelected(initialValues.levelOfServiceFbi)
        setIsDojSelected(initialValues.levelOfServiceDoj)
        break;
      default:
        break;
    }

  }, [selectedFbCa]) //eslint-disable-line

  return (
    <>
      <form id="transaction-information-form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="12">
            <h3 className="section-heading mt-4">Transaction Information</h3>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextField
              control={control}
              name="oca"
              type="text"
              rules={{
                required: false,
                pattern: {
                  value: patternRgx.alphaNumeric.value,
                  message: `${invalidOcaErrorMessage()}, ${patternRgx.alphaNumeric.message}`
              },
                  maxLength: {
                  value: 30,
                  message: maxLength(30)
                }
              }}
              controlId="OcaTransactionInfoStep"
              label="Your Number, OCA or Facility Number"

            />
          </Col>
          <Col md={6}>
            <Col md={12} className='level-of-service-title'>Level of service *</Col>
            <Row>
              <Col md={4} className='level-of-service-checkbox'>
                <CheckboxField
                  control={control}
                  rules={{ required: false }}
                  name="levelOfServiceDoj"
                  controlId="DojTransactionInfoStep"
                  type="checkbox"
                  value={isDojSelected}
                  checked={isDojSelected}
                  label={"DOJ"}
                  disabled={isDisabledDoj}
                  onChangeCallBack={(e) => {
                    setIsDojSelected(e.target.checked);
                  }}
                />
              </Col>
              <Col md={4} className='level-of-service-checkbox'>
                <CheckboxField
                  control={control}
                  rules={{ required: false }}
                  name="levelOfServiceFbi"
                  controlId="FbiTransactionInfoStep"
                  type="checkbox"
                  value={isFbiSelected}
                  checked={isFbiSelected}
                  disabled={isDisabledFbi}
                  label={"FBI"}
                  onChangeCallBack={(e) => {
                    setIsFbiSelected(e.target.checked);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <TextField
              control={control}
              name="billingNumber"
              type="text"
              rules={{
                pattern: patternRgx.onlySixDigitsAndDisallowZeros,

              }}
              controlId="applicantBillingNumberTransactionInfoStep"
              label="Billing Number"

            />
          </Col>
          <Col>

          </Col>

        </Row>
        <Row className="mt-4 mb-3">
          <Col md="12">
            <input
              type="button"
              className="btn btn-outline-primary"
              value="Back"
              onClick={onBack}
            />
            <input type="submit" className="btn btn-primary" value="Next" />
          </Col>
        </Row>
      </form>
    </>
  )
}
function mapStateToProps({ values, selectedFbCa, currentStep }) {
  return {
    initialValues: values,
    selectedFbCa,
    currentStep,

  }

}
const mapDispatchToProps = dispatch => {
  return {
    calculateFees: (getOriFees) => dispatch(getFees(getOriFees)),
    updateCurrentStep: (value) => dispatch({ type: UPDATE_CURRENT_STEP, value }),
    updateStepValues: (values) => dispatch({ type: UPDATE_FORM_VALUES, values }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionInformation)
