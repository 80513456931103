import React, { useEffect, useState } from 'react'
import ModalApp from './shared/modal/ModalApp'
import { fdleRedirectionModalText } from '../utils/confirmationMessage';
import warning from "../assets/images/redirection-warning.svg";
import { useDispatch, connect } from 'react-redux';
import _ from "lodash";
import { orderType } from '../utils/orderType';
import { getServiceLogo } from '../store/reducers';


const FdleRedirectionModal = ({ show, onClose, onSubmit, imagesSrc, orderTypes }) => {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const service = _.find(orderTypes, element => element.Id === orderType.FDLE);
    const serviceId = service ? service.Setting.Id : null;
    const fdleLogo = _.find(imagesSrc, (img) => { return img.serviceId === serviceId });
    if ((!imagesSrc || (imagesSrc && !fdleLogo)) && serviceId) {
      dispatch(getServiceLogo(serviceId));
    }
    else {
      setLogo(fdleLogo.data)
    }
    // eslint-disable-next-line 
  }, [orderTypes, imagesSrc]);


  return (
    <ModalApp show={show} onClose={onClose} saveBtnTitle={"Switch to Florida Enrollment"} cancelBtnTitle={"Proceed with Fingerprint Card Service"} onSave={onSubmit}
      hideModalHeader={true} hideCloseBtn={true} saveBtnStyle={'redirect-modal-btn-save'} cancelBtnStyle={'redirect-modal-btn-cancel'} backdrop={"static"}>
      <div className='redirection-container'>
        <div className='fdle-icon'>
          <img src={logo ? logo : ""} alt='' />
        </div>
        <div className='warning-body'>
          <span className='warning-icon'>
            <img src={warning} alt='fdle logo' />

          </span>
          <span className='warning-text'>{fdleRedirectionModalText('paragraphOne')}
            <b>{fdleRedirectionModalText('boldText')}</b>
            {fdleRedirectionModalText('paragraphTwo')}
          </span>
        </div>
      </div>
    </ModalApp>
  )
}

const mapStateToProps = ({ imagesSrc, orderTypes }) => ({
  imagesSrc, orderTypes
});

export default connect(mapStateToProps)(FdleRedirectionModal);