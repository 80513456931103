import { paperEducationLabel_1, paperEducationLabel_2, paperEducationPolicyMessage } from "./confirmationMessage";
import { paperEducationPolicyLink } from "./links";

export const AccountAgreementType = {
  none: 0,
  paperAccountAgreement: 1,
};

export const AccountAgreementTypeLabel = new Map([
  [AccountAgreementType.none, ''],
  [AccountAgreementType.paperAccountAgreement, <div>{paperEducationLabel_1()} <a href={paperEducationPolicyLink()} rel="noreferrer" target="_blank" > {paperEducationPolicyMessage()} </a> {paperEducationLabel_2()}</div>],
]);
