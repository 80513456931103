import React from 'react'
import { Row, Col } from "react-bootstrap";
import MuiDatePicker from "./form-fields/MuiDatePicker";
import RadioCheckboxField from "./form-fields/RadioCheckboxField";
import TextField from "./form-fields/TextField";
import { useFormContext } from "react-hook-form";
import SelectField from "./form-fields/SelectField";
import moment from "moment";
import { connect } from "react-redux";
import { patternRgx } from '../utils/formValidator';
import {  minMaxRange } from '../utils/errorMessage';
import Option from './form-fields/Option';
import RequiredSSN from './RequiredSSN';

const FdleApplicantInfo = ({ apiData, initialValues, globalErrors }) => {
    const { control, watch, setValue, clearErrors } = useFormContext();
    const maxDateOfBirth = moment()
        .subtract(12, "year")
        .subtract(1, "days")
        .format("MM/DD/YYYY");

    const dateOfBirthVal = watch("dateOfBirth");
    const heightTypeVal = watch("heightType");
    const weightTypeVal = watch("weightType");



    return (
        <>
            <Col md="6">
                <MuiDatePicker
                    control={control}
                    name="dateOfBirth"
                    rules={{ required: true }}
                    controlId="dateOfBirthStepTwo"
                    label="Date of Birth *"
                    maxDate={maxDateOfBirth}
                    fieldValue={dateOfBirthVal}
                />
            </Col>
            <Col md="6" >
                <SelectField
                    control={control}
                    name="placeOfBirth"
                    rules={{ required: "Required" }}
                    controlId="placeOfBirthStepTwo"
                    label="Place of Birth"
                >
                    <option value="" disabled>
                        {" "}
                        -- Select Place of Birth --
                    </option>
                    <Option data={apiData.countries} />
                </SelectField>
            </Col>
            <RequiredSSN/>
            <Col md="6">
                <SelectField
                    control={control}
                    name="gender"
                    rules={{ required: "Required" }}
                    controlId="genderStepTwo"
                    label="Gender"
                >
                    <option value="" disabled>
                        {" "}
                        -- Select Gender --
                    </option>
                    <Option data={apiData.gender} />
                </SelectField>
            </Col>
            <Col md="6">
                <SelectField
                    control={control}
                    name="race"
                    rules={{ required: "Required" }}
                    controlId="raceStepTwo"
                    label="Race"
                >
                    <option value="" disabled>
                        {" "}
                        -- Select Race --
                    </option>
                    <Option data={apiData.race} isRace={true}/>
                </SelectField>
            </Col>
            <Col md="6"></Col>
            <Col md="6">
                <RadioCheckboxField
                    control={control}
                    name="heightType"
                    controlId="heightTypeStepTwo"
                    label="Height"
                    type="radio"
                    rules={{ required: "Required" }}
                    options={["Standard", "Metric"]}
                    onChangeCallBack={() => {
                        clearErrors(["height", "heightInch"]);
                        setValue("height", "");
                        setValue("heightInch", "");
                    }}
                    defaultValue={initialValues.heightType}
                />
            </Col>
            <Col md="6"></Col>

            <Col md="3">
                <TextField
                    control={control}
                    name="height"
                    type="text"
                    controlId="heightStepTwo"
                    inputGroupAppend={
                        heightTypeVal === "Standard" ? "Ft" : "Cm"
                    }
                    rules={{
                        required: "Required",
                   
                        min: {
                            value: heightTypeVal === "Standard" ? 4 : 122,
                            message:
                                heightTypeVal === "Standard"
                                    ? minMaxRange(4, 7, "Ft")
                                    : minMaxRange(122, 241, "Cm"),
                        },
                        max: {
                            value: heightTypeVal === "Standard" ? 7 : 241,
                            message:
                                heightTypeVal === "Standard"
                                    ? minMaxRange(4, 7, "Ft")
                                    : minMaxRange(122, 241, "Cm"),
                        },
                        pattern: patternRgx.numeric
                    }}
                />
            </Col>
            {heightTypeVal === "Standard" ? (
                <Col md="3">
                    <TextField
                        control={control}
                        name="heightInch"
                        type="text"
                        controlId="heightInchStepTwo"
                        inputGroupAppend="In"
                        formatTwoDigits
                        rules={{
                           min:{
                            value: 0,
                            message: minMaxRange(0, 11, "In"),
                           },
                            max: {
                                value: 11,
                                message: minMaxRange(0, 11, "In"),
                            },
                            pattern: patternRgx.numeric
                        }}
                    />
                </Col>
            ) : null}
            <Col md='6'></Col>
            <Col md="6">
                <RadioCheckboxField
                    control={control}
                    name="weightType"
                    controlId="weightTypeStepTwo"
                    label="Weight"
                    type="radio"
                    rules={{ required: "Required" }}
                    options={["Standard", "Metric"]}
                    onChangeCallBack={() => {
                        clearErrors("weight");
                        setValue("weight", "");
                    }}
                    defaultValue={initialValues.weightType}
                />
            </Col>
            <Col md="6"></Col>
            <Col md="6">
                <Row>
                    <Col md="6">
                        <TextField
                            control={control}
                            name="weight"
                            type="text"
                            controlId="weightStepTwo"
                            inputGroupAppend={
                                weightTypeVal === "Standard" ? "Lb" : "Kg"
                            }
                            rules={{
                                required: "Required",
                                min: {
                                    value: weightTypeVal === "Standard" ? 50 : 23,
                                    message:
                                        weightTypeVal === "Standard"
                                            ? minMaxRange(50, 499, "Lb")
                                            : minMaxRange(23, 226, "Kg"),
                                },
                                max: {
                                    value: weightTypeVal === "Standard" ? 499 : 226,
                                    message:
                                        weightTypeVal === "Standard"
                                            ? minMaxRange(50, 499, "Lb")
                                            : minMaxRange(23, 226, "Kg")
                                },
                                pattern: patternRgx.numeric,
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col md='6'></Col>
            <Col md="6">
                <SelectField
                    control={control}
                    name="hairColor"
                    rules={{ required: "Required" }}
                    controlId="hairColorStepTwo"
                    label="Hair Color"
                >
                    <option value="" disabled>
                        {" "}
                        -- Select Hair Color --
                    </option>
                    <Option data={apiData.hairColor} />
                </SelectField>
            </Col>
            <Col md="6">
                <SelectField
                    control={control}
                    name="eyeColor"
                    rules={{ required: "Required" }}
                    controlId="eyeColorStepTwo"
                    label="Eye Color"
                >
                    <option value="" disabled>
                        {" "}
                        -- Select Eye Color --
                    </option>
                    <Option data={apiData.eyeColor} />
                </SelectField>
            </Col>

        </>
    )
}
function mapStateToProps({ values, apiData, errors }) {
    return {
        initialValues: values,
        apiData,
        globalErrors: errors || {},
    };
}

export default connect(mapStateToProps)(FdleApplicantInfo)
