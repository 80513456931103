import { getServiceLogo } from './apiRequests';
import useApiResult from './useApiResult';

const useGetServiceLogo = async (id) => {
  
  const request = getServiceLogo(id);
  const response = await useApiResult(request);
  return response;
}

export default useGetServiceLogo;