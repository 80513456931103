import React from 'react'

const Option = ({data, isRace}) => {

    const options = isRace ?  data.map((r) => {
                let shortDesc = r.Description.substring(0, 70);
                return (
                    <option
                        key={r.Code}
                        value={r.Code}
                        data-content={shortDesc}
                        title={r.Description}
                    >
                        {shortDesc}
                        {r.Description.length > shortDesc ? "..." : ""}
                    </option>
                );
            }) :
            data.map((data) => (
                <option key={data.Code} value={data.Code}>
                    {data.Description}
                </option>
            ))
 
  return (
    <>
      
        {options}
        
    </>
  )
}

export default Option
