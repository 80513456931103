
const useMapOriAgencies = (response) => {
  const authApplicantTypes =  response && response?.Data?.map(i => {
    return {
      authType: i?.auth_applicant_type,
      fbCa: i?.fb_ca
    }
  } );
  const authorizedAgency =  response && response?.Data[0]?.agency_name;

  return {
    authApplicantTypes,
    authorizedAgency
  }
}

export default useMapOriAgencies;