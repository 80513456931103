import useApiResult from './useApiResult';
import { getLocation } from './apiRequests';

const formatDaysHours = (hours) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const shortDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const similarHours = {};
  for (let i = 0; i < hours.length; i++) {
    const hourStr = hours[i][days[i]];
    const dayHours = similarHours[hourStr];
    if (dayHours) {
      similarHours[[hourStr]].push(shortDays[i])
    } else {
      similarHours[hourStr] = [shortDays[i]]
    }
  }

  const hourRows = (Object.entries(similarHours)).map(([key, value]) => {
    if(value.length > 1) {
      return `${value[0]} - ${value[value.length-1]}: ${key}\n`;
    }
    return `${value[0]}: ${key}\n`;
  }).join('');
  
  return hourRows;
}


const useLocationById = async (locationId) => {

  const request = getLocation(locationId);
  const response = await useApiResult(request);

  // If Response Has Error, return it as is
  if (!response || response[1]) {
    return response
  }
  // If Respones with Data => process locations data
  if (response[0]) {
    const { Data } = response[0];
    
    const dataLocations = [];
    dataLocations.push(Data.location);
    const locations = dataLocations.map(location => {
      const {externalLocationId: key, distance, friendlyName: name, addressLine1: address, city, state, zipCode: zip, phoneNumber: phone, hoursOfOperation: hours } = location;
      const distanceRounded = distance.toFixed(1) + ' mi';
      const hoursFlat = hours.map(day => {
        const {dayOfWeek, openTime, closeTime} = day;
        return openTime === "Closed" ? {[dayOfWeek]: 'Closed'} : {[dayOfWeek]: `${openTime}-${closeTime}`};
      });
      const daysHours = formatDaysHours(hoursFlat);
      return {key, distance: distanceRounded, name, address, city, state, zip, phone, hours: daysHours}
    });
  
    return [locations, response[1]];
  }

  return response;
}

export default useLocationById;
